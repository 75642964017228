import axiosInstance from './server';
import { Expense, GetExpenseResponse, GetExpensesResponse } from 'app-ts-types/expenses';
import { createNotification, initNotification } from './notifications';

// Get an expense by ID
export const getExpenseById = async (id: string): Promise<GetExpenseResponse> => {
	const response = await axiosInstance.get<GetExpenseResponse>(`/expense/${id}`);
	return response.data;
};

// Get expenses
export const getExpenses = async (): Promise<GetExpensesResponse> => {
	const response = await axiosInstance.get<GetExpensesResponse>('/expense');
	return response.data;
};

// Create a new expense
export const createExpense = async (data: Expense): Promise<GetExpenseResponse> => {
	try {
		const response = await axiosInstance.post<GetExpenseResponse>('/expense', data);
		initNotification(data.id, "Expense creation", "Expense successfully created").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error creating expense", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Update an expense by ID
export const updateExpense = async (id: string, data: Expense): Promise<GetExpenseResponse> => {
	try {
		const response = await axiosInstance.put<GetExpenseResponse>(`/expense/${id}`, data);
		initNotification(data.id, "Expense update", "Expense successfully updated").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error updating expense", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Delete an expense by ID
export const deleteExpense = async (id: string): Promise<void> => {
	try {
		await axiosInstance.delete(`/expense/${id}`);
		initNotification(id, "Expense deletion", "Expense successfully deleted").catch(e => console.log(e));
	} catch (e: any) {
		initNotification(id, "Error deleting expense", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};
