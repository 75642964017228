import axiosInstance from './server';
import {
	GetNotificationResponse,
	GetNotificationsResponse,
	Notification,
	UpdateNotificationRequest
} from 'app-ts-types/notifications';
import {userStore} from "../state/User";
import {MarketplacePlatform} from "../utils/types";
import {notificationsStore} from "../state/Notification";
import {generateUUID} from "../utils/data";

// Get a notification by ID
export const getNotificationById = async (id: string): Promise<GetNotificationResponse> => {
	const response = await axiosInstance.get<GetNotificationResponse>(`/notification/${id}`);
	return response.data;
};

// Get a notifications
export const getNotifications = async (): Promise<GetNotificationsResponse> => {
	const response = await axiosInstance.get<GetNotificationsResponse>(`/notification`);
	return response.data;
};

// Create a new notification
export const createNotification = async (data: Notification): Promise<GetNotificationResponse> => {
	const response = await axiosInstance.post<GetNotificationResponse>('/notification', data);
	return response.data;
};

// Update a notification by ID
export const updateNotification = async (id: string, data: Notification): Promise<GetNotificationResponse> => {
	try {
		const response = await axiosInstance.put<GetNotificationResponse>(`/notification/${id}`, data);
		notificationsStore.updateNotification(data);
		return response.data;
	} catch (e) {
		throw e;
	}
};

// Delete a notification by ID
export const deleteNotification = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/notification/${id}`);
};

const forceStringify = (obj: any) => {
	if(typeof obj === "string") {
		return obj
	}
	return JSON.stringify(obj)
}

export const initNotification = async (id: string, type: string, message: string, platform:MarketplacePlatform = MarketplacePlatform.UNRECOGNIZED) => {
	const now = Date.now();
	const notification: Notification = {
		id: generateUUID(), // Provided notification ID
		user: userStore.getUser()?.id || "", // Replace with current user identifier as needed
		type,
		platform, // Replace with appropriate platform if needed
		content: forceStringify(message),
		timestamp: now,
		notificationLink: `https://app.tracknlist.com/notifications/${id}`,
		read: false,
		createdAt: now,
		updatedAt: now,
	};
	createNotification(notification)
		.then(() => {
			notificationsStore.addNotifications([notification])
		})
		.catch((e:any) => {
			console.log(e?.response.data);
		})
};
