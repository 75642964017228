import React, {useEffect, useState} from 'react';
import noMessagesImage from "../images/no-messages.png";
import EmptyComponent from "../components/Empty";
import { observer } from "mobx-react-lite";
import Loader from "../components/Loading";
import { marketplaceImages } from "../components/ListingImageCloud";
import { messagesStore } from "../state/Message";
import {getMessages, respondToBestOffer, updateMessage} from "../api/messages";
import {GetMessagesResponse, Message} from "app-ts-types/messages";
import {GetChatMessagesResponse} from "app-ts-types/chats";
import {getChatMessages} from "../api/chats";
import {chatMessagesStore} from "../state/Chats";
import {mergeUnique} from "../api/polling";
import {NotificationPopupComponent} from "../components/NotificationPopup";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export default observer(() => {
	const [loading, setLoading] = useState(true);
	const [counterOffer, setCounterOffer] = useState<{[key:string]:string }>({});
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	// Simulate fetching messages on mount.
	useEffect(() => {
		// If there are already messages in the store, skip loading.
		if (messagesStore.messages.length) {
			setLoading(false);
			return;
		}
		setLoading(true);
		getMessages()
			.then((data: GetMessagesResponse) => {
				// Assuming response.chatMessages is the array of ChatMessage items
				messagesStore.setMessages(mergeUnique(messagesStore.messages, data.data));
			})
			.catch((err:any) => {
				console.log("error fetching messages", err);
			})
			.finally(() => {
				setLoading(false);
			})
	}, []);

	const respondToBestOfferFunction = async (message:Message, action:"CounterOffer" | "Accept" | "Decline", counterOfferPrice:number) => {
		// Construct detailed message content for the best offer response.
		// Parse counter offer quantity from the message.content; default to 1 if not found
		let counterOfferQty = 1;
		const qtyRegex = /Quantity:\s*(\d+)/i;
		const qtyMatch = message.content.match(qtyRegex);
		if (qtyMatch && qtyMatch[1]) {
			counterOfferQty = parseInt(qtyMatch[1], 10);
		}

		// Build the payload to send to the API.
		const payload = {
			best_offer_id: message.id,
			action,
			item_id: message.messageLink,
			counter_offer_price: action === "CounterOffer" ? counterOfferPrice! : undefined,
			counter_offer_qty: action === "CounterOffer" ? counterOfferQty : undefined,
			seller_message: undefined,
		};

		console.log("Submitting Best Offer Response:", payload);

		try {
			// Call the API to respond to the best offer.
			await respondToBestOffer(payload);
			console.log("Message updated with response:", action);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: false,
				title: `Sent best offer message`,
				message: `Best offer response successfully sent to user`
			});
			return
		} catch (error:any) {
			console.error("Error updating message with response:", error);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Best offer error`,
				message: `Error sending best offer message to buyer: ${error.response?.data?.error}`
			});
			throw error
		}
	}

	const handleBestOfferResponse = async (
		message: Message,
		action: string,
		counterOfferPrice: string | null = null
	) => {
		let responseMessage = "";
		if (action === "Accept") {
			responseMessage = "✅ Offer Accepted";
		} else if (action === "Decline") {
			responseMessage = "❌ Offer Declined";
		} else if (action === "CounterOffer") {
			if (counterOfferPrice && parseFloat(counterOfferPrice) > 0) {
				responseMessage = `💰 Counter Offered: $${counterOfferPrice}`;
			} else {
				console.error("Invalid or missing counter offer price.");
				return;
			}
		} else {
			console.error("Invalid action provided.");
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Counter offer price`,
				message: `counter offer price invalid`
			});
			return;
		}

		// Build the updated message payload
		const updatedMessage: Message = {
			...message,
			responded: true,
			responseMessage,
		};

		try {
			await respondToBestOfferFunction(message, action, counterOfferPrice ? parseFloat(counterOfferPrice) : 0)
		} catch (e) {
			return;
		}

		try {
			// Update the message in the database via API
			await updateMessage(message.id, updatedMessage);
			// Update the message in the store
			messagesStore.updateMessage(updatedMessage);
			console.log("Message updated with response:", responseMessage);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: false,
				title: `Update Message Response`,
				message: `Message response successfully updated`
			});
		} catch (error:any) {
			console.error("Error updating message with response:", error);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Message update error`,
				message: `Error updating message ${error.message}`
			});
		}
	};

	// Handler when a message card is clicked.
	const handleMessageClick = async (message:Message) => {
		if (!message.read) {
			try {
				await updateMessage(message.id, { ...message, read: true });
				messagesStore.updateMessage({ ...message, read: true });
			} catch (err) {
				console.error("Error marking message as read", err);
			}
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	if (loading) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900">
				<Loader />
			</div>
		);
	}

	if (!messagesStore.messages || messagesStore.messages.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900 p-4">
				<EmptyComponent
					imageSrc={noMessagesImage}
					headerText={"No Messages"}
					descriptionText={"You don’t have any messages yet"}
				/>
			</div>
		);
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	return (
		<main className="dark:bg-gray-900 min-h-screen">
			{notificationComponent}
			<div className="space-y-8">
				<h1 className="text-3xl font-bold text-gray-900 dark:text-white">
					Messages
				</h1>
				<div className="flex flex-col md:flex-row items-center justify-between mb-6 space-y-4 md:space-y-0">
					{/* Filtering controls */}
					<div className="flex items-center space-x-4">
						<select
							value={messagesStore.filter}
							onChange={(e) => messagesStore.setFilter(e.target.value)}
							className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white"
						>
							<option value="">All Messages</option>
							<option value="read">Read</option>
							<option value="unread">Unread</option>
						</select>
					</div>
					{/* Sorting control */}
					<div>
						<button
							onClick={() => messagesStore.setSortOption({ field: "timestamp", direction: messagesStore.sortOption.direction === "desc" ? "asc" : "desc" })}
							className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none"
						>
							Sort by {" "}
							{messagesStore.sortOption.direction === "desc" ? "Newest" : "Oldest"} Date
						</button>
					</div>
				</div>
				{messagesStore.filteredAndSortedMessages.length === 0 ? (
					<div className="flex flex-col items-center justify-center py-20">
						<p className="text-gray-500 dark:text-gray-400">
							No messages found
						</p>
					</div>
				) : (
					<div className="divide-y rounded-lg divide-gray-300 dark:divide-gray-600">
						{messagesStore.filteredAndSortedMessages.map((message) => (
							<div
								key={message.id}
								onClick={() => handleMessageClick(message)}
								className={classNames(
									"bg-gray-100 dark:bg-gray-800 shadow p-4 cursor-pointer",
									"hover:bg-gray-200 dark:hover:bg-gray-700"
								)}
							>
								<h2 className="text-sm text-gray-700 dark:text-gray-300">
									New message from {message.sender}
								</h2>
								<p className="mt-2 font-bold text-gray-900 whitespace-pre-wrap dark:text-white">
									{message.content}
								</p>
								<div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center justify-between">
									<div className="flex items-center space-x-2">
										{marketplaceImages[message.platform] ? (
											<img
												src={marketplaceImages[message.platform].src}
												alt={marketplaceImages[message.platform].alt}
												className="h-6 w-6"
											/>
										) : (
											<span className="text-sm text-gray-500 dark:text-gray-400">
                        No Platform
                      </span>
										)}
										<span className="text-sm text-gray-500 dark:text-gray-400">
                      {new Date(message.timestamp * 1000).toLocaleString()}
                    </span>
									</div>
									<div>
										{message.read ? (
											<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Read
                      </span>
										) : (
											<span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Unread
                      </span>
										)}
									</div>
								</div>
								{message.needsAction && !message.responded && (
									<div className="mt-4 flex flex-col space-y-4">
										{/* Accept and Decline Buttons */}
										<div className="flex space-x-4">
											<button
												onClick={(e) => {
													e.stopPropagation();
													handleBestOfferResponse(message, "Accept");
												}}
												className="px-4 py-2 rounded-md transition-all focus:outline-none
                   bg-green-500 dark:bg-green-600 text-white hover:bg-green-600 dark:hover:bg-green-700"
											>
												Accept
											</button>

											<button
												onClick={(e) => {
													e.stopPropagation();
													handleBestOfferResponse(message, "Decline");
												}}
												className="px-4 py-2 rounded-md transition-all focus:outline-none
                   bg-red-500 dark:bg-red-600 text-white hover:bg-red-600 dark:hover:bg-red-700"
											>
												Decline
											</button>
										</div>

										{/* Counter Offer Section */}
										<div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md border dark:border-gray-600 border-gray-300">
											<label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
												Counter Offer Price ($)
											</label>
											<input
												type="number"
												min="0.01"
												step="0.01"
												value={counterOffer[message.id]}
												onChange={(e) => setCounterOffer({...counterOffer, [message.id]: e.target.value})}
												className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md
                   focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-900 dark:text-white"
											/>
											<button
												onClick={(e) => {
													e.stopPropagation();
													handleBestOfferResponse(message, "CounterOffer", counterOffer[message.id]);
												}}
												disabled={!counterOffer[message.id] || parseFloat(counterOffer[message.id]) <= 0}
												className={`mt-2 w-full px-4 py-2 rounded-md transition-all focus:outline-none
                        ${parseFloat(counterOffer[message.id]) > 0 ? "bg-blue-500 hover:bg-blue-600 dark:bg-blue-700 dark:hover:bg-blue-800 text-white" : "bg-gray-400 text-gray-200 cursor-not-allowed"}`}>
												Send Counter Offer
											</button>
										</div>
									</div>
								)}
								{message.responded && (
									/* Response Section */
									<div className="mt-4 p-4 rounded-md border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-800">
										<p className="text-gray-700 dark:text-gray-300 font-medium">Response Sent:</p>
										<p className="text-gray-900 dark:text-white font-semibold">
											{message.responseMessage}
										</p>
									</div>
								)}
							</div>
						))}
					</div>
				)}
			</div>
		</main>
	);
});
