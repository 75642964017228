import axiosInstance from './server';
import {
	ChatMessage,
	GetChatMessageResponse,
	GetChatMessagesResponse
} from 'app-ts-types/chats';

// Get a chat message by ID
export const getChatMessageById = async (id: string): Promise<GetChatMessageResponse> => {
	const response = await axiosInstance.get<GetChatMessageResponse>(`/chats/${id}`);
	return response.data;
};

// Get all chat messages
export const getChatMessages = async (): Promise<GetChatMessagesResponse> => {
	const response = await axiosInstance.get<GetChatMessagesResponse>(`/chats/`);
	return response.data;
};

// Create a new chat message
export const createChatMessage = async (data: ChatMessage): Promise<GetChatMessageResponse> => {
	const response = await axiosInstance.post<GetChatMessageResponse>('/chats', data);
	return response.data;
};

// Update a chat message by ID
export const updateChatMessage = async (id: string, data: ChatMessage): Promise<GetChatMessageResponse> => {
	const response = await axiosInstance.put<GetChatMessageResponse>(`/chats/${id}`, data);
	return response.data;
};

// Delete a chat message by ID
export const deleteChatMessage = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/chats/${id}`);
};
