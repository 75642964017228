import { makeAutoObservable } from "mobx";
import { Expense } from "../utils/types";
export const sampleExpenses: Expense[] = [
	{
		id: 'expense-1',
		user: 'user-1',
		title: 'Office Supplies',
		description: 'Purchased pens, notebooks, and paper for the office.',
		currencyCode: 'USD',
		expenseDuration: "One-Time",
		price: 49.99,
		date: new Date('2023-09-01').getTime(),
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	{
		id: 'expense-2',
		user: 'user-2',
		title: 'Travel Expenses',
		description: 'Flight tickets and hotel booking for conference travel.',
		currencyCode: 'USD',
		price: 1200.00,
		expenseDuration: "One-Time",
		date: new Date('2023-08-15').getTime(),
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	{
		id: 'expense-3',
		user: 'user-3',
		title: 'Client Lunch',
		description: 'Lunch meeting with potential client at upscale restaurant.',
		currencyCode: 'USD',
		expenseDuration: "One-Time",
		price: 85.50,
		date: new Date('2023-07-20').getTime(),
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	{
		id: 'expense-4',
		user: 'user-1',
		title: 'Software Subscription',
		description: 'Monthly fee for cloud-based design software.',
		currencyCode: 'USD',
		price: 29.99,
		expenseDuration: "One-Time",
		date: new Date('2023-09-05').getTime(),
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
	{
		id: 'expense-5',
		user: 'user-4',
		title: 'Marketing Materials',
		description: 'Printed brochures and business cards for new campaign.',
		currencyCode: 'USD',
		expenseDuration: "One-Time",
		price: 300.00,
		date: new Date('2023-08-10').getTime(),
		createdAt: Date.now(),
		updatedAt: Date.now(),
	},
];


class ExpensesStore {
	expenses: Expense[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "createdAt", direction: "DESC" };
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setExpenses(expenses: Expense[]) {
		this.expenses = expenses;
	}

	addExpenses(newItems: Expense[]) {
		newItems.forEach(newItem => {
			const index = this.expenses.findIndex(item => item.id === newItem.id);
			if (index !== -1) {
				this.expenses[index] = newItem;
			} else {
				this.expenses.push(newItem);
			}
		});
	}

	updateExpense(updatedItem: Expense) {
		const index = this.expenses.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.expenses[index] = updatedItem;
		}
	}

	deleteExpense(itemId: string) {
		this.expenses = this.expenses.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedExpenses(): Expense[] {
		let filteredExpenses = this.expenses;

		if (this.filter) {
			filteredExpenses = filteredExpenses.filter(expense =>
				expense.title.includes(this.filter)
			);
		}

		if (this.sortOption.field) {
			filteredExpenses = filteredExpenses.slice().sort((a, b) => {
				if (this.sortOption.direction === "ASC") {
					// @ts-ignore: dynamic sort on field
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore: dynamic sort on field
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}

		return filteredExpenses;
	}

	get paginatedExpenses(): Expense[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedExpenses.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedExpenses.length / this.itemsPerPage);
	}
}

export const expensesStore = new ExpensesStore();
