import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import noExpensesImage from "../images/expenses-icon.png";
import EmptyComponent from "../components/Empty";
import {expensesStore} from "../state/Expenses";
import {Expense} from "../utils/types";
import {deleteExpense, getExpenses} from "../api/expense";
import Loader from "../components/Loading";
import {observer} from "mobx-react-lite";
import {CheckCircleIcon, EllipsisHorizontalIcon, XCircleIcon} from "@heroicons/react/20/solid";
import {Menu, Transition} from "@headlessui/react";
import {NotificationPopupComponent} from "../components/NotificationPopup";
function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}
// the exported Expenses component
const Expenses = observer(({handleCreateInventory, handleCreateNewExpense, handleCreateListing}: {
	handleCreateInventory: () => void;
	handleCreateNewExpense: (data?:Expense) => void;
	handleCreateListing: () => void;
}) => {
	const navigate = useNavigate();
	const [loadExpenses, setLoadExpenses] = useState(false);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	useEffect(() => {
		if (expensesStore.expenses.length) return;
		setLoadExpenses(true);
		getExpensesObjects();
	}, []);

	const handleView = (expense: Expense) => {
		handleCreateNewExpense(expense);
	}

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	const getExpensesObjects = () => {
		getExpenses()
			.then((res:any) => {
				if(!res.isError) {
					expensesStore.setExpenses(res.data)
					setLoadExpenses(false);
				}
			})
			.catch((err:any) => {
				console.log(err);
				setLoadExpenses(false);
			})
	}

	const handleDelete = async (expense: any) => {
		await deleteExpense(expense.id)
			.then((res: any) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: false,
					title: `Expense Removed`,
					message: `Expense object successfully removed`
				});
				expensesStore.deleteExpense(expense.id);
			}).catch((err: any) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: `Expense Delete error`,
					message: err.message
				})
			});
	}

	const component = <EmptyComponent
		imageSrc={noExpensesImage}
		headerText={"Expenses"}
		descriptionText={"You have no saved Expenses"}
		actionText={"Create New Expense"}
		onActionClick={handleCreateNewExpense}
	/>

	let expensesComponent;
	if (expensesStore.paginatedExpenses.length === 0) {
		expensesComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				{ component }
			</div>
		);
	} else {
		expensesComponent = (
		<div className="bg-transparent overflow-hidden">
			<table className="min-w-full">
				<thead className="hidden border-b dark:border-gray-600 border-gray-300 rounded-lg sm:table-header-group bg-slate-200/50 dark:bg-slate-900/50">
				<tr>
					<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Expense Date</th>
					<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Title</th>
					<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Price</th>
					<th className="px-4 py-3 text-left text-sm font-semibold text-gray-900 dark:text-white">Description</th>
					<th className="px-4 py-3 text-right text-sm font-semibold text-gray-900 dark:text-white">Expense Duration</th>
					<th className="px-4 py-3 text-sm font-semibold text-gray-900 text-right dark:text-white">Actions</th>
				</tr>
				</thead>
				<tbody className="divide-y divide-gray-200 dark:divide-gray-700">
				{expensesStore.paginatedExpenses.map((expense: Expense, index:number) => {
					return (
						<tr key={expense.id + index} className="flex flex-col sm:table-row sm:flex-row">
							{/* Template Name */}
							<td className="px-4 py-4 text-sm text-gray-900 dark:text-gray-100 font-medium sm:w-1/5">
								{new Date(expense.date).toDateString()}
							</td>
							{/* Template Description */}
							<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
								{expense.title}
							</td>
							<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
								{expense.currencyCode  === 'USD' ? '$' : expense.currencyCode  === 'EUR' ? '€' : expense.currencyCode  === 'GBP' ? '£' : '$'}{expense.price.toFixed(2)}
							</td>
							<td className="px-4 py-4 text-left text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
								{expense.description}
							</td>
							{/* Template creation or update date */}
							<td className="px-4 py-4 text-right text-sm text-gray-500 dark:text-gray-300 sm:w-1/5">
								{expense.expenseDuration || "One-Time"}
							</td>
							{/* Actions */}
							<td className="px-4 py-4 text-sm w-full flex justify-end text-right sm:justify-start">
								{
									expense.id && (
										<Menu as="div" className="relative text-right w-full">
											<div className={"text-right"}>
												<Menu.Button className="p-2 text-gray-400 hover:text-gray-500 dark:hover:text-gray-300">
													<span className="sr-only">Open options</span>
													<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
												</Menu.Button>
											</div>
											<Transition
												as={Fragment}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<Menu.Items
													className="absolute border dark:border-gray-600 divide-y divide-gray-500 bottom-0 right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
													<Menu.Item>
														{({active}) => (
															<button
																onClick={() => handleView(expense)}
																className={classNames(
																	active ? 'bg-gray-100 dark:bg-gray-700' : '',
																	'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																)}
															>
																View
															</button>
														)}
													</Menu.Item>
													<Menu.Item>
														{({active}) => (
															<button
																onClick={() => handleDelete(expense)}
																className={classNames(
																	active ? 'bg-gray-100 dark:bg-gray-700' : '',
																	'block w-full px-4 py-2 text-sm text-gray-900 dark:text-white'
																)}
															>
																Delete
															</button>
														)}
													</Menu.Item>
												</Menu.Items>
											</Transition>
										</Menu>
									)
								}
							</td>
						</tr>
					)
				})}
				</tbody>
			</table>
		</div>
		)
	}

	if (loadExpenses) {
		expensesComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	return (
		<main>
			{notificationComponent}
			<div className="space-y-8">
				<div
					className={"flex md:flex-row flex-col lg:space-x-4 space-y-4 lg:space-y-0 mx-auto w-full justify-between"}>
					{/*<h1 className={"text-3xl font-bold"}>Expenses & Expensess</h1>*/}
					<h1 className={"text-3xl -space-y-8 m-0 font-bold"}>
						<span className={"block"}>Expenses</span>
						<span className={"text-base text-red-500"}>
							Total Expenses:
							{Object.entries(
								expensesStore.expenses.reduce((acc, expense) => {
									acc[expense.currencyCode] = (acc[expense.currencyCode] || 0) + expense.price;
									return acc;
								}, {} as Record<string, number>)
							).map(([currency, total]) => {
								const symbol = currency === 'USD' ? '$' : currency === 'EUR' ? '€' : currency === 'GBP' ? '£' : '';
								return (
									<div key={currency}>
										{symbol}{total.toFixed(2)} {currency}
									</div>
								);
							})}
						</span>
					</h1>
					<button
						onClick={() => {
							handleCreateNewExpense();
						}}
						className="mt-6 rounded-md self-start bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						Create Expense
					</button>
				</div>
				<div className="overflow-hidden w-full h-full bg-white dark:bg-gray-800/10 sm:rounded-lg min-h-fit">
					<div className="h-full">
						{ expensesComponent }
					</div>
				</div>
			</div>
		</main>
	);
});
export default Expenses;
