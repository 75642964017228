import axiosInstance from './server';
import { Collection, CreateCollectionRequest, UpdateCollectionRequest, GetCollectionResponse, GetCollectionsResponse } from 'app-ts-types/collections';
import {createNotification, initNotification} from "./notifications";

// Get a collection by ID
export const getCollectionById = async (id: string): Promise<GetCollectionResponse> => {
	const response = await axiosInstance.get<GetCollectionResponse>(`/collection/${id}`);
	return response.data;
};

// Get a collections
export const getCollections = async (): Promise<GetCollectionsResponse> => {
	const response = await axiosInstance.get<GetCollectionsResponse>(`/collection`);
	return response.data;
};

// Create a new collection
export const createCollection = async (data: Collection): Promise<GetCollectionResponse> => {
	try{
		const response = await axiosInstance.post<GetCollectionResponse>('/collection', data);
		initNotification(data!.id, "Collection creation", "Collection successfully created").catch((e) => {console.log(e);});
		return response.data;
	} catch (e:any) {
		initNotification(data.id, "Error creating collection", e.response?.data?.error).catch((e) => {console.log(e);});
		console.log(e.response?.data);
		throw e;
	}
};

// Update a collection by ID
export const updateCollection = async (id: string, data: Collection): Promise<GetCollectionResponse> => {
	try {
		const response = await axiosInstance.put<GetCollectionResponse>(`/collection/${id}`, data);
		initNotification(data.id, "Collection update", "Collection successfully updated").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error updating collection", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a collection by ID
export const deleteCollection = async (id: string): Promise<void> => {
	try {
		await axiosInstance.delete(`/collection/${id}`);
		initNotification(id, "Collection deletion", "Collection successfully deleted").catch((e) =>
			console.log(e)
		);
	} catch (e: any) {
		initNotification(id, "Error deleting collection", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};
