import {Inventory, Listing, MarketplacePlatform, marketplacePlatformToJSON, Platform} from "../utils/types";

import ebayMarketPlace, {EbayPlatformOperations} from "./ebay";
import amazonMarketPlace, {AmazonPlatformOperations} from "./amazon";
import grailedMarketPlace, {GrailedPlatformOperations} from "./grailed";
import mercariMarketPlace, {MercariPlatformOperations} from "./mercari";
import goatMarketPlace, {GoatPlatformOperations} from "./goat";
import depopMarketPlace, {DepopPlatformOperations} from "./depop";
import shopifyMarketPlace, {ShopifyPlatformOperations} from "./shopify";
import stockxMarketPlace, {StockxPlatformOperations} from "./stockx";
import etsyMarketPlace, {EtsyPlatformOperations} from "./etsy";
import facebookMarketPlace, {FacebookPlatformOperations} from "./facebook";
import poizonMarketPlace, {PoizonPlatformOperations} from "./poizon";
import poshmarkMarketPlace, {PoshmarkPlatformOperations} from "./poshmark";
import {createInventory, updateInventory} from "../api/inventories";
import {inventoryStore} from "../state/Inventory";
import {generateUUID} from "../utils/data";
import {userStore} from "../state/User";
import {BasePlatformOperations} from "./base";
import notificationStore from "../state/PopupNotification";
import {ListingAPIResponseType} from "../types";


export const MARKETPLACES: Platform[] = [
	ebayMarketPlace,
	amazonMarketPlace,
	grailedMarketPlace,
	mercariMarketPlace,
	goatMarketPlace,
	depopMarketPlace,
	shopifyMarketPlace,
	stockxMarketPlace,
	etsyMarketPlace,
	poshmarkMarketPlace,
	facebookMarketPlace,
	// woocommerceMarketPlace,
	poizonMarketPlace,
]

export default class ListingPlatform {
	private readonly listingObjects: { [key:number]:Listing} | undefined;
	private inventory: Inventory | undefined;
	private allPlatformOperations: BasePlatformOperations[] = [];
	private newInventoryId:string = generateUUID();

	// let's pass the inventory and listing objects here
	// data sanitization, connections and data checks happens here before the listing begins
	// if a platform cannot be listed, remove it from the inventory object before listing.
	constructor(listingObjects?: { [key:number]:Listing}, inventory?:Inventory) {
		this.listingObjects = listingObjects;
		this.inventory = inventory;
	}

	// the starting process of the listing
	// we begin from here
	init = async (): Promise<ListingAPIResponseType> => {
		return this
			.validatePlatforms()
			.then(async (res:any[]):Promise<ListingAPIResponseType> => {
				// if there's an errors object and the error is not empty
				// then let's get ready to start crafting the error notification
				if(res && res.length) {
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: true,
						closeTime: 10e3,
						title: `Listing Error`,
						message: res.join(" \n ")
					});
				}

				// if there are some platforms that are fully verified
				// refreshed and are ready to start making posts
				// then let's begin
				if(this.allPlatformOperations.length) {
					// let's update the inventory object
					const inv = await this.createOrUpdateInventoryFunction();
					// let's cache the listing objects in store for visual proof
					this.allPlatformOperations.map((po) => {
						if(inv && !inv.isError)
							po.updateListingInventoryId(inv.data.id);
						po.cacheListingObjectInStore()
					});
					// now let's begin the listing here
					this.__init__().catch((err:any) => {console.log(err);});
					// return the results of everything after the validation
					return {
						success: true,
						isError: false,
						listingId: "",
						message: "Listings in progress. You'll be updated shortly",
						platform: "",
						data: undefined,
					};
				}

				// return the results of everything after the validation
				return {
					success: false,
					isError: true,
					listingId: "",
					message: res.join(" \n "),
					platform: "",
					data: undefined,
				};
			})
	}

	// updateListing
	// when some of the fields of the listing changes
	// this should be reflected on the marketplace
	updateListing = async (listing:Listing):Promise<ListingAPIResponseType> => {
		// Check if the user is still connected to the platform
		const platformOperations = this.getPlatformOperations(listing.platform, listing, listing.inventoryId);
		const connectionStatus = await platformOperations.isUserStillConnected();
		if (!connectionStatus.isConnected) {
			return {
				success: false,
				isError: true,
				platform: marketplacePlatformToJSON(listing.platform),
				listingId: listing.id,
				message: connectionStatus.message,
				data: null,
			}
		}
		return platformOperations
			.updateListing()
			.then((data:any) => {
				return {
					success: true,
					data,
					isError: false,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: "Successfully updated listing on marketplace",
				}
			})
			.catch((err: any) => {
				return {
					success: false,
					isError: true,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: err.response.data.message || err.response.data.error,
					data: null,
				}
			})
	};

	// deListListing
	// remove the listing object from the marketplace but do not delete from system
	deListListing = async (listing:Listing):Promise<ListingAPIResponseType> => {
		// Check if the user is still connected to the platform
		const platformOperations = this.getPlatformOperations(listing.platform, listing, listing.inventoryId);
		const connectionStatus = await platformOperations.isUserStillConnected();
		if (!connectionStatus.isConnected) {
			return {
				success: false,
				isError: true,
				platform: marketplacePlatformToJSON(listing.platform),
				listingId: listing.id,
				message: connectionStatus.message,
				data: null,
			}
		}
		return platformOperations
			.deListListing()
			.then((data) => {
				return {
					success: true,
					data,
					isError: false,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: "Successfully updated listing on marketplace",
				}
			})
			.catch((err: any) => {
				return {
					success: false,
					isError: true,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: err.response.data.message || err.response.data.error,
					data: null,
				}
			})
	};

	// reListListing
	// when the listing is temporarily disabled and you wanna re-activate it
	// in some cases it involves creating the listing all over again
	reListListing = async (listing:Listing):Promise<ListingAPIResponseType> => {
		// Check if the user is still connected to the platform
		const platformOperations = this.getPlatformOperations(listing.platform, listing, listing.inventoryId);
		const connectionStatus = await platformOperations.isUserStillConnected();
		if (!connectionStatus.isConnected) {
			return {
				success: false,
				isError: true,
				platform: marketplacePlatformToJSON(listing.platform),
				listingId: listing.id,
				message: connectionStatus.message,
				data: null,
			}
		}
		return platformOperations
			.reListListing()
			.then((data) => {
				return {
					data,
					success: true,
					isError: false,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: "Successfully updated listing on marketplace",
				}
			})
			.catch((err: any) => {
				return {
					success: false,
					isError: true,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: err.response.data.message || err.response.data.error,
					data: null,
				}
			})
	};

	// deleteListing
	// when the listing is temporarily disabled and you wanna re-activate it
	// in some cases it involves creating the listing all over again
	deleteListing = async (listing:Listing):Promise<ListingAPIResponseType> => {
		// Check if the user is still connected to the platform
		const platformOperations = this.getPlatformOperations(listing.platform, listing, listing.inventoryId);
		const connectionStatus = await platformOperations.isUserStillConnected();
		if (!connectionStatus.isConnected) {
			return {
				success: false,
				isError: true,
				platform: marketplacePlatformToJSON(listing.platform),
				listingId: listing.id,
				message: connectionStatus.message,
				data: null,
			}
		}
		return platformOperations
			.deleteListing()
			.then((data) => {
				return {
					data,
					success: true,
					isError: false,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: "Successfully deleted listing",
				}
			})
			.catch((err: any) => {
				return {
					success: false,
					isError: true,
					platform: marketplacePlatformToJSON(listing.platform),
					listingId: listing.id,
					message: err.response.data.message || err.response.data.error,
					data: null,
				}
			})
	};

	// __init__ is where we perform some validations before we even begin the listing process
	__init__ = async() => {
		const listingPromises = this.allPlatformOperations.map(async (po) => {
			return po.init();
		})
		Promise
			.all(listingPromises)
			.then((res: ListingAPIResponseType[]) => {
				// loop through all the data of the request
				const failures: string[] = [];
				const success: string[] = [];
				res.forEach((data) => { if(!data.success) { failures.push(data.message) } else { success.push(data.message) } })
				if(failures.length) {
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: true,
						title: `Listing Error`,
						closeTime: 20e3,
						message: failures.join(", \n")
					});
				}

				if(success.length) {
					notificationStore.setNotification({
						show: true,
						autoClose: true,
						isError: false,
						title: `Listing Success`,
						closeTime: 20e3,
						message: success.join(", \n"),
					});
				}
			})
			.catch((err) => {
				notificationStore.setNotification({
					show: true,
					autoClose: true,
					isError: true,
					title: `Listing Error`,
					message: `An unknown error has occurred. Please try listing again 😥\n ${err.message}`
				});
			})
	}

	/**
	 * Validate platforms and their connections.
	 */
	private async validatePlatforms(): Promise<string[]> {
		if(!this.listingObjects || !this.inventory) return [];
		const errors: string[] = [];

		// Iterate over listing objects to validate platform connections
		const validationPromises = Object.entries(this.listingObjects).map(
			async ([platformKey, listing]) => {
				const platform = Number(platformKey) as MarketplacePlatform;

				// if this platform is of type unrecognized
				if(platform === -1 ) return;

				// Check if user has a connection for the platform
				const userPlatformDetails = userStore.user?.platformDetails[marketplacePlatformToJSON(platform)];
				if (!userPlatformDetails) {
					errors.push(`No account connection found for platform: ${marketplacePlatformToJSON(platform)}`);
					// Remove from listingObjects
					// @ts-ignore
					delete this.listingObjects[platform];
					// Remove that platform from the inventory object too
					// @ts-ignore
					this.inventory.platform = this.inventory.platform.filter((p) => p !== platform);
					return;
				}

				// Check if the user is still connected to the platform
				// @ts-ignore
				const platformOperations = this.getPlatformOperations(platform, listing, this.inventory.id || this.newInventoryId);
				const connectionStatus = await platformOperations.isUserStillConnected();

				if (!connectionStatus.isConnected) {
					errors.push(connectionStatus.message);
					// Remove from listingObjects
					// @ts-ignore
					delete this.listingObjects[platform];
					// Remove that platform from the inventory object too
					// @ts-ignore
					this.inventory.platform = this.inventory.platform.filter((p) => p !== platform);
				} else {
					// let cache the list of platform operation that are good to go.
					// we need this
					this.allPlatformOperations.push(platformOperations)
				}
			}
		);

		// Wait for all validation promises to complete
		await Promise.all(validationPromises);

		return errors;
	}

	/**
	 * Factory method to get the appropriate PlatformOperations instance.
	 */
	private getPlatformOperations(platform: MarketplacePlatform, listing: Listing, inventoryId: string): BasePlatformOperations {
		switch (platform) {
			case MarketplacePlatform.EBAY:
				return new EbayPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.GRAILED:
				return new GrailedPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.MERCARI:
				return new MercariPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.SHOPIFY:
				return new ShopifyPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.STOCKX:
				return new StockxPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.POSHMARK:
				return new PoshmarkPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.GOAT:
				return new GoatPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.AMAZON:
				return new AmazonPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.ETSY:
				return new EtsyPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.DEPOP:
				return new DepopPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.FACEBOOK:
				return new FacebookPlatformOperations(listing, inventoryId);
			case MarketplacePlatform.POIZON:
				return new PoizonPlatformOperations(listing, inventoryId);
			default:
				throw new Error(`Unsupported platform: ${MarketplacePlatform[platform]}`);
		}
	}

	createOrUpdateInventoryFunction = async (): Promise<any> => {
		if(!this.inventory) return
		// should inventory be updated or created
		const func = this.inventory.id ? updateInventory.bind(this, this.inventory.id) : createInventory;
		// let's make sure the inventory actually has an ID
		return func({...this.inventory, id: this.inventory.id || this.newInventoryId})
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					//@ts-ignore
					if (this.inventory.id) {
						inventoryStore.updateInventory(res.data);
					} else {
						inventoryStore.addOrUpdateInventory(res.data);
					}
				}
				return res;
			})
			.catch((err:any) => {
				console.log(err);
				return null;
			});
	};
}
