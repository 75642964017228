import axiosInstance from './server';
import {
	BestOffer,
	CreateBestOfferRequest,
	UpdateBestOfferRequest,
	GetBestOfferResponse,
	GetBestOffersResponse,
} from 'app-ts-types/best-offers';

// Get a best offer by ID
export const getBestOfferById = async (id: string): Promise<GetBestOfferResponse> => {
	const response = await axiosInstance.get<GetBestOfferResponse>(`/bestoffer/${id}`);
	return response.data;
};

// Get multiple best offers
export const getBestOffers = async (filter?:string, filterId?:string): Promise<GetBestOffersResponse> => {
	let searchParams = "";
	if(filter && filterId) { searchParams=`?filter=${filter}&filterId=${filterId}`}
	const response = await axiosInstance.get<GetBestOffersResponse>(`/bestoffer${searchParams}`);
	return response.data;
};

// Create a new best offer
export const createBestOffer = async (data: CreateBestOfferRequest): Promise<GetBestOfferResponse> => {
	const response = await axiosInstance.post<GetBestOfferResponse>('/bestoffer', data);
	return response.data;
};

// Update a best offer by ID
export const updateBestOffer = async (id: string, data: UpdateBestOfferRequest): Promise<GetBestOfferResponse> => {
	const response = await axiosInstance.put<GetBestOfferResponse>(`/bestoffer/${id}`, data);
	return response.data;
};

// Delete a best offer by ID
export const deleteBestOffer = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/bestoffer/${id}`);
};
