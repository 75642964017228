import {observer} from "mobx-react-lite";
import React, {useState, useEffect} from "react";
import noNotificationsImage from "../images/no-notifications.png";
import EmptyComponent from "../components/Empty";
import {Notification} from "app-ts-types/notifications";
import {notificationsStore} from "../state/Notification";
import {getNotifications, updateNotification} from "../api/notifications";
import {mergeUnique} from "../api/polling";
import Loader from "../components/Loading";
import {marketplaceImages} from "../components/ListingImageCloud";


export default observer(() => {
	const [loading, setLoading] = useState(false);
	// Sort order for createdAt ("desc" = newest first)
	const [sortOrder, setSortOrder] = useState("desc");

	useEffect(() => {
		if (notificationsStore.notifications.length) return;
		setLoading(true)
		getNotifications()
			.then((data: any) => {
				// Assuming response.notifications is the array of Notification items
				notificationsStore.setNotifications(
					mergeUnique(data.data, notificationsStore.notifications)
				);
			})
			.catch((err: any) => {
				console.error("Error polling notifications:", err);
			})
			.finally(() => {
				setLoading(false);
			})
	}, []);

	// Handler when a notification is clicked.
	const handleNotificationClick = async (notification: Notification) => {
		if (!notification.read) {
			try {
				await updateNotification(notification.id, {...notification, read: true});
				notificationsStore.updateNotification({...notification, read: true});
			} catch (err) {
				console.error('Error marking notification as read', err);
			}
		}
	};

	if (loading) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	if (!notificationsStore.notifications) {
		return <div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900 p-4">
			<div className="w-full text-center">
				<EmptyComponent
					imageSrc={noNotificationsImage}
					headerText={"No Notifications"}
					descriptionText={"You're all caught up!"}
				/>
			</div>
		</div>
	}

	return (
		<main>
			<div className="space-y-8">
				<h1 className={"text-3xl font-bold"}>Notifications</h1>
				<div className="flex flex-col min-h-screen dark:bg-gray-900">
					<div className="w-full text-center">
						<div className="flex flex-col md:flex-row items-center justify-between mb-6 space-y-4 md:space-y-0">
							{/* Filters */}
							<div className="flex items-center space-x-4">
								<select
									value={notificationsStore.filter}
									onChange={(e) => notificationsStore.setFilter(e.target.value)}
									className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white"
								>
									<option value="">All Notifications</option>
									<option value="read">Read</option>
									<option value="unread">Unread</option>
								</select>
							</div>
							{/* Sort Button */}
							<div>
								<button
									onClick={() => notificationsStore.setSortOption({ field: "createdAt", direction: notificationsStore.sortOption.direction === "desc" ? "asc" : "desc" })}
									className="px-4 py-2 bg-indigo-500 text-white rounded-md hover:bg-indigo-600 focus:outline-none"
								>
									Sort by {notificationsStore.sortOption.direction === "desc" ? "Oldest" : "Newest"} Date
								</button>
							</div>
						</div>
						{notificationsStore.filteredAndSortedNotifications.length === 0 ? (
							<div className="flex flex-col items-center justify-center py-20">
								<p className="text-gray-500 dark:text-gray-400">
									No notifications found
								</p>
							</div>
						) : (
							<div className="bg-gray-100 dark:bg-gray-800 sm:rounded-lg shadow overflow-x-auto">
								<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
									<thead className="hidden sm:table-header-group bg-gray-200 dark:bg-gray-700">
									<tr>
										<th
											className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
											Type
										</th>
										<th
											className="px-6 py-3 text-xs text-center font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
											Content
										</th>
										<th
											className="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
											Platform
										</th>
										<th
											className="px-6 py-3 text-center text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
											Date
										</th>
										<th
											className="px-6 py-3 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
											Status
										</th>
									</tr>
									</thead>
									<tbody className="bg-gray-100 dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
									{notificationsStore.filteredAndSortedNotifications.map((notification) => (
										<tr
											id={`id-${notification.id}`}
											key={notification.id}
											onClick={() => handleNotificationClick(notification)}
											className="hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer flex flex-col sm:table-row sm:flex-row"
										>
											<td
												className={`px-6 text-left sm:w-1/3 py-4 ${notification.type.toLowerCase().includes("error") ? "text-red-500" : "text-gray-900 dark:text-white"} text-sm font-medium`}>
												{notification.type}
											</td>
											<td className="px-6 py-4 sm:w-1/3 whitespace-normal text-sm text-gray-500 dark:text-gray-300">
												{notification.content}
											</td>
											<td
												className="px-6 py-4 whitespace-nowrap text-sm text-center flex justify-center text-gray-500 dark:text-gray-300">
												{notification.platform === -1 ? (
													"No Platform"
												) : (
													marketplaceImages[notification.platform] ? (
														<img
															src={marketplaceImages[notification.platform].src}
															alt={marketplaceImages[notification.platform].alt}
															className="h-8 w-8 rounded-full"
														/>
													) : (
														"No Platform"
													)
												)}
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
												{new Date(notification.timestamp).toLocaleString()}
											</td>
											<td className="px-6 py-4 whitespace-nowrap text-sm">
												{notification.read ? (
													<span
														className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Read
                      </span>
												) : (
													<span
														className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                        Unread
                      </span>
												)}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						)}
					</div>
				</div>
			</div>
		</main>
	);
});
