import {makeAutoObservable, toJS} from "mobx";
import {
	Inventory,
	Categories,
	Statuses,
	StockLevel,
} from "../utils/types";
import {MarketplacePlatform} from "app-ts-types/platforms";
import {Share} from "app-ts-types/shares";


class InventoryStore {
	sharedLink: Share | null = null;
	inventory: Inventory[] = []; // inventories;
	filter: string = "";
	sortOption: { field: string; direction: string } = {field: "name", direction: "desc"};
	currentPage: number = 1;
	itemsPerPage: number = 100;
	selectedCategory: Categories | "" = "";
	selectedPlatform: MarketplacePlatform | "" = "";
	selectedStatus: Statuses | "" = "";
	selectedStockLevel: StockLevel | "" = "";
	selectedCollection: string = ""

	constructor() {
		makeAutoObservable(this);
	}

	setSharedLink(sharedLink:Share) {
		this.sharedLink = sharedLink;
	}

	setInventory(inventory: Inventory[]) {
		this.inventory = inventory;
	}

	addInventory(newItems: Inventory[]) {
		this.inventory = [...newItems, ...this.inventory];
	}

	loadNewInventories(newItems: Inventory[]) {
		this.inventory = [...this.inventory, ...newItems];
	}

	addOrUpdateInventory(updatedItem: Inventory) {
		const index = this.inventory.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.inventory[index] = updatedItem;
		} else {
			this.inventory = [updatedItem, ...this.inventory];
		}
	}

	updateInventory(updatedItem: Inventory) {
		const index = this.inventory.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.inventory[index] = updatedItem;
		}
	}

	deleteInventory(itemId: string) {
		this.inventory = this.inventory.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setSelectedCategory(category: Categories | "") {
		this.selectedCategory = category;
	}

	setSelectedCollection(collectionId: string | "") {
		this.selectedCollection = collectionId;
	}

	setSelectedPlatform(platform: MarketplacePlatform | "") {
		this.selectedPlatform = platform;
	}

	setSelectedStatus(status: Statuses | "") {
		this.selectedStatus = status;
	}

	setSelectedStockLevel(stockLevel: StockLevel | "") {
		this.selectedStockLevel = stockLevel;
	}

	get filteredAndSortedInventory(): Inventory[] {
		let filteredInventory = this.inventory;
		if (this.filter) {
			filteredInventory = filteredInventory.filter(item =>
				item.name.toLowerCase().includes(this.filter.toLowerCase()) || item.collectionId.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.selectedCategory !== "") {
			filteredInventory = filteredInventory.filter(item =>
				item.category.toString() === this.selectedCategory.toString()
			);
		}

		if (this.selectedPlatform !== "") {
			filteredInventory = filteredInventory.filter(item =>
				item.platform.toString() === this.selectedPlatform.toString()
			);
		}

		if (this.selectedStatus !== "") {
			filteredInventory = filteredInventory.filter(item =>
				item.status.toString() === this.selectedStatus.toString()
			);
		}

		if (this.selectedStockLevel !== "") {
			filteredInventory = filteredInventory.filter(item =>
				item.stockLevel.toString() === this.selectedStockLevel.toString()
			);
		}

		if (this.selectedCollection !== "") {
			filteredInventory = filteredInventory.filter(item =>
				item.collectionId.toString() === this.selectedCollection.toString()
			);
		}

		if (this.sortOption.field) {
			filteredInventory = filteredInventory.slice().sort((a: Inventory, b: Inventory) => {
				const field = this.sortOption.field;
				const direction = this.sortOption.direction;

				// Check if the field is nested
				const isNestedField = field.includes('.');

				// Helper function to get the value of a nested field
				const getValue = (obj: any, path: string) => {
					return path.split('.').reduce((acc, part) => acc && acc[part], obj);
				};

				// @ts-ignore
				const aValue = isNestedField ? getValue(a, field) : a[field];
				// @ts-ignore
				const bValue = isNestedField ? getValue(b, field) : b[field];

				if (direction === "asc") {
					return aValue > bValue ? 1 : -1;
				} else {
					return aValue < bValue ? 1 : -1;
				}
			});
		}

		return filteredInventory;
	}

	get paginatedInventory(): Inventory[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedInventory.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedInventory.length / this.itemsPerPage);
	}
}

export const inventoryStore = new InventoryStore();
