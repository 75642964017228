'use client'
import {observer} from "mobx-react-lite";
import {Menu, Transition} from "@headlessui/react";
import {ArrowLongRightIcon, BellIcon, EnvelopeIcon} from "@heroicons/react/24/outline";
import React from "react";
import {Link} from "react-router-dom";
import {messagesStore} from "../state/Message";

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}
export default observer(() => {
	return (
		<Menu as="div" className="relative">
			<Menu.Button className="-m-1.5 flex items-center p-1.5">
				<span className="sr-only">Open Notifications</span>
				<button type="button"
				        className="flex relative -m-2.5 p-2.5 text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-400">
					<span className="sr-only">View messages</span>
					<EnvelopeIcon aria-hidden="true" className={`h-6 w-6}`}/>
				</button>
				{messagesStore.messages.filter(n => !n.read).length ? (<span className={"text-red-500 absolute -top-2 -right-2"}>*</span>) : null}
			</Menu.Button>
			<Transition
				as={React.Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items
					className="absolute max-h-[60vh] overflow-y-scroll divide-y divide-gray-300 dark:divide-gray-600 -right-16 md:-right-32 z-10 mt-2.5 w-48 md:w-60 lg:w-80 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
					<Menu.Item>
						<Link
							to={"/messages"}
							className={classNames(
								'w-full text-deepPurple flex text-left px-3 py-1 text-sm leading-6 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700'
							)}
						>
							<span className={"text-deepPurple dark:text-brightPurple"}>All Messages</span> &nbsp;
							<ArrowLongRightIcon aria-hidden="true" className={`h-6 w-6 text-deepPurple dark:text-brightPurple`}/>
						</Link>
					</Menu.Item>
					{messagesStore.filteredAndSortedMessages.map((item) => (
						<Menu.Item key={item.id}>
							<Link
								to={`/messages#id-${item.id}`}
								className={classNames(
									'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-700'
								)}
							>
								<p className={`font-bold ${item.messageId.toLowerCase().includes("error") ? "text-red-500" : "text-gray-900 dark:text-white"}`}>{item.content.split("\n")[0] || `New message from ${item.sender}`}</p>
								<p className={`text-gray-700 whitespace-pre-wrap dark:text-gray-300`}>{item.content.split("\n")[1] || item.content}</p>
							</Link>
						</Menu.Item>
					))}
				</Menu.Items>
			</Transition>
		</Menu>
	)
})
