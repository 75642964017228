import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Expense } from '../utils/types';
import {userStore} from "../state/User";
import {generateUUID} from "../utils/data";
import {NotificationPopupComponent} from "./NotificationPopup";
import {createExpense, updateExpense} from "../api/expense";
import {expensesStore} from "../state/Expenses";
const initialExpense: Expense = {
	id: '',
	user: '',
	title: '',
	description: '',
	currencyCode: 'USD',
	expenseDuration: 'One-Time',
	price: 0,
	date: Date.now(),
	createdAt: Date.now(),
	updatedAt: Date.now(),
};
const ExpenseForm = ({ initialData = initialExpense, onClose }: { onClose: () => void; initialData?: Expense }) => {
	const [expense, setExpense] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setExpense((prevExpense) => ({
			...prevExpense,
			[name]: name === "price" ? parseFloat(value) : value,
		}));
	};
	const handleFormSubmit = async () => {
		setLoading(true);
		const missingList = [];
		if(!expense.price)
			missingList.push("Price")
		if(!expense.title)
			missingList.push("Title")
		if(!expense.description)
			missingList.push("Description")
		if(missingList.length !== 0) {
			setLoading(false);
			return setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Missing required fields`,
				message: `${missingList.join(", ")} fields are required!`
			})
		}
		try {
			expense.user = userStore.getUser()?.id as string
			let update: boolean = false;
			if(expense.id) {
				expense.updatedAt = new Date().getTime()
				update = true;
			} else {
				expense.createdAt = new Date().getTime()
				expense.updatedAt = new Date().getTime()
			}
			const func = update ? updateExpense.bind(this, expense.id) : createExpense
			await func({...expense, id: expense.id || generateUUID()})
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						if (update) {
							expensesStore.updateExpense(res.data)
						} else {
							expensesStore.addExpenses([res.data])
						}
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Expense ${update ? "Updated" : "Created"}`,
							message: `Expense information successfully ${update ? "updated" : "created"}`
						})
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2e3);
					} else {
						setLoading(false);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating user object",
							// @ts-ignore
							message: res.error?.errorMessage | res.message
						})
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating expense object",
						message: err.message
					})
				})
		} catch (error) {
			console.error('Error creating expense:', error);
		} finally {
			setLoading(false);
		}
	};
	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}
	let notificationComponent;
	if(notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => { setShowDialog(false); onClose(); }}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
									{expense.id ? "Update" : "Create"} Expense
								</Dialog.Title>
								<form onSubmit={(e) => {
									e.preventDefault();
									handleFormSubmit().catch((e) => {
										console.log(e);
									});
								}}>
									<div className="mt-4">
										<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Title <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="title"
												name="title"
												type="text"
												autoComplete="title"
												value={expense.title}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="price"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Price <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												type={"number"}
												id="price"
												name="price"
												autoComplete="price"
												value={expense.price}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="description"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Description <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={expense.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="expenseDuration"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Expense Duration
										</label>
										<div className="mt-2">
											<select
												id="expenseDuration"
												name="expenseDuration"
												value={expense.expenseDuration}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="Weekly">Weekly</option>
												<option value="Monthly">Monthly</option>
												<option value="Yearly">Yearly</option>
												<option value="One-Time">One-Time</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="currencyCode"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Currency Code
										</label>
										<div className="mt-2">
											<select
												id="currencyCode"
												name="currencyCode"
												value={expense.currencyCode}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="USD">USD ($)</option>
												<option value="EUR">EUR (€)</option>
												<option value="GBP">GBP (£)</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="date"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Expense Date
										</label>
										<div className="mt-2">
											<input
												id="date"
												name="date"
												type="date"
												autoComplete="purchase-date"
												value={new Date(expense.date).toISOString().split("T")[0]}
												onChange={(e: any) => {
													e.preventDefault();
													setExpense({...expense, date: +new Date(e.target.value)});
												}}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												expense.id ? "Update Expense" : 'Create Expense'
											)}
										</button>
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};
export default ExpenseForm;
