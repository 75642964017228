import {ErrorMessage, Filter, QuantitySizing, QueryOptions} from "app-ts-types/generic";
import {Any} from "app-ts-types/google/protobuf/any";

/** Enum to represent different marketplace platforms */
export interface SMTPConfig {
	/** the id of this SMTP config */
	id: string;
	/** who does this smtp config belong to */
	user: string;
	/** Email address of the user */
	email_address: string;
	/** the username of the IMAP server */
	username: string;
	/** Password for the user's email account */
	password: string;
	/** IMAP server address */
	imap_server_address: string;
	/** IMAP server port (e.g., 993 for SSL) */
	imap_server_port: number;
	/** Whether to use SSL/TLS for IMAP connection */
	use_ssl: boolean;
	/** the level of the message */
	messageLevel: string;
	/** the message itself */
	message: string;
}

export enum MarketplacePlatform {
	EBAY = 0,
	GRAILED = 1,
	MERCARI = 2,
	SHOPIFY = 3,
	STOCKX = 4,
	POSHMARK = 5,
	GOAT = 6,
	AMAZON = 7,
	ETSY = 8,
	DEPOP = 9,
	FACEBOOK = 10,
	// WOOCOMMERCE = 11,
	POIZON = 12,
	WHATNOT = 13,
	UNRECOGNIZED = -1,
}

export function marketplacePlatformFromJSON(object: any): MarketplacePlatform {
	switch (object) {
		case 0:
		case "EBAY":
			return MarketplacePlatform.EBAY;
		case 1:
		case "GRAILED":
			return MarketplacePlatform.GRAILED;
		case 2:
		case "MERCARI":
			return MarketplacePlatform.MERCARI;
		case 3:
		case "SHOPIFY":
			return MarketplacePlatform.SHOPIFY;
		case 4:
		case "STOCKX":
			return MarketplacePlatform.STOCKX;
		case 5:
		case "POSHMARK":
			return MarketplacePlatform.POSHMARK;
		case 6:
		case "GOAT":
			return MarketplacePlatform.GOAT;
		case 7:
		case "AMAZON":
			return MarketplacePlatform.AMAZON;
		case 8:
		case "ETSY":
			return MarketplacePlatform.ETSY;
		case 9:
		case "DEPOP":
			return MarketplacePlatform.DEPOP;
		case 10:
		case "FACEBOOK":
			return MarketplacePlatform.FACEBOOK;
		case 11:
		// case "WOOCOMMERCE":
		// 	return MarketplacePlatform.WOOCOMMERCE;
		case 12:
		case "POIZON":
			return MarketplacePlatform.POIZON;
		case -1:
		case "UNRECOGNIZED":
		default:
			return MarketplacePlatform.UNRECOGNIZED;
	}
}

export function marketplacePlatformToJSON(object: MarketplacePlatform): string {
	switch (object) {
		case MarketplacePlatform.EBAY:
			return "EBAY";
		case MarketplacePlatform.GRAILED:
			return "GRAILED";
		case MarketplacePlatform.MERCARI:
			return "MERCARI";
		case MarketplacePlatform.SHOPIFY:
			return "SHOPIFY";
		case MarketplacePlatform.STOCKX:
			return "STOCKX";
		case MarketplacePlatform.POSHMARK:
			return "POSHMARK";
		case MarketplacePlatform.GOAT:
			return "GOAT";
		case MarketplacePlatform.AMAZON:
			return "AMAZON";
		case MarketplacePlatform.ETSY:
			return "ETSY";
		case MarketplacePlatform.DEPOP:
			return "DEPOP";
		case MarketplacePlatform.FACEBOOK:
			return "FACEBOOK";
		// case MarketplacePlatform.WOOCOMMERCE:
		// 	return "WOOCOMMERCE";
		case MarketplacePlatform.POIZON:
			return "POIZON";
		case MarketplacePlatform.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

export interface Platform {
	id: MarketplacePlatform;
	name: string;
	url: string;
	src: string;
	alt: string;
	homepage: string;
	supportsApi: boolean;
	instructionSet: string;
	apiUrl: string;
	documentationUrl: string;
}

/** Expense object representing an expense record */
export interface Expense {
	/** Unique identifier for the expense */
	id: string;
	/** the user id to whom this expense belongs */
	user: string;
	/** Title of the expense */
	title: string;
	/** Description of the expense */
	description: string;
	/** Currency code (e.g., USD, EUR) */
	currencyCode: string;
	/**
	 * The duration of the expense
	 * could be Weekly, Monthly, Yearly, One-Time
	 */
	expenseDuration: string;
	/** Price of the expense (float) */
	price: number;
	/** Date of the expense (epoch timestamp) */
	date: number;
	/** Timestamp indicating when the expense was created */
	createdAt: number;
	/** Timestamp indicating when the expense was updated */
	updatedAt: number;
}

export interface Collection {
	/** the id of the collection */
	id: string;
	/** the user of this collection */
	user: string;
	/** the name of this collection */
	name: string;
	/** is this collection listed? */
	listed: boolean;
	/** automatically list this collection including any new additions */
	autoList: boolean;
	/** schedule this listing on a specific day */
	scheduleListing: number;
	/** Timestamp indicating when the collection was created */
	createdAt: number;
	/** when the collection was updated */
	updatedAt: number;
}

/** Enum to represent different status */
/** Enum to represent different status */
export enum Statuses {
	UNLISTED = 0,
	PERSONAL = 1,
	IN_TRANSIT = 2,
	DELIVERED = 3,
	CONSIGNED = 4,
	LISTED = 5,
	SHIPPED = 6,
	CANCELLED = 7,
	ORDERED = 8,
	PENDING = 9,
	FAILED = 10,
	UNRECOGNIZED = -1,
}
export function statusesFromJSON(object: any): Statuses {
	switch (object) {
		case 0:
		case "UNLISTED":
			return Statuses.UNLISTED;
		case 1:
		case "PERSONAL":
			return Statuses.PERSONAL;
		case 2:
		case "IN_TRANSIT":
			return Statuses.IN_TRANSIT;
		case 3:
		case "DELIVERED":
			return Statuses.DELIVERED;
		case 4:
		case "CONSIGNED":
			return Statuses.CONSIGNED;
		case 5:
		case "LISTED":
			return Statuses.LISTED;
		case 6:
		case "SHIPPED":
			return Statuses.SHIPPED;
		case 7:
		case "CANCELLED":
			return Statuses.CANCELLED;
		case 8:
		case "ORDERED":
			return Statuses.ORDERED;
		case 9:
		case "PENDING":
			return Statuses.PENDING;
		case 10:
		case "FAILED":
			return Statuses.FAILED;
		case -1:
		case "UNRECOGNIZED":
		default:
			return Statuses.UNRECOGNIZED;
	}
}
export function statusesToJSON(object: Statuses): string {
	switch (object) {
		case Statuses.UNLISTED:
			return "UNLISTED";
		case Statuses.PERSONAL:
			return "PERSONAL";
		case Statuses.IN_TRANSIT:
			return "IN_TRANSIT";
		case Statuses.DELIVERED:
			return "DELIVERED";
		case Statuses.CONSIGNED:
			return "CONSIGNED";
		case Statuses.LISTED:
			return "LISTED";
		case Statuses.SHIPPED:
			return "SHIPPED";
		case Statuses.CANCELLED:
			return "CANCELLED";
		case Statuses.ORDERED:
			return "ORDERED";
		case Statuses.PENDING:
			return "PENDING";
		case Statuses.FAILED:
			return "FAILED";
		case Statuses.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/** Enum to represent different sources for the inventory products */
export enum Sources {
	EMAIL = 0,
	SPREADSHEET = 1,
	ONLINE = 2,
	WEB = 3,
	DESKTOP = 4,
	UNRECOGNIZED = -1,
}
export function sourcesFromJSON(object: any): Sources {
	switch (object) {
		case 0:
		case "EMAIL":
			return Sources.EMAIL;
		case 1:
		case "SPREADSHEET":
			return Sources.SPREADSHEET;
		case 2:
		case "ONLINE":
			return Sources.ONLINE;
		case 3:
		case "WEB":
			return Sources.WEB;
		case 4:
		case "DESKTOP":
			return Sources.DESKTOP;
		case -1:
		case "UNRECOGNIZED":
		default:
			return Sources.UNRECOGNIZED;
	}
}
export function sourcesToJSON(object: Sources): string {
	switch (object) {
		case Sources.EMAIL:
			return "EMAIL";
		case Sources.SPREADSHEET:
			return "SPREADSHEET";
		case Sources.ONLINE:
			return "ONLINE";
		case Sources.WEB:
			return "WEB";
		case Sources.DESKTOP:
			return "DESKTOP";
		case Sources.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/** Enum to represent different categories for the products */
export enum Categories {
	SNEAKERS = 0,
	STREET_WEAR = 1,
	COLLECTIBLES = 2,
	HANDBAGS = 3,
	TOPS = 4,
	WATCHES = 5,
	NFT = 6,
	SHORTS = 7,
	PANTS = 8,
	JEWELRY = 9,
	JEANS = 10,
	BAGS = 11,
	BELT = 12,
	JUMPSUIT = 13,
	BLAZER = 14,
	COAT = 15,
	SKIRT = 16,
	DRESSES = 17,
	SWEATERS = 18,
	ACCESSORIES = 19,
	HOME_ITEMS = 20,
	KITCHENWARE = 21,
	GENERIC = 22,
	UNRECOGNIZED = -1,
}
export function categoriesFromJSON(object: any): Categories {
	switch (object) {
		case 0:
		case "SNEAKERS":
			return Categories.SNEAKERS;
		case 1:
		case "STREET_WEAR":
			return Categories.STREET_WEAR;
		case 2:
		case "COLLECTIBLES":
			return Categories.COLLECTIBLES;
		case 3:
		case "HANDBAGS":
			return Categories.HANDBAGS;
		case 4:
		case "TOPS":
			return Categories.TOPS;
		case 5:
		case "WATCHES":
			return Categories.WATCHES;
		case 6:
		case "NFT":
			return Categories.NFT;
		case 7:
		case "SHORTS":
			return Categories.SHORTS;
		case 8:
		case "PANTS":
			return Categories.PANTS;
		case 9:
		case "JEWELRY":
			return Categories.JEWELRY;
		case 10:
		case "JEANS":
			return Categories.JEANS;
		case 11:
		case "BAGS":
			return Categories.BAGS;
		case 12:
		case "BELT":
			return Categories.BELT;
		case 13:
		case "JUMPSUIT":
			return Categories.JUMPSUIT;
		case 14:
		case "BLAZER":
			return Categories.BLAZER;
		case 15:
		case "COAT":
			return Categories.COAT;
		case 16:
		case "SKIRT":
			return Categories.SKIRT;
		case 17:
		case "DRESSES":
			return Categories.DRESSES;
		case 18:
		case "SWEATERS":
			return Categories.SWEATERS;
		case -1:
		case "UNRECOGNIZED":
		default:
			return Categories.UNRECOGNIZED;
	}
}
export function categoriesToJSON(object: Categories): string {
	switch (object) {
		case Categories.SNEAKERS:
			return "SNEAKERS";
		case Categories.STREET_WEAR:
			return "STREET_WEAR";
		case Categories.COLLECTIBLES:
			return "COLLECTIBLES";
		case Categories.HANDBAGS:
			return "HANDBAGS";
		case Categories.TOPS:
			return "TOPS";
		case Categories.WATCHES:
			return "WATCHES";
		case Categories.NFT:
			return "NFT";
		case Categories.SHORTS:
			return "SHORTS";
		case Categories.PANTS:
			return "PANTS";
		case Categories.JEWELRY:
			return "JEWELRY";
		case Categories.JEANS:
			return "JEANS";
		case Categories.BAGS:
			return "BAGS";
		case Categories.BELT:
			return "BELT";
		case Categories.JUMPSUIT:
			return "JUMPSUIT";
		case Categories.BLAZER:
			return "BLAZER";
		case Categories.COAT:
			return "COAT";
		case Categories.SKIRT:
			return "SKIRT";
		case Categories.DRESSES:
			return "DRESSES";
		case Categories.SWEATERS:
			return "SWEATERS";
		case Categories.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/** Enum to represent different stock levels */
export enum StockLevel {
	LOW = 0,
	NORMAL = 1,
	HIGH = 2,
	UNRECOGNIZED = -1,
}
export function stockLevelFromJSON(object: any): StockLevel {
	switch (object) {
		case 0:
		case "LOW":
			return StockLevel.LOW;
		case 1:
		case "NORMAL":
			return StockLevel.NORMAL;
		case 2:
		case "HIGH":
			return StockLevel.HIGH;
		case -1:
		case "UNRECOGNIZED":
		default:
			return StockLevel.UNRECOGNIZED;
	}
}
export function stockLevelToJSON(object: StockLevel): string {
	switch (object) {
		case StockLevel.LOW:
			return "LOW";
		case StockLevel.NORMAL:
			return "NORMAL";
		case StockLevel.HIGH:
			return "HIGH";
		case StockLevel.UNRECOGNIZED:
		default:
			return "UNRECOGNIZED";
	}
}

/**
 * this is the size and the quantity per size field
 * associated with a specific inventory.
 * each inventory can have a multiple sizings
 */
// export interface QuantitySizing {
// 	/** the size of the product */
// 	size: string;
// 	/** in how many quantities does this size exist */
// 	quantity: number;
// }

export interface Inventory {
	/** Basic Info */
	name: string;
	SKU: string;
	brand: string;
	color: string;
	totalQuantity: number;
	description: string;
	/** the sizes listed in the inventory */
	sizes: QuantitySizing[];
	/** Purchase Details */
	purchaseDetails:
		| PurchaseDetails
		| undefined;
	/** Classification Details */
	category: Categories;
	status: Statuses;
	source: Sources;
	platform: MarketplacePlatform[];
	deleted: boolean;
	createdAt: number;
	updatedAt: number;
	/** create a collection given the name */
	collectionId: string;
	outOfStock: boolean;
	/** the level the stock is at */
	stockLevel: StockLevel;
	/** the id and the user of the inventory object */
	id: string;
	user: string;
	imageUrl: string;
	scheduleListing: number;
	autoList: boolean;
	listingPrice: number;
	soldPrice: number;
	variants: ProductVariant[];
}

export interface PurchaseDetails {
	price: number;
	shipping: string;
	vatPercentage: number;
	purchasePlace: string;
	purchasePlaceNumber: string;
	purchasePlaceWebsite: string;
	purchasePlaceLocation: string;
	/** Consider using a date or timestamp type */
	purchaseDate: string;
	orderNumber: string;
}

export interface ProductVariant {
	/** Basic Info */
	name: string;
	SKU: string;
	brand: string;
	color: string;
	totalQuantity: number;
	description: string;
	sizes: QuantitySizing[];
	purchaseDetails: PurchaseDetails | undefined;
	imageUrl: string;
	listingPrice: number;
	soldPrice: number;
	/** the id and the user of the inventory object */
	id: string;
	user: string;
}

export interface Invoice {
	id: string;
	amount_due: number;
	currency: string;
	status: string;
	period_start: number;
	period_end: number;
	invoice_pdf: string;
}

export interface Listing {
	/** Unique identifier for the listing */
	id: string;
	/** who does this listing belong to */
	user: string;
	/** Title of the product listing */
	title: string;
	/** Description of the product listing */
	description: string;
	/** Price of the product listing */
	price: number;
	/** Currency code (e.g., USD, EUR) */
	currencyCode: string;
	/** Quantity available for sale */
	quantity: number;
	/** URL of the product image */
	imageUrls: string[];
	/** Category of the product listing */
	category: string;
	/** Condition of the product (e.g., new, used) */
	condition: string;
	/** Seller username or ID */
	seller: string;
	/** Location of the item (e.g., city, country) */
	location: string;
	/** Shipping information */
	shipping:
		| Shipping
		| undefined;
	/** where this listing is taking place */
	platform: MarketplacePlatform;
	/** if this listing is scheduled */
	scheduleDateTime: number;
	/** the createdAt for the listings */
	createdAt: number;
	/** the updatedAt for the */
	updatedAt: number;
	/** the status of this listing */
	status: string;
	/** the inventory id */
	inventoryId: string;
	/** the url of the listing on the marketplace platform */
	listingUrl: string;
	/** tags and keywords for the listings */
	tagsAndKeywords: string[];
	/** SKU of the listing item */
	SKU: string;
	/** brand of the item to be listed */
	brand: string;
	/** the color combination of the item */
	color: string;
	/** the sizing of the item to be listed */
	sizes: QuantitySizing[];
	/** Additional information about the listed object */
	listingObject: { [key: string]: string };
	// expiration date of the listing
	expirationDate: number;
}

export interface Shipping {
	/** Shipping cost */
	cost: number;
	/** Estimated delivery time (in days) */
	estimatedDeliveryTime: number;
	/** Shipping method (e.g., "Standard", "Expedited", "Freight", etc.) */
	method: string;
	/** Package weight (in numeric form) */
	packageWeightMajor: number;
	packageWeightMinor: number;
	/** The weight unit, e.g. "lbs", "kg", "oz", "g", etc. */
	packageWeightUnit: string;
	/** Dimensions */
	packageDimensionLength: number;
	packageDimensionWidth: number;
	packageDimensionHeight: number;
	/** The dimension unit, e.g. "in", "cm", "mm" */
	packageDimensionUnit: string;
}

export interface GetListingRequest {
	filter: Filter | undefined;
}

export interface GetListingResponse {
	data: Listing | undefined;
	isError: boolean;
	error: ErrorMessage | undefined;
	message: string;
	statusCode: number;
}

export interface GetListingsRequest {
	filter: Filter | undefined;
	sort: QueryOptions | undefined;
}

export interface GetListingsResponse {
	data: Listing[];
	isError: boolean;
	error: ErrorMessage | undefined;
	message: string;
	statusCode: number;
}

export interface CreateListingRequest {
	data: Listing | undefined;
}

export interface CreateListingResponse {
	data: Listing | undefined;
	isError: boolean;
	error: ErrorMessage | undefined;
	message: string;
	statusCode: number;
}

export interface UpdateListingRequest {
	filter: Filter | undefined;
	data: Listing | undefined;
	partial: boolean;
	updateData: { [key: string]: Any };
}

function createBaseListing(): Listing {
	return {
		id: "",
		user: "",
		title: "",
		description: "",
		price: 0,
		currencyCode: "",
		quantity: 0,
		imageUrls: [],
		category: "",
		condition: "",
		seller: "",
		location: "",
		shipping: undefined,
		platform: 0,
		scheduleDateTime: 0,
		createdAt: 0,
		updatedAt: 0,
		status: "",
		inventoryId: "",
		listingUrl: "",
		tagsAndKeywords: [],
		SKU: "",
		brand: "",
		color: "",
		sizes: [],
		listingObject: {},
		expirationDate: 0,
	};
}

export function createBaseShipping(): Shipping {
	return {
		cost: 0,
		estimatedDeliveryTime: 0,
		method: "",
		packageWeightMajor: 0,
		packageWeightMinor: 0,
		packageWeightUnit: "",
		packageDimensionLength: 0,
		packageDimensionWidth: 0,
		packageDimensionHeight: 0,
		packageDimensionUnit: "",
	};
}

export function createBaseInventory(): Inventory {
	return {
		name: "",
		SKU: "",
		brand: "",
		color: "",
		totalQuantity: 0,
		description: "",
		sizes: [],
		purchaseDetails: undefined,
		category: 0,
		status: 0,
		source: 0,
		platform: [],
		deleted: false,
		createdAt: 0,
		updatedAt: 0,
		collectionId: "",
		outOfStock: false,
		stockLevel: 0,
		id: "",
		user: "",
		scheduleListing: 0,
		autoList: false,
		imageUrl: "",
		listingPrice: 0,
		soldPrice: 0,
		variants: [],
	};
}

export interface Template {
	/** Unique identifier for the Template */
	id: string;
	/** who does this Template belong to */
	user: string;
	/** Title of the product Template */
	title: string;
	/** Description of the product Template */
	description: string;
	/** Category of the product Template */
	category: string;
	/** Condition of the product (e.g., new, used) */
	condition: string;
	/** Location of the item (e.g., city, country) */
	location: string;
	/** Shipping information */
	shipping:
		| Shipping
		| undefined;
	/** the createdAt for the Templates */
	createdAt: number;
	/** the updatedAt for the */
	updatedAt: number;
	/** tags and keywords for the Templates */
	tagsAndKeywords: string[];
	/** SKU of the Template item */
	SKU: string;
	/** brand of the item to be listed */
	brand: string;
	/** the sizing of the item to be listed */
	sizes: QuantitySizing[];
}

// shippingMethod can be an enum:
export enum ShippingMethodType {
	STANDARD_SMALL_MEDIUM = 1,   // "Standard shipping: Small to medium items"
	FREIGHT_OVERSIZED = 2,       // "Freight: Oversized items"
	LOCAL_PICKUP_ONLY = 3        // "Local pickup only: Sell to buyers near you"
}

export function shippingMethodTypeToJson(object: ShippingMethodType): string {
	switch (object) {
		case ShippingMethodType.STANDARD_SMALL_MEDIUM:
			return "Standard";
		case ShippingMethodType.FREIGHT_OVERSIZED:
			return "Freight";
		case ShippingMethodType.LOCAL_PICKUP_ONLY:
			return "Local Pickup";
	}
}

export interface ShippingServiceOption {
	serviceId: string;
	serviceName: string;
	cost: number;          // cost for 1 item, if costType = "Flat"/"Free"
	additionalCost: number;// cost per extra item
	shippingTimeMin: number;
	shippingTimeMax: number;
	expeditedService: boolean;
	trackingIncluded: boolean;
	insuranceIncluded: string;
}

/** Represents a set of shipping rules for a region (domestic or international) */
export interface ShippingPolicyDetail {
	/** e.g. "Flat", "Calculated", or "Free" */
	costType: string;
	/**
	 * "Buyer" or "Seller"
	 * If "Seller", that often implies free to the buyer in eBay's typical scenario
	 */
	shippingPayer: string;
	/** The user can pick "Standard," "Freight," or "Local pickup" from the screenshot */
	shippingMethod: ShippingMethodType;
	/**
	 * If costType = "Flat" (or "Free"), you can have up to 4 (domestic) or 5 (international) shipping services.
	 * If costType = "Calculated", you may still define services but shipping cost is determined at checkout.
	 */
	shippingServices: ShippingServiceOption[];
	/** does the shipping option or the chosen cost type need dimensions */
	needsDimensions: boolean;
	/** If costType = "Flat", define base cost for shipping one unit */
	baseCost: number;
	/** If costType = "Flat", define additional item cost (if user buys more than 1) */
	additionalCost: number;
}

export interface ShippingTemplate {
	id: string;
	user: string;
	label: string;
	city: string;
	state: string;
	zipCode: string;
	country: string;
	handlingTime: number;
	address: string;
	addressOther: string;
	domesticEnabled: boolean;
	domesticPolicy: ShippingPolicyDetail;
	internationalEnabled: false,
	internationalPolicy: ShippingPolicyDetail;
	isDefault: boolean;
	supportedPlatform: number[]; // e.g. platform enum values
	createdAt: number;
	updatedAt: number;
}

export interface Share {
	/** Unique share ID */
	id: string;
	/** Cryptographically generated temporary key */
	shareKey: string;
	createdAt: number;
	/** Optional: define an expiration time for the share */
	expiresAt: number;
	/** should we share all the inventory belong to a user? */
	shareAll: boolean;
	userId: string;
	user: string;
	/** IDs of inventory items being shared; could be empty or a flag to indicate "all" */
	inventoryIds: string[];
}

export interface ChatUserMessage {
	from: string;
	to: string;
	message: string;
	timestamp: string;
	read: boolean;
}

/** ChatMessage object representing a message sent between users on the marketplace platform */
export interface ChatMessage {
	/** Unique identifier for the message */
	id: string;
	/** who does this message belong to */
	user: string;
	/** Sender of the message */
	sender: string;
	/** Receiver of the message */
	receiver: string;
	/** Content of the message */
	content: ChatUserMessage[];
	/** Timestamp indicating when the message was sent */
	timestamp: number;
	/** message is read? */
	read: boolean;
	/** which platform is this message from? */
	platform: MarketplacePlatform;
	needsAction: boolean;
	messageId: string;
	messageLink: string;
	supportsResponse: boolean;
	responseLink: string;
	/** dates */
	createdAt: number;
	updatedAt: number;
}

/** Notification object representing a notification received by a user on the marketplace platform */
export interface Notification {
	/** Unique identifier for the notification */
	id: string;
	/** who does this notification belong to? */
	user: string;
	/** Type of notification (e.g., new message, bid placed, item sold) */
	type: string;
	/** Content or message associated with the notification */
	content: string;
	/** Timestamp indicating when the notification was generated */
	timestamp: number;
	/** Link or reference to the relevant item or action on the platform */
	notificationLink: string;
	/** message is read? */
	read: boolean;
	/** which platform is this message from? */
	platform: MarketplacePlatform;
	createdAt: number;
	updatedAt: number;
}

/** Message object representing a message sent between users on the marketplace platform */
export interface Message {
	/** Unique identifier for the message */
	id: string;
	/** who does this message belong to */
	user: string;
	/** Sender of the message */
	sender: string;
	/** Receiver of the message */
	receiver: string;
	/** Content of the message */
	content: string;
	/** Timestamp indicating when the message was sent */
	timestamp: number;
	/** message is read? */
	read: boolean;
	/** which platform is this message from? */
	platform: MarketplacePlatform;
	responded: boolean;
	responseMessage: string;
	needsAction: boolean;
	messageId: string;
	messageLink: string;
	createdAt: number;
	updatedAt: number;
}

function createBaseTemplate(): Template {
	return {
		id: "",
		user: "",
		title: "",
		description: "",
		category: "",
		condition: "",
		location: "",
		shipping: undefined,
		createdAt: 0,
		updatedAt: 0,
		tagsAndKeywords: [],
		SKU: "",
		brand: "",
		sizes: [],
	};
}

export interface RespondToBestOfferRequest {
	best_offer_id: string;
	action: "Accept" | "Decline" | "CounterOffer";
	counter_offer_price?: number;
	counter_offer_qty?: number;
	seller_message?: string;
}

export interface PostMemberMessageResponseRequest {
	message_id: string;
	item_id: string;
	recipient_id: string;
	body: string;
	email_copy_to_sender?: boolean;
	subject?: string;
	display_to_public?: boolean;
	question_type?: string;
}
