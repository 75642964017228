import { makeAutoObservable } from "mobx";
import { ReturnNotification } from "app-ts-types/returns";
import { MarketplacePlatform } from "app-ts-types/platforms";

// --- Sample Test Data for Returns ---
export const sampleReturns: ReturnNotification[] = [
	{
		id: "return1",
		user: "user1",
		marketplace: 0,
		sku: "SKU-201",
		inventoryId: "INV-201",
		listingId: "LIST-201",
		eventType: "ReturnCreated",
		recipientId: "recipient1",
		creationDate: "2023-02-01T12:00:00Z",
		otherPartyId: "other1",
		otherPartyRole: "Buyer",
		returnStatus: "Open",
		returnGlobalId: "globalReturn1",
	},
	{
		id: "return2",
		user: "user2",
		marketplace: 0,
		sku: "SKU-202",
		inventoryId: "INV-202",
		listingId: "LIST-202",
		eventType: "ReturnDelivered",
		recipientId: "recipient2",
		creationDate: "2023-02-02T12:00:00Z",
		otherPartyId: "other2",
		otherPartyRole: "Seller",
		returnStatus: "Closed",
		returnGlobalId: "globalReturn2",
	},
];

class ReturnsStore {
	returns: ReturnNotification[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "" };
	currentPage: number = 1;
	itemsPerPage: number = 100;
	selectedPlatform: MarketplacePlatform | "" = "";

	constructor() {
		makeAutoObservable(this);
	}

	setReturns(returns: ReturnNotification[]) {
		this.returns = returns;
	}

	addReturns(newItems: ReturnNotification[]) {
		newItems.forEach(newItem => {
			const index = this.returns.findIndex(item => item.id === newItem.id);
			if (index !== -1) {
				this.returns[index] = newItem;
			} else {
				this.returns.push(newItem);
			}
		});
	}

	updateReturn(updatedItem: ReturnNotification) {
		const index = this.returns.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.returns[index] = updatedItem;
		}
	}

	deleteReturn(itemId: string) {
		this.returns = this.returns.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setSelectedPlatform(platform: MarketplacePlatform | "") {
		this.selectedPlatform = platform;
	}

	get filteredAndSortedReturns(): ReturnNotification[] {
		let filteredReturns = this.returns;

		if (this.filter) {
			// Filter by event type or other return notification field as needed
			filteredReturns = filteredReturns.filter(item =>
				item.eventType.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.selectedPlatform !== "") {
			filteredReturns = filteredReturns.filter(item =>
				item.marketplace === this.selectedPlatform
			);
		}

		if (this.sortOption.field) {
			filteredReturns = filteredReturns.sort((a, b) => {
				if (this.sortOption.direction === "ASCENDING") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}
		return filteredReturns;
	}

	get paginatedReturns(): ReturnNotification[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedReturns.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedReturns.length / this.itemsPerPage);
	}
}

export const returnsStore = new ReturnsStore();
