import axiosInstance from './server';
import { Inventory, CreateInventoryRequest, UpdateInventoryRequest, GetInventoryResponse, GetInventoriesResponse } from 'app-ts-types/inventories';
import {initNotification} from "./notifications";
import {listingsStore} from "../state/Listing";

// Get an inventory item by ID
export const getInventoryById = async (id: string): Promise<GetInventoryResponse> => {
	const response = await axiosInstance.get<GetInventoryResponse>(`/inventory/${id}`);
	return response.data;
};

// Get an inventories
export const getInventories = async (skip=0, inventory=500): Promise<GetInventoriesResponse> => {
	const response = await axiosInstance.get<GetInventoriesResponse>(`/inventory?skip=${skip}&limit=${inventory}`);
	return response.data;
};

// Create a new inventory item
export const createInventory = async (data: Inventory): Promise<GetInventoryResponse> => {
	try {
		const response = await axiosInstance.post<GetInventoryResponse>('/inventory', data);
		initNotification(data.id, "Inventory creation", "Inventory successfully created").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error creating inventory", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Update an inventory item by ID
export const updateInventory = async (id: string, data: Inventory): Promise<GetInventoryResponse> => {
	try {
		const response = await axiosInstance.put<GetInventoryResponse>(`/inventory/${id}`, data);
		initNotification(data.id, "Inventory update", "Inventory successfully updated").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error updating inventory", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Delete an inventory item by ID
export const deleteInventory = async (id: string): Promise<void> => {
	try {
		await axiosInstance.delete(`/inventory/${id}`);
		// if the inventory is successfully deleted,
		// let's remove the associated listings by inventory Id
		listingsStore.deleteListingsByInventoryId(id);
		initNotification(id, "Inventory deletion", "Inventory successfully deleted").catch(e => console.log(e));
	} catch (e: any) {
		initNotification(id, "Error deleting inventory", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

export const refreshInventoryStats = async (id: string): Promise<any> => {
	const response = await axiosInstance.get<any>(`/inventory/${id}/refresh/stats`);
	return response.data;
};
