import axiosInstance from './server';
import { ExportInventoryResponse } from 'app-ts-types/import-export';
import {initNotification} from "./notifications";

// Import inventory
export const importInventory = async (data: FormData): Promise<any> => {
	try {
		const response = await axiosInstance.post('/import', data, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		initNotification("Import Inventory", "Inventory import", "Inventory successfully imported").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification("Import Inventory", "Error importing inventory", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Export inventory
export const exportInventory = async (): Promise<ExportInventoryResponse> => {
	try {
		const response = await axiosInstance.get<ExportInventoryResponse>('/export');
		initNotification("Export Inventory", "Inventory export", "Inventory successfully exported").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification("Export Inventory", "Error exporting inventory", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};
