import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import noCategoryImage from "../images/no-categories-found.png";
import EmptyComponent from "../components/Empty";
import {collectionsStore} from "../state/Collection";
import {Collection} from "app-ts-types/collections";
import {Categories, categoriesToJSON, categoriesFromJSON} from "../utils/types";
import ListingImage from "../components/Image";
import {inventoryStore} from "../state/Inventory";
import {Inventory} from "app-ts-types/inventories";
import {getCollections} from "../api/collections";
import Loader from "../components/Loading";
import noInventoryImage from "../images/no-inventory-image.png";
import {observer} from "mobx-react-lite";

// the names of the categories
const categoryNames = Object.keys(Categories).filter(key => isNaN(Number(key)));

// the exported categories component
const CategoriesComponent = observer(({handleCreateInventory, handleCreateCollection, handleCreateListing}: {
	handleCreateInventory: () => void;
	handleCreateCollection: () => void;
	handleCreateListing: () => void;
}) => {
	const navigate = useNavigate();
	const [loadCollections, setLoadCollections] = useState(false);

	useEffect(() => {
		if (collectionsStore.collections.length) return;
		setLoadCollections(true);
		getCollectionObjects();
	}, []);

	const getCollectionObjects = () => {
		getCollections()
			.then((res:any) => {
				if(!res.isError) {
					collectionsStore.setCollections(res.data)
					setLoadCollections(false);
				}
			})
			.catch((err:any) => {
				console.log(err);
				setLoadCollections(false);
			})
	}

	// this click on the category object
	const categoryClick = (category: string) => (e: any) => {
		inventoryStore.setSelectedCategory(categoriesFromJSON(category));
		// Navigate to a different route, e.g., '/home'
		navigate('/inventory');
	}

	// this click on the category object
	const collectionClick = (collectionId: string) => (e: any) => {
		inventoryStore.setSelectedCollection(collectionId);
		// Navigate to a different route, e.g., '/home'
		navigate('/inventory');
	}

	const component = <EmptyComponent
		imageSrc={noCategoryImage}
		headerText={"Collections"}
		descriptionText={"You have no saved collections"}
		actionText={"create new collection"}
		onActionClick={handleCreateCollection}
	/>

	let collectionsComponent;
	if (collectionsStore.collections.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				{ component }
			</div>
		);
	} else {
		collectionsComponent = (
			<ul className="mt-4 space-y-4 max-h-[35rem] overflow-y-scroll">
				{collectionsStore.filteredAndSortedCollections.map((collection: Collection) => (
					<li key={collection.id}
					    onClick={collectionClick(collection.id)}
					    className="border cursor-pointer dark:border-gray-700 overflow-hidden bg-white dark:bg-gray-800 px-4 py-4 shadow sm:rounded-md sm:px-6 flex justify-between">
						<div className={"flex "}>
							<div>
								<h3 className="text-sm font-medium text-gray-900 dark:text-white">{collection.name}</h3>
								<p
									className="text-sm text-gray-500 dark:text-gray-400"> Date: {new Date(collection.updatedAt || collection.createdAt).toDateString()} </p>
							</div>
						</div>
						<div className={"text-left min-w-32"}>
							<div className="text-sm text-right text-gray-900 dark:text-white">
								{inventoryStore.inventory.filter((inventory: Inventory) => inventory.collectionId === collection.id).length} Items
							</div>
							<p className="text-sm text-right text-gray-500 dark:text-gray-400">Total:
								${inventoryStore.inventory.filter((inventory: Inventory) => inventory.collectionId === collection.id).reduce((accumulator, inventory) => accumulator + ((inventory.purchaseDetails ? inventory.purchaseDetails.price : 1) * inventory.totalQuantity), 0).toFixed(2)}
							</p>
						</div>
					</li>
				))}
			</ul>
		)
	}

	if (loadCollections) {
		collectionsComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	return (
		<main>
			<div className="space-y-16">
				<div
					className={"flex md:flex-row flex-col lg:space-x-4 space-y-4 lg:space-y-0 mx-auto w-full justify-between"}>
					{/*<h1 className={"text-3xl font-bold"}>Categories & Collections</h1>*/}
					<h1 className={"text-3xl font-bold"}>Collections</h1>
					<button
						onClick={() => {
							handleCreateCollection();
						}}
						className="mt-6 rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						Create Collection
					</button>
				</div>
				<div
					className={"grid grid-cols-1 lg:grid-cols-5 lg:space-x-4 space-y-4 lg:space-y-0 mx-auto max-w-7xl"}>
					<div className="lg:col-span-3 hidden border bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6 h-full">
							<div className="flex items-center justify-between mb-4">
								<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Categories</h2>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-2 justify-between gap-8 lg:gap-4">
								{categoryNames.map((category) => (
									<div key={category} onClick={categoryClick(category)}
									     className="flex items-center cursor-pointer relative">
										<ListingImage title={`${category.length < 4 ? category + "s" : category}`} font={"source-sans-pro"}
										              imageUrl="" className={"h-[10rem] !w-full cursor-pointer"} bg="1F2937" fg="7B5EFF"/>
										<div
											className={`absolute text-center font-bold font-lato text-gray-200 w-full ${category.length < 6 ? "top-[18%]" : "top-[25%]"}`}> {inventoryStore.inventory.filter((inv: Inventory) => categoriesToJSON(inv.category) === category).length} items
											in inventory
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className={"space-y-4 lg:col-span-5 w-full"}>
						<div
							className="overflow-hidden h-full border-0 bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg min-h-fit">
							<div className="h-full">
								{/*<div className="flex items-center justify-between">*/}
								{/*	<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Collections</h2>*/}
								{/*</div>*/}
								{ collectionsComponent }
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
});

export default CategoriesComponent;
