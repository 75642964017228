import {ShippingMethodType, ShippingTemplate} from "../utils/types";
import React, {useState} from "react";
import {NotificationPopupComponent} from "./NotificationPopup";
import {Dialog, Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/solid";
import ShippingPolicyForm from "./ShippingTemplateComponent";
import {userStore} from "../state/User";
import {updateShippingTemplate, createShippingTemplate} from "../api/template";
import {ShippingTemplate as ShippingTemplateType} from "app-ts-types/templates";
import {generateUUID} from "../utils/data";
import {shippingTemplatesStore} from "../state/ShippingTemplate";

export const initialShippingTemplate: ShippingTemplate = {
	id: "",
	user: "",
	label: "",
	handlingTime: 0,
	city: "",
	state: "",
	zipCode: "",
	country: "US",

	address: "",
	addressOther: "",

	domesticEnabled: true,
	domesticPolicy: {
		shippingMethod: ShippingMethodType.STANDARD_SMALL_MEDIUM,
		shippingPayer: "Buyer",
		costType: "Flat",           // "Flat" or "Calculated" or ""
		needsDimensions: false,
		shippingServices: [],
		baseCost: 0.0,
		additionalCost: 0.0,
	},

	internationalEnabled: false,
	internationalPolicy: {
		shippingMethod: ShippingMethodType.STANDARD_SMALL_MEDIUM,
		shippingPayer: "Buyer",
		costType: "Calculated",
		needsDimensions: false,
		baseCost: 0.0,
		additionalCost: 0.0,
		shippingServices: [],
	},
	"isDefault": false,
	"supportedPlatform": [0],
	"createdAt": 0,
	"updatedAt": 0
};

const ShippingTemplateForm = ({initialData = initialShippingTemplate, onClose,}: {
	initialData?: any,
	onClose: () => void;
}) => {
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [loading, setLoading] = useState(false);
	// We hold a copy of the data in this parent's state:
	const [shippingTemplate, setShippingTemplate] = useState(initialData);
	// let's also set some errors here from within the form
	const [errors, setErrors] = useState<Record<string, string>>({});

	const createOrUpdateShippingFunction = async (template: ShippingTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;

		const func = template.id ? updateShippingTemplate.bind(this, template.id) : createShippingTemplate;

		return func({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime } as ShippingTemplateType)
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (template.id) {
						shippingTemplatesStore.updateShippingTemplate(res.data as ShippingTemplate);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Label Updated`,
							message: `Label information successfully updated`
						});
					} else {
						shippingTemplatesStore.addShippingTemplates([res.data]);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Label Created`,
							message: `Label information successfully created`
						});
					}
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error ${template.id ? 'updating' : 'creating'} label object`,
						message: res.error?.errorMessage || res.message
					});
					console.log(res);
				}
				return res;
			});
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			await createOrUpdateShippingFunction(shippingTemplate)
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2000);
					} else {
						setLoading(false);
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating template object",
						message: err.message
					});
				});
		} catch (error) {
			console.error('Error creating template:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	// This function is triggered by a button or some other event
	// to finalize or save the data.
	const handleSubmit = () => {
		if (Object.keys(errors).length > 0) {

			// 3. Combine them into a single string
			const combinedErrorMessage = Object.values(errors).join("\n ");

			// 4. Show them in a single notification
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error in shipping label",
				message: combinedErrorMessage
			});

			// Stop the flow, do NOT call the API
			return;
		}
		// Insert your call to add or update the policy in your backend, etc.
		// e.g. await myApiClient.savePricingPolicy(PricingTemplate);
		handleFormSubmit().catch((err) => {console.log(err);});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<Transition.Child
						as={"div"}
						className={"relative"}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							className="transform transition-all">
							<div className="grid min-h-full w-1/2 mt-10 mx-auto top-10 rounded-md bg-white dark:bg-gray-800 shadow-sm p-4 px-8">
								<Dialog.Title as="div"
								              className="flex justify-between items-center text-2xl font-medium leading-6 text-gray-900 dark:text-white">
									<h2
										className="text-lg mb-4 font-semibold text-slate-900 dark:text-white">{shippingTemplate.id ? "Update" : "Create"} Shipping
										Template</h2>
									<button
										onClick={() => {
											setShowDialog(false);
											onClose();
										}}
										className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
									>
										<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
									</button>
								</Dialog.Title>
								<ShippingPolicyForm value={initialData} propagateErrors={setErrors} onChange={(updated) => setShippingTemplate(updated)}/>
								{/* We can add a "Submit" button that triggers handleSubmit */}
								<div className="mt-4 flex justify-end">
									<button
										onClick={handleSubmit}
										disabled={loading}
										className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
									>
										{loading ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
											     fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											shippingTemplate.id ? "Update Shipping Template" : "Create Shipping Template"
										)}
									</button>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};

export default ShippingTemplateForm;
