import { makeAutoObservable } from "mobx";
import { BestOffer } from "app-ts-types/best-offers";
import { MarketplacePlatform } from "app-ts-types/platforms";

// --- Sample Test Data for Best Offers ---
export const sampleBestOffers: BestOffer[] = [
	{
		id: "bestoffer1",
		user: "user1",
		marketplace: 0,
		sku: "SKU-101",
		inventoryId: "INV-101",
		listingId: "LIST-101",
		eventType: "BestOffer",
		timestamp: "2023-01-05T12:00:00Z",
		recipientId: "recipient1",
		itemId: "ITEM-101",
		title: "Cool Sneakers",
		status: "Pending",
		offerPrice: 150,
		currency: "USD",
		expirationTime: "2023-01-10T12:00:00Z",
		quantity: 1,
		buyItNowPrice: 200,
		itemCurrency: "USD",
		listingEndTime: "2023-01-15T12:00:00Z",
		location: "New York",
		buyerId: "buyer1",
		buyerFeedbackScore: 90,
	},
	{
		id: "bestoffer2",
		user: "user2",
		marketplace: 0,
		sku: "SKU-102",
		inventoryId: "INV-102",
		listingId: "LIST-102",
		eventType: "BestOffer",
		timestamp: "2023-01-06T12:00:00Z",
		recipientId: "recipient2",
		itemId: "ITEM-102",
		title: "Stylish Sneakers",
		status: "Accepted",
		offerPrice: 160,
		currency: "USD",
		expirationTime: "2023-01-11T12:00:00Z",
		quantity: 2,
		buyItNowPrice: 210,
		itemCurrency: "USD",
		listingEndTime: "2023-01-16T12:00:00Z",
		location: "Los Angeles",
		buyerId: "buyer2",
		buyerFeedbackScore: 110,
	},
];

class BestOffersStore {
	bestOffers: BestOffer[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "ASC" };
	currentPage: number = 1;
	itemsPerPage: number = 100;
	selectedPlatform: MarketplacePlatform | "" = "";

	constructor() {
		makeAutoObservable(this);
	}

	setBestOffers(bestOffers: BestOffer[]) {
		this.bestOffers = bestOffers;
	}

	addBestOffers(newItems: BestOffer[]) {
		newItems.forEach(newItem => {
			const index = this.bestOffers.findIndex(item => item.id === newItem.id);
			if (index !== -1) {
				this.bestOffers[index] = newItem;
			} else {
				this.bestOffers.push(newItem);
			}
		});
	}

	updateBestOffer(updatedItem: BestOffer) {
		const index = this.bestOffers.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.bestOffers[index] = updatedItem;
		}
	}

	deleteBestOffer(itemId: string) {
		this.bestOffers = this.bestOffers.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setSelectedPlatform(platform: MarketplacePlatform | "") {
		this.selectedPlatform = platform;
	}

	get filteredAndSortedBestOffers(): BestOffer[] {
		let filteredBestOffers = this.bestOffers;

		if (this.filter) {
			// Filtering by best offer title (or adjust to other fields as needed)
			filteredBestOffers = filteredBestOffers.filter(item =>
				item.title.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.selectedPlatform !== "") {
			filteredBestOffers = filteredBestOffers.filter(item =>
				item.marketplace === this.selectedPlatform
			);
		}

		if (this.sortOption.field) {
			filteredBestOffers = filteredBestOffers.sort((a, b) => {
				if (this.sortOption.direction === "ASC") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}
		return filteredBestOffers;
	}

	get paginatedBestOffers(): BestOffer[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedBestOffers.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedBestOffers.length / this.itemsPerPage);
	}
}

export const bestOffersStore = new BestOffersStore();
