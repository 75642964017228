import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import noMarketPlaceImage from "../images/no-marketplace-found.png";
import EmptyComponent from "../components/Empty";
import {listingsStore} from "../state/Listing";
import {Listing} from "../utils/types";
import {marketplaceImages} from "../components/ListingImageCloud";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {deleteListing, getListings} from "../api/listings";
import Loader from "../components/Loading";
import {ChevronDownIcon, ChevronUpIcon, CogIcon, EllipsisHorizontalIcon} from "@heroicons/react/20/solid";
import {statuses} from "../utils/data";
import ListingImage from "../components/Image";
import {Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, Transition} from "@headlessui/react";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {observer} from "mobx-react-lite";
import ListingItem from "../components/ListingItem";
import {
	Categories,
	MarketplacePlatform,
	marketplacePlatformToJSON,
	Platform, Statuses, statusesFromJSON, statusesToJSON, StockLevel
} from "../utils/types";
import {userStore} from "../state/User";
import ListingPlatform, {MARKETPLACES} from "../sdk";
import Tooltip from "../components/Tooltip";
import {Link} from "react-router-dom";
import {templatesStore} from "../state/Template";
import {initialListing} from "../components/CreateListing";
import {ListingAPIResponseType} from "../types";
import SaleForm from "../components/CreateSale";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}

export const SortableHeader = ({field, label, sortField, sortOrder, onSort}: any) => {
	return (
		<th
			scope="col"
			className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter cursor-pointer"
			onClick={() => onSort(field)}
		>
			<div className="flex items-center">
				{label}
				{sortField === field && (
					sortOrder === 'asc' ? (
						<ChevronUpIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white"/>
					) : (
						<ChevronDownIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white"/>
					)
				)}
			</div>
		</th>
	);
};

const Marketplaces = observer(({
	                               handleCreateInventory,
	                               handleCreateCollection,
	                               handleCreateListing
                               }: {
	handleCreateInventory: () => void;
	handleCreateCollection: () => void;
	handleCreateListing: (listing?: Listing) => void;
}) => {
	const checkbox = useRef(null);
	const [checked, setChecked] = useState(false);
	const [loadListings, setLoadListings] = useState(false);
	const [indeterminate, setIndeterminate] = useState(false);
	const [editListing, setEditListing] = useState(false);
	const [fetchingNextPage, setFetchingNextPage] = useState(false);
	const [selectedItems, setSelectedItems] = useState<Listing[]>([]);
	const [deletingItems, setDeletingItems] = useState<Listing[]>([]);
	const [currentListing, setCurrentListing] = useState<Listing | null>(null);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [saleCreation, setSaleCreation] = useState<boolean>(false);
	const [saleCreationComponent, setSaleCreationComponent] = useState<any | null>(null);

	useEffect(() => {
		if (listingsStore.listings.length) return;
		setLoadListings(true);
		getListingObjects();
	}, []);

	useLayoutEffect(() => {
		const isIndeterminate = selectedItems.length > 0 && selectedItems.length < listingsStore.filteredAndSortedListings.length;
		setChecked(selectedItems.length === listingsStore.filteredAndSortedListings.length);
		setIndeterminate(isIndeterminate);
		if (checkbox.current) {
			// @ts-ignore
			checkbox.current.indeterminate = isIndeterminate;
		}
	}, [selectedItems, listingsStore.filteredAndSortedListings.length]);

	function toggleAll() {
		setSelectedItems(checked || indeterminate ? [] : listingsStore.filteredAndSortedListings);
		setChecked(!checked && !indeterminate);
		setIndeterminate(false);
	}

	const getListingObjects = () => {
		getListings()
			.then((res: any) => {
				if (!res.isError) {
					listingsStore.setListings(res.data);
					setLoadListings(false);
				}
			})
			.catch((err: any) => {
				console.log(err);
				setLoadListings(false);
			})
	}

	const deleteListingRequest = async (listing: Listing) => {
		return new ListingPlatform().deleteListing(listing).then((res: ListingAPIResponseType) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: res.isError,
				title: `Listing Removed`,
				message: res.message,
			});
			listingsStore.deleteListing(listing.id)
		}).catch((err: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Listing Delete error`,
				message: err.message
			})
		});
	}

	const listingItemClick = (item:Listing) => {
		setCurrentListing(item);
	};

	const deListOrRelist = async (listing:Listing) => {
		if(listing.status === "DELISTED") {
			return new ListingPlatform().reListListing(listing);
		}
		return new ListingPlatform().deListListing(listing);
	}

	const DeListOrRelistlistingItemClick = async (item:Listing) => {
		setDeletingItems([...deletingItems, item]);
		await deListOrRelist(item).then((res: ListingAPIResponseType) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: res.isError,
				title: `Listing Removed`,
				message: res.message
			});
			listingsStore.updateListing({...item, status: item.status === "DELISTED" ? "LISTED" : "DELISTED"})
		})
			.catch((err: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Listing Delete error`,
				message: err.message
			})
		})
			.finally(() => {
				setDeletingItems(deletingItems.filter((dit) => dit !== item));
			})
	}

	const markAsSold = async(item:Listing) => {
		setSaleCreationComponent(<SaleForm onClose={() => {setSaleCreation(false); setCurrentListing(null);}} inventory={null} listing={item} />);
		setSaleCreation(true);
	}

	const listingItemClickPopupOnClose = () => {
		setEditListing(false);
		setCurrentListing(null);
	}

	const editClick = (listing: Listing) => {
		setEditListing(true);
		setCurrentListing(listing);
	}

	const deleteClick = async (listing: Listing) => {
		setDeletingItems([...deletingItems, listing]);
		await deleteListingRequest(listing)
		setDeletingItems(deletingItems.filter((dit) => dit !== listing));
		setCurrentListing(null);
	}

	const handleSort = (field: string) => {
		if (listingsStore.sortOption.field === field) {
			listingsStore.setSortOption({
				...listingsStore.sortOption,
				direction: listingsStore.sortOption.direction === 'asc' ? 'desc' : 'asc'
			});
		} else {
			listingsStore.setSortOption({field, direction: "asc"});
		}
	};

	const handlePrevPage = () => {
		if (listingsStore.currentPage > 1) {
			listingsStore.setCurrentPage(listingsStore.currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (listingsStore.currentPage < listingsStore.totalPages) {
			listingsStore.setCurrentPage(listingsStore.currentPage + 1);
			return;
		}
		setFetchingNextPage(true);
		getListings(listingsStore.listings.length)
			.then((res: any) => {
				if (!res.isError && res.data.length) {
					listingsStore.loadNewListings(res.data);
					listingsStore.setCurrentPage(listingsStore.currentPage + 1);
				}
				setFetchingNextPage(false);
			})
			.catch((err: any) => {
				console.log(err);
				setFetchingNextPage(false);
			})
			.finally(() => {
				setFetchingNextPage(false);
			})
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	const deleteSelectedItems = async (e: any) => {
		e.preventDefault();
		try {
			setDeletingItems(selectedItems)
			// Wait for all delete operations to complete
			await Promise.all(selectedItems.map((lst) => deleteListing(lst.id)));
			// Update the inventory store and clear selected items
			listingsStore.setListings(listingsStore.filteredAndSortedListings.filter((item: Listing) => !selectedItems.includes(item)));
			setSelectedItems([]);
		} catch (error) {
			console.error("Error deleting items:", error);
			// Handle error if needed
		}
	};

	// Example helper to decide whether user is connected:
	const isConnected = (platform: MarketplacePlatform) => {
		const details = userStore.user!.platformDetails?.[marketplacePlatformToJSON(platform)];
		if (!details) return false;

		// If there's an expiry property, check if it's in the future:
		// if (details.expiry && details.expiry < Date.now()) {
		// 	return false;
		// }
		// You may also check for accessToken presence or other credentials here
		return true;
	};

	const handleConnect = (marketplace: Platform) => {
		// If the platform has an API, redirect. Otherwise, open a new page or
		// perform any extension-based logic. You could also do it all in the
		// onClick if you prefer.
		if (marketplace.supportsApi && marketplace.apiUrl) {
			window.location.href = `${marketplace.apiUrl}?state=${userStore.user!.id}`;
		} else {
			// For non-API integrations (e.g., Grailed) you might:
			// 1) Check extension presence
			// 2) Open relevant site, etc.
			alert(`Attempting to connect to ${marketplace.name} via browser extension...`);
		}
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	const component = <EmptyComponent
		imageSrc={noMarketPlaceImage}
		headerText={"Listings"}
		descriptionText={"You have not listed any products"}
		actionText={"Create New Listing"}
		onActionClick={handleCreateListing}
	/>

	let listingsComponent;

	if (listingsStore.listings.length === 0) {
		listingsComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				{component}
			</div>
		);
	} else {
		listingsComponent = (
			<div className="bg-white dark:bg-gray-900 sm:rounded-lg">
				<table className="w-full whitespace-nowrap dark:border dark:border-gray-700 border rounded-lg text-left">
					<thead
						className="border-b border-gray-200 dark:border-gray-700 rounded-lg text-sm font-semibold text-gray-900 dark:text-white">
					<tr>
						<th scope="col" className="relative px-7 sm:w-12 sm:px-6">
							<input
								type="checkbox"
								className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
								ref={checkbox}
								checked={checked}
								onChange={toggleAll}
							/>
						</th>
						<th className="py-2 px-4">Image</th>
						<SortableHeader
							field="platform"
							label="Marketplace"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						{/*<th className="py-2 px-4">Marketplace</th>*/}
						<SortableHeader
							field="title"
							label="Title & Description"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						<SortableHeader
							field="price"
							label="Price"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						<th>Sizes</th>
						<th className="py-2 px-4">Quantity</th>
						<SortableHeader
							field="status"
							label="Status"
							sortField={listingsStore.sortOption.field}
							sortOrder={listingsStore.sortOption.direction}
							onSort={handleSort}
						/>
						<th className="py-2 px-4 text-right">Actions</th>
					</tr>
					</thead>
					<tbody className="divide-y divide-gray-200 dark:divide-gray-700 font-lato-regular ">
					{listingsStore.paginatedListings.map((listing, index) => (
						<tr key={`${listing.id}-${listing.imageUrls.length}`} className="table-row">
							<td className="relative px-7 sm:w-12 sm:px-6">
								{/*// @ts-ignore*/}
								{selectedItems.includes(listing) && (
									<div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"/>
								)}
								{/*// @ts-ignore*/}
								<input checked={selectedItems.includes(listing)}
								       type="checkbox"
								       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
								       value={listing.id}
								       onChange={(e) =>
									       // @ts-ignore*
									       setSelectedItems(e.target.checked ? [...selectedItems, listing] : selectedItems.filter((p) => p !== listing))
								       }
								/>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4">
								<ListingImage title={listing.SKU} imageUrl={listing.imageUrls[0]}/>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4">
								<div className={"flex items-center gap-2"}>
									<img src={marketplaceImages[listing.platform]?.src} alt={marketplaceImages[listing.platform]?.alt}
									     className="h-6 w-6"/>
									<span className="text-gray-800 dark:text-gray-200">{marketplaceImages[listing.platform]?.alt}</span>
								</div>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4 max-w-[17rem] lg:max-w-[10rem]">
								<div className="text-gray-900 dark:text-white font-medium whitespace-break-spaces">{listing.title}</div>
								<p className="text-gray-500 dark:text-gray-400 line-clamp-3 whitespace-break-spaces">{listing.description}</p>
							</td>
							<td onClick={() => {
								listingItemClick(listing)
							}} className="py-4 cursor-pointer px-4">
								{listing.currencyCode  === 'USD' ? '$' : listing.currencyCode  === 'EUR' ? '€' : listing.currencyCode  === 'GBP' ? '£' : '$'}{listing.price.toFixed(2)}
							</td>
							<td>
								<ul className="flex flex-wrap gap-2">
									{listing.sizes.map((size, index) => (
										<li key={index} className="bg-brightPurple text-white px-2 py-1 rounded">
											{size.size || "One-Size"}
										</li>
									))}
								</ul>
							</td>
							<td className="py-4 px-4">
								{listing.quantity}
							</td>
							<td className="py-4 px-4">
                <span className={classNames(statuses[listing.status.toUpperCase()], 'rounded px-2 py-1 text-xs font-medium')}>
                  {listing.status}
                </span>
							</td>
							<td className="py-4 px-4 text-right">
							<Menu as="div" className="relative inline-block text-left">
									<div>
										{deletingItems.includes(listing) ? (
											<svg className="animate-spin h-5 w-5 text-deepPurple" xmlns="http://www.w3.org/2000/svg"
											     fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											<Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
												<span className="sr-only">Open options</span>
												<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
											</Menu.Button>
										)}
									</div>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-lg dark:border dark:border-gray-700 bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															listingItemClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														View
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															editClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														Edit
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															DeListOrRelistlistingItemClick(listing).catch((err) => {console.log(err);})
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														{listing.status === "DELISTED" ? "Re-List" : "De-List"}
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															markAsSold(listing).catch((err) => {console.log(err);})
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														Mark as Sold
													</button>
												)}
											</Menu.Item>
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															deleteClick(listing)
														}}
														className={classNames(
															active ? 'bg-gray-100 dark:bg-gray-700' : '',
															'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
														)}
													>
														Delete
													</button>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</td>
						</tr>
					))}
					</tbody>
				</table>
				<div className="flex items-center justify-center space-x-4 mt-4">
					<button
						onClick={handlePrevPage}
						disabled={listingsStore.currentPage === 1}
						className={`p-2 cursor-pointer rounded-full ${listingsStore.currentPage === 1 ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
					>
						<ChevronLeftIcon className="h-6 w-6"/>
					</button>
					<span className="text-gray-900 dark:text-gray-100">
			        Page {listingsStore.currentPage} of {listingsStore.totalPages}
			      </span>
					<button
						onClick={handleNextPage}
						// disabled={listingsStore.currentPage === listingsStore.totalPages}
						className={`p-2 cursor-pointer rounded-full ${listingsStore.currentPage === listingsStore.totalPages ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
					>
						{
							fetchingNextPage ? (
								<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
								     fill="none" viewBox="0 0 24 24">
									<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
									        strokeWidth="4"></circle>
									<path className="opacity-75" fill="currentColor"
									      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
								</svg>
							) : (
								<ChevronRightIcon className="h-6 w-6"/>
							)
						}
					</button>
				</div>
			</div>
		)
	}

	if (loadListings) {
		listingsComponent = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	return (
		<main>
			{notificationComponent}
			{/*@ts-ignore*/}
			{currentListing && <ListingItem markAsSold={markAsSold} edit={editListing} del={deletingItems.includes(currentListing)} listing={currentListing}
				             onClose={listingItemClickPopupOnClose} deleteClick={deleteClick} deListRelistClick={DeListOrRelistlistingItemClick}
				             editClick={editClick} dels={deletingItems.includes(currentListing)}/>}
			{ saleCreation && saleCreationComponent }
			<div className="space-y-8">
				<div
					className={"flex md:flex-row flex-col lg:space-x-4 space-y-4 lg:space-y-0 mx-auto w-full justify-between"}>
					<h1 className={"text-3xl font-bold"}>Listings & Marketplaces</h1>
					{/*<button*/}
					{/*	onClick={() => {*/}
					{/*		handleCreateListing();*/}
					{/*	}}*/}
					{/*	className="mt-6 rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"*/}
					{/*>*/}
					{/*	Create New Listing*/}
					{/*</button>*/}
					<Listbox>
						<Label className="sr-only">Create New Listing</Label>
						<div className="relative">
							<div className="inline-flex divide-x divide-indigo-700 rounded-md outline-none">
								<button
									onClick={() => {
										handleCreateListing();
									}}
									className="rounded-l-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
								>
									Create New Listing
								</button>
								<Tooltip className={"w-24 bottom-8 -left-8"} text={"Select Template"}>
									<ListboxButton
										className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-500 p-2 outline-none hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-400">
										<span className="sr-only">Select Template</span>
										<ChevronDownIcon aria-hidden="true" className="size-5 text-white forced-colors:text-[Highlight]"/>
									</ListboxButton>
								</Tooltip>
							</div>

							<ListboxOptions
								transition
								className="absolute right-0 z-[1000] mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white dark:bg-gray-800 dark:text-white shadow-lg ring-1 ring-black/5 focus:outline-none border dark:border-gray-600 max-h-[30rem] overflow-y-auto"
							>
								<ListboxOption
									key="manage-templates"
									value="Manage Templates"
									className="group cursor-default select-none p-4 text-sm text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
								>
									<Link to="/templates">
										<div className="flex flex-col">
											<p className="font-normal flex space-x-4 dark:text-white group-data-[selected]:font-semibold">
												<CogIcon aria-hidden="true" className="size-5"/>
												<span>Manage Templates</span>
											</p>
										</div>
									</Link>
								</ListboxOption>
								{templatesStore.templates.map((template, index) => (
									<ListboxOption
										key={template.id + index}
										value={template}
										onClick={() => {
											// @ts-ignore
											handleCreateListing({...initialListing, ...template, name: template.title, id: "", createdAt: Date.now(), updatedAt: Date.now()})
										}}
										className="group cursor-pointer select-none p-4 text-sm text-gray-900 dark:text-white data-[focus]:bg-indigo-600 data-[focus]:text-white"
									>
										<div className="flex flex-col">
											<div className="flex justify-between">
												<p className="font-normal group-data-[selected]:font-semibold">{template.title}</p>
												<span
													className="text-indigo-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
                            {/*<CheckIcon aria-hidden="true" className="size-5"/>*/}
                          </span>
											</div>
											<p
												className="mt-2 text-gray-500 dark:text-gray-300 group-data-[focus]:text-indigo-200">{template.description}</p>
										</div>
									</ListboxOption>
								))}
							</ListboxOptions>
						</div>
					</Listbox>
				</div>
				<div className={"relative flex md:flex-row flex-col lg:space-x-4 space-y-4 lg:space-y-0 mx-auto w-full justify-between"}>
					<div className="flex items-center space-x-4">
						{selectedItems.length > 0 && (
							<>
								<div
									className="left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
											<span
												className="text-sm font-medium text-gray-900 dark:text-white">{selectedItems.length} selected</span>
								</div>
								<div
									className="left-14 top-0 z-[20] flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
									<button
										type="button"
										className="inline-flex items-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
									>
										Bulk Delist
									</button>
									{/*@ts-ignore*/}
									<button onClick={deleteSelectedItems}
									        disabled={Boolean(deletingItems.length)}
									        type="button"
									        className="inline-flex items-center !text-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
									>
										{Boolean(deletingItems.length) ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											"Delete all"
										)
										}
									</button>
								</div>
							</>
						)}
					</div>
					<div className="flex items-center justify-end space-x-8 mb-4">
						<div className="flex items-center space-x-4">
							{/*@ts-ignore*/}
							<select onChange={(e) => {
								listingsStore.setSelectedStatus(statusesToJSON(parseInt(e.target.value)))
							}}
							        value={statusesFromJSON(listingsStore.selectedStatus)}
							        className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							>
								{/*<option value="-1">Status: All</option>*/}
								{Object.keys(Statuses).filter(key => isNaN(Number(key))).map((key) => (
									<option key={key} value={Statuses[key as keyof typeof Statuses]}>
										{key === "UNRECOGNIZED" ? "Status: All" : key}
									</option>
								))}
							</select>
							{/*@ts-ignore*/}
							<select onChange={(e) => {
								listingsStore.setSelectedPlatform(e.target.value)
							}}
							        value={listingsStore.selectedPlatform}
							        className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
							>
								<option value="">Platform: All</option>
								{Object.keys(MarketplacePlatform).filter(key => isNaN(Number(key))).map((key) => (
									<option key={key} value={MarketplacePlatform[key as keyof typeof MarketplacePlatform]}>
										{key}
									</option>
								))}
							</select>
						</div>
						<button
							type="button"
							onClick={() => {
								listingsStore.setSelectedStatus('');
								listingsStore.setSelectedPlatform('');
								listingsStore.setSelectedCategory('');
							}}
							className="block rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						>
							Clear
						</button>
					</div>
				</div>
				<div
					className={"grid grid-cols-1 lg:grid-cols-6 items-start lg:space-x-4 space-y-4 lg:space-y-0 mx-auto max-w-7xl"}>
					<div className={"space-y-4 self-start overflow-x-auto lg:col-span-5"}>
						{listingsComponent}
					</div>
					<div
						className="overflow-hidden border bg-white dark:bg-gray-800/10 dark:border-gray-700 sm:rounded-lg">
						<div className="px-4 py-5 sm:p-6 h-full">
							<div className="flex items-center justify-between">
								<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Marketplaces</h2>
							</div>
							<ul className="mt-4 space-y-4">
								{MARKETPLACES.map((marketplace) => {
									const connected = isConnected(marketplace.id);
									return <Tooltip className={"w-full"} text={connected ? "Connected" : "Connect Marketplace"}>
										<li key={marketplace.id}
										    onClick={() => handleConnect(marketplace)}
										    className={`border cursor-pointer dark:border-gray-700 hover:bg-blue-500 dark:hover:bg-blue-500 ${connected ? "bg-green-500" : "bg-gray-200 dark:bg-gray-800"} overflow-hidden px-4 py-4 shadow sm:rounded-md sm:px-2 flex justify-between`}>
											<div className="flex items-center space-x-2">
												<img src={marketplace.src} alt={marketplace.name} className="h-8 w-8 rounded-full"/>
												<span className="text-gray-900 dark:text-gray-100">{marketplace.name}</span>
											</div>
										</li>
									</Tooltip>
								})}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
});

export default Marketplaces;
