import React, {useEffect, useRef, useState} from 'react';
import {Dialog, Menu, Switch, Transition} from '@headlessui/react';
import {Categories, categoriesFromJSON, categoriesToJSON, Template, MarketplacePlatform, Listing} from '../utils/types';
import {NotificationPopupComponent} from "./NotificationPopup";
import {userStore} from "../state/User";
import {defaultSizes, generateUUID, productSizes, defaultSizesEu} from "../utils/data";
import {createTemplate, updateTemplate} from "../api/template";
import {templatesStore} from "../state/Template";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {PlusCircleIcon, XCircleIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {calculateTotalQuantity, determineStockLevel, initialInventory as initialInventoryData} from "./CreateInventory";

const initialTemplate: Template = {
	"id": '',
	"user": '',
	"title": '',
	"description": '',
	"category": '',
	"condition": 'new',
	"location": 'N/A',
	"shipping": undefined,
	"createdAt": Date.now(),
	"updatedAt": Date.now(),
	"tagsAndKeywords": [],
	"SKU": "",
	"brand": "",
	"sizes": [],
};

const TemplateForm = ({ initialData=initialTemplate, onClose, }: { initialData?: Template, onClose: () => void; }) => {
	// if there's no size information available, then let's add one for default size selection
	if (!initialData.sizes || !initialData.sizes.length) {}
	const [template, setTemplate] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [openMenu, setOpenMenu] = useState(false);

	const menuPopupRef = useRef<HTMLDivElement | null>(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let { name, value } = e.target;
		if(name === "tagsAndKeywords") {
			// @ts-ignore
			value = value.toString().split(", ")
		}
		setTemplate((prevTemplate) => ({
			...prevTemplate,
			[name]: value,
		}));
	};

	const handleMenuClose = (event: any) => {
		if (menuPopupRef.current && !menuPopupRef.current.contains(event.target as Node)) {
			setOpenMenu(false);
			setOpenCategory("");
		}
	}

	const createOrUpdateListingFunction = async (template: Template): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;

		const func = template.id ? updateTemplate.bind(this, template.id) : createTemplate;

		return func({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					if (template.id) {
						templatesStore.updateTemplate(res.data as Listing);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Template Updated`,
							message: `Template information successfully updated`
						});
					} else {
						templatesStore.addTemplates([res.data]);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Template Created`,
							message: `Template information successfully created`
						});
					}
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error ${template.id ? 'updating' : 'creating'} template object`,
						message: res.error?.errorMessage || res.message
					});
					console.log(res);
				}
				return res;
			});
	};

	const handleFormSubmit = async () => {
		setLoading(true);

		try {
			await createOrUpdateListingFunction(template)
				.then((res: { [key: string]: any }) => {
					if (!res.isError) {
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: false,
							title: `Template Created`,
							message: `Template information successfully created`
						});
						setTimeout(() => {
							setShowDialog(false);
							onClose();
						}, 2000);
					} else {
						setLoading(false);
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating template object",
							message: res.error?.errorMessage || res.message
						});
					}
				})
				.catch((err: any) => {
					console.log(err);
					setLoading(false);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating/creating template object",
						message: err.message
					});
				});
		} catch (error) {
			console.error('Error creating template:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...template.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setTemplate((prevInventory: Template) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setTemplate((prevInventory: Template) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = template.sizes.filter((_: any, i: any) => i !== index);
		setTemplate((prevInventory: Template) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
	};

	const handleShippingChange = (e: React.ChangeEvent<any>) => {
		const { name, value } = e.target;
		// @ts-ignore
		setTemplate((prevTemplate) => ({
			...prevTemplate,
			shipping: {
				...prevTemplate.shipping,
				[name]: value,
			},
		}));
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>
				<div className="fixed inset-0 overflow-y-auto">
					<Transition.Child
						as={"div"}
						className={"relative"}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Dialog.Panel
							onClick={handleMenuClose}
							className="transform transition-all">
							<div className="grid min-h-full w-fit mx-auto items-center justify-center p-4 text-center">
								<form
									className={"w-full max-w-2xl overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl"}
									onSubmit={(e) => {
										e.preventDefault();
										handleFormSubmit().catch((err) => {
											console.log(err);
										});
									}}>
									<Dialog.Title as="div"
									              className="flex justify-between items-center text-2xl font-medium leading-6 text-gray-900 dark:text-white">
										<span>{template.id ? "Update" : "Create"} Template</span>
										<button
											onClick={() => {
												setShowDialog(false);
												onClose();
											}}
											className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
										>
											<XMarkIcon className="h-6 w-6" aria-hidden="true"/>
										</button>
									</Dialog.Title>
									<div className="mt-4">
										<label htmlFor="title"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Title <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="title"
												name="title"
												type="text"
												autoComplete="title"
												value={template.title}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="description"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Description <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={template.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="SKU"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											SKU <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="SKU"
												name="SKU"
												type="text"
												autoComplete="sku"
												value={template.SKU}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="brand"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Brand <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="brand"
												name="brand"
												type="text"
												autoComplete="brand"
												value={template.brand}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="location"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Location <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<input
												id="location"
												name="location"
												type="text"
												autoComplete="location"
												value={template.location}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="category"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Category <span className={"text-red-500 font-bold"}>*</span>
										</label>
										<div className="mt-2">
											<select
												id="category"
												name="category"
												value={template.category}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="">Select a category</option>
												{Object.keys(Categories).filter(key => isNaN(Number(key))).map((key) => (
													<option key={key} value={key}>
														{key.replaceAll("_", " ")}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="condition"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Condition
										</label>
										<div className="mt-2">
											<select
												id="condition"
												name="condition"
												value={template.condition}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="new">New</option>
												<option value="slightly used">Slightly Used</option>
												<option value="used">Used</option>
											</select>
										</div>
									</div>
									<div className="mt-3 relative">
										<div className="flex w-24 items-center relative justify-between">
											<label htmlFor="sizes"
											       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Sizes
											</label>
											<Switch
												checked={sizeType === 'EU'}
												onChange={handleToggleSizeType}
												className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
											>
												<span className="sr-only">Toggle Size Type</span>
												<span
													className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
											</Switch>
										</div>
										{/*{*/}
										{/*	openMenu && <Transition*/}
										{/*		as={"div"}*/}
										{/*		enter="transition ease-out duration-100"*/}
										{/*		enterFrom="transform opacity-0 scale-95"*/}
										{/*		enterTo="transform opacity-100 scale-100"*/}
										{/*		leave="transition ease-in duration-75"*/}
										{/*		leaveFrom="transform opacity-100 scale-100"*/}
										{/*		leaveTo="transform opacity-0 scale-95"*/}
										{/*	>*/}
										{/*		<div*/}
										{/*			className="absolute left-0 bottom-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
										{/*			<div className="py-1 max-h-60 overflow-y-auto">*/}
										{/*				{Object.keys(productSizes).map((category) => (*/}
										{/*					<div key={category}>*/}
										{/*						<div>*/}
										{/*							<div*/}
										{/*								onClick={(e: any) => {*/}
										{/*									e.preventDefault();*/}
										{/*									handleCategoryClick(category)*/}
										{/*								}}*/}
										{/*								className={`inline-flex w-full px-4 py-2 text-left cursor-pointer text-sm font-semibold text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}*/}
										{/*							>*/}
										{/*								{category.charAt(0).toUpperCase() + category.slice(1)}*/}
										{/*								<ChevronRightIcon aria-hidden="true"*/}
										{/*								                  className="-mr-1 h-5 w-5 text-gray-400"/>*/}
										{/*							</div>*/}
										{/*						</div>*/}
										{/*					</div>*/}
										{/*				))}*/}
										{/*			</div>*/}
										{/*		</div>*/}
										{/*	</Transition>*/}
										{/*}*/}
										{/*{*/}
										{/*	openCategory &&*/}
										{/*	<Transition*/}
										{/*		as={"div"}*/}
										{/*		enter="transition ease-out duration-100"*/}
										{/*		enterFrom="transform opacity-0 scale-95"*/}
										{/*		enterTo="transform opacity-100 scale-100"*/}
										{/*		leave="transition ease-in duration-75"*/}
										{/*		leaveFrom="transform opacity-100 scale-100"*/}
										{/*		leaveTo="transform opacity-0 scale-95"*/}
										{/*	>*/}
										{/*		<div*/}
										{/*			className="absolute left-[225px] -bottom-2.5 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">*/}
										{/*			<div className="py-1">*/}
										{/*				{productSizes[openCategory][sizeType.toLowerCase()].map((sizeOption) => (*/}
										{/*					<div key={sizeOption}>*/}
										{/*						<button*/}
										{/*							type="button"*/}
										{/*							onClick={(e) => {*/}
										{/*								e.preventDefault();*/}
										{/*								handleSizeChange(currentSizeIndex, 'size', sizeOption)*/}
										{/*							}}*/}
										{/*							className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}*/}
										{/*						>*/}
										{/*							{sizeOption}*/}
										{/*						</button>*/}
										{/*					</div>*/}
										{/*				))}*/}
										{/*			</div>*/}
										{/*		</div>*/}
										{/*	</Transition>*/}
										{/*}*/}
										{
											openMenu &&
											<Transition
												as={"div"}
												enter="transition ease-out duration-100"
												enterFrom="transform opacity-0 scale-95"
												enterTo="transform opacity-100 scale-100"
												leave="transition ease-in duration-75"
												leaveFrom="transform opacity-100 scale-100"
												leaveTo="transform opacity-0 scale-95"
											>
												<div
													ref={menuPopupRef}
													className="absolute left-0 bottom-10 border dark:border-gray-700 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
													<div className="py-1">
														{(sizeType === "US" ? defaultSizes : defaultSizesEu).map((sizeOption) => (
															<div key={sizeOption}>
																<button
																	type="button"
																	onClick={(e) => {
																		e.preventDefault();
																		handleSizeChange(currentSizeIndex, 'size', sizeOption)
																	}}
																	className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
																>
																	{sizeOption}
																</button>
															</div>
														))}
													</div>
												</div>
											</Transition>
										}
										{template.sizes.map((size, index) => (
											<div key={index} className="flex items-center mt-1">
												<div className="relative inline-block text-left w-1/2 mr-2">
													<div>
														<div
															onClick={() => {
																setCurrentSizeIndex(index)
																setOpenMenu(!openMenu)
															}}
															className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
															{size.size || 'Select Size'}
															<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
														</div>
													</div>
												</div>
												<input
													type="number"
													name={`quantity-${index}`}
													value={size.quantity}
													onChange={(e) => {
														e.preventDefault();
														handleSizeChange(index, 'quantity', parseInt(e.target.value))
													}}
													className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
													placeholder="Quantity"
												/>
												<button
													type="button"
													onClick={() => handleRemoveSize(index)}
													className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
												>
													<XCircleIcon className="h-6 w-6"/>
												</button>
												<button
													type="button"
													onClick={handleAddSize}
													className="text-blue-500 hover:text-blue-700 ml-2"
												>
													<PlusCircleIcon className="h-6 w-6"/>
												</button>
											</div>
										))}
									</div>
									<div className="mt-4">
										<label htmlFor="tagsAndKeywords"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Tags And Keywords
										</label>
										<div className="mt-2">
											<input
												id="tagsAndKeywords"
												name="tagsAndKeywords"
												type="text"
												autoComplete="tags-and-keywords"
												value={template.tagsAndKeywords.join(", ")}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div>
										<h1 className="block text-lg pt-4 font-medium leading-6 text-gray-900 dark:text-white">
											Shipping Information
										</h1>

										{/* First row: cost & estimated delivery */}
										<div className="flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col">
											<div className="md:w-1/2 w-full">
												<label
													htmlFor="shipping-cost"
													className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
												>
													Shipping Cost
												</label>
												<div className="mt-2">
													<input
														id="shipping-cost"
														name="cost"
														type="number"
														value={template.shipping?.cost}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
											<div className="md:w-1/2 w-full">
												<label
													htmlFor="estimated-delivery-time"
													className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
												>
													Estimated Delivery Time (days)
												</label>
												<div className="mt-2">
													<input
														id="estimated-delivery-time"
														name="estimatedDeliveryTime"
														type="number"
														value={template.shipping?.estimatedDeliveryTime}
														onChange={handleShippingChange}
														className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
										</div>

										{/* Shipping method */}
										<div className="mt-4">
											<label
												htmlFor="shipping-method"
												className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
											>
												Shipping Method
											</label>
											<div className="mt-2">
												<input
													id="shipping-method"
													name="method"
													type="text"
													value={template.shipping?.method}
													onChange={handleShippingChange}
													className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
          text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
          dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
          sm:text-sm sm:leading-6"
												/>
											</div>
										</div>

										{/* Weight major/minor + unit */}
										<div className="mt-4">
											<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Package Weight
											</label>
											<div
												className="mt-2 flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0">
												<div className="md:w-1/3 w-full">
													{/* "Major" portion: e.g. pounds or kilograms */}
													<label
														htmlFor="packageWeightMajor"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Major
													</label>
													<input
														id="packageWeightMajor"
														name="packageWeightMajor"
														type="number"
														value={template.shipping?.packageWeightMajor}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>

												<div className="md:w-1/3 w-full">
													{/* "Minor" portion: e.g. ounces or grams */}
													<label
														htmlFor="packageWeightMinor"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Minor
													</label>
													<input
														id="packageWeightMinor"
														name="packageWeightMinor"
														type="number"
														value={template.shipping?.packageWeightMinor}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>

												<div className="md:w-1/3 w-full">
													{/* Weight unit selector: e.g. "lbs", "kg", etc. */}
													<label
														htmlFor="packageWeightUnit"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Weight Unit
													</label>
													<select
														id="packageWeightUnit"
														name="packageWeightUnit"
														value={template.shipping?.packageWeightUnit}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													>
														<option value="">Select Unit</option>
														<option value="lbs">lbs</option>
														<option value="oz">oz</option>
														<option value="kg">kg</option>
														<option value="g">g</option>
													</select>
												</div>
											</div>
										</div>

										{/* Dimensions + dimension unit */}
										<div className="mt-4">
											<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
												Package Dimensions
											</label>
											<div
												className="mt-2 flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0">
												<div className="md:w-1/4 w-full">
													<label
														htmlFor="packageDimensionLength"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Length
													</label>
													<input
														id="packageDimensionLength"
														name="packageDimensionLength"
														type="number"
														value={template.shipping?.packageDimensionLength}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>

												<div className="md:w-1/4 w-full">
													<label
														htmlFor="packageDimensionWidth"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Width
													</label>
													<input
														id="packageDimensionWidth"
														name="packageDimensionWidth"
														type="number"
														value={template.shipping?.packageDimensionWidth}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>

												<div className="md:w-1/4 w-full">
													<label
														htmlFor="packageDimensionHeight"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Height
													</label>
													<input
														id="packageDimensionHeight"
														name="packageDimensionHeight"
														type="number"
														value={template.shipping?.packageDimensionHeight}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													/>
												</div>

												<div className="md:w-1/4 w-full">
													<label
														htmlFor="packageDimensionUnit"
														className="text-xs text-gray-900 dark:text-white font-semibold"
													>
														Dimension Unit
													</label>
													<select
														id="packageDimensionUnit"
														name="packageDimensionUnit"
														value={template.shipping?.packageDimensionUnit}
														onChange={handleShippingChange}
														className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
													>
														<option value="">Select Unit</option>
														<option value="in">in</option>
														<option value="cm">cm</option>
													</select>
												</div>
											</div>
										</div>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
												     fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												template.id ? "Update Template" : "Create Template"
											)}
										</button>
									</div>
								</form>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};
export default TemplateForm;
