import axiosInstance from './server';
import {
	ReturnNotification,
	CreateReturnRequest,
	UpdateReturnRequest,
	GetReturnResponse,
	GetReturnsResponse,
} from 'app-ts-types/returns';

// Get a return notification by ID
export const getReturnById = async (id: string): Promise<GetReturnResponse> => {
	const response = await axiosInstance.get<GetReturnResponse>(`/return/${id}`);
	return response.data;
};

// Get multiple return notifications
export const getReturns = async (filter?:string, filterId?:string): Promise<GetReturnsResponse> => {
	let searchParams = "";
	if(filter && filterId) { searchParams=`?filter=${filter}&filterId=${filterId}`}
	const response = await axiosInstance.get<GetReturnsResponse>(`/return${searchParams}`);
	return response.data;
};

// Create a new return notification
export const createReturn = async (data: CreateReturnRequest): Promise<GetReturnResponse> => {
	const response = await axiosInstance.post<GetReturnResponse>('/return', data);
	return response.data;
};

// Update a return notification by ID
export const updateReturn = async (id: string, data: UpdateReturnRequest): Promise<GetReturnResponse> => {
	const response = await axiosInstance.put<GetReturnResponse>(`/return/${id}`, data);
	return response.data;
};

// Delete a return notification by ID
export const deleteReturn = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/return/${id}`);
};
