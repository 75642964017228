import {makeAutoObservable} from "mobx";
import {Message} from "app-ts-types/messages";
import {generateUUID} from "../utils/data";

const sampleMessages = [
	{
		"id": generateUUID(),
		"user": "Seller456",
		"sender": "eBay",
		"receiver": "Seller456",
		"content": "A new bid of $50.00 has been placed on your item 'Antique Vase'.",
		"timestamp": 1709142600,
		"read": false,
		"platform": 0,
		"responded": false,
		"responseMessage": "",
		"needsAction": false,
		"messageId": "bid-001",
		"messageLink": "https://www.ebay.com/itm/Antique-Vase",
		"createdAt": 1709142600,
		"updatedAt": 1709142600
	},
	{
		"id": generateUUID(),
		"user": "Seller456",
		"sender": "eBay",
		"receiver": "Seller456",
		"content": "You received a Best Offer of $250.00 for your item 'Collectible Toy Car'.",
		"timestamp": 1709154600,
		"read": false,
		"platform": 0,
		"responded": false,
		"responseMessage": "",
		"needsAction": true,
		"messageId": "offer-002",
		"messageLink": "89765435678",
		"createdAt": 1709154600,
		"updatedAt": 1709154600
	}
]

export class MessagesStore {
	messages: Message[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = {field: "timestamp", direction: "desc"};
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setMessages(messages: Message[]) {
		this.messages = messages;
	}

	addMessages(newItems: Message[]) {
		this.messages = [...this.messages, ...newItems];
	}

	updateMessage(updatedItem: Message) {
		const index = this.messages.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.messages[index] = updatedItem;
		}
	}

	deleteMessage(itemId: string) {
		this.messages = this.messages.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedMessages(): Message[] {
		let filteredMessages = this.messages;

		if (this.filter) {
			filteredMessages = filteredMessages.filter(item => this.filter === "read" ? item.read : !item.read);
		}

		if (this.sortOption.field) {
			filteredMessages = filteredMessages.slice().sort((a, b) => {
				if (this.sortOption.direction === "asc") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}

		return filteredMessages;
	}

	get paginatedMessages(): Message[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedMessages.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedMessages.length / this.itemsPerPage);
	}
}
export const messagesStore = new MessagesStore();
