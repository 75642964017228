/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
'use client'

import React, {useEffect, useRef, useState} from 'react'
import {
	Dialog,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	Field,
	Label,
	Switch,
	Tab,
	TabGroup,
	TabList,
	TabPanel,
	TabPanels,
	Transition
} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {CheckCircleIcon, MinusIcon, PlusIcon, TrashIcon, XMarkIcon} from '@heroicons/react/24/outline'
import {
	Categories,
	categoriesToJSON,
	Listing,
	MarketplacePlatform,
	shippingMethodTypeToJson,
	ShippingTemplate
} from "../utils/types";
import {PencilIcon} from "@heroicons/react/16/solid";
import {marketplaceImages} from "./ListingImageCloud";
import ImageUploader from "./ImgUploader";
import {Shipping} from "app-ts-types/listings";
import {defaultSizes, generateUUID, defaultSizesEu} from "../utils/data";
import {PlusCircleIcon, XCircleIcon, MinusCircleIcon} from "@heroicons/react/24/solid";
import {calculateTotalQuantity} from "./CreateInventory";
import {userStore} from "../state/User";
import {refreshListingStats, updateListing} from "../api/listings";
import {listingsStore} from "../state/Listing";
import {NotificationPopupComponent} from "./NotificationPopup";
import ShippingTemplateForm, {ShippingDimensionsComponent} from "./ShippingTemplateComponent";
import {returnsTemplatesStore} from "../state/ReturnsTemplate";
import ReturnPolicyForm from "./ReturnTemplateComponent";
import {initialReturnTemplate} from "./CreateReturnTemplate";
import CategoryMenu from "./EbayCategories";
import {shippingTemplatesStore} from "../state/ShippingTemplate";
import {initialShippingTemplate} from "./CreateShippingTemplate";
import {pricingTemplatesStore} from "../state/PricingTemplate";
import PricingForm from "./PricingTemplateComponent";
import {initialPricingTemplate} from "./CreatePricingTemplate";
import {PricingTemplate, ReturnTemplate, ShippingTemplate as ShippingTemplateType} from "app-ts-types/templates";
import {createPricingTemplate, createReturnTemplate, createShippingTemplate} from "../api/template";
import StockXListing from "./StockXListingForm";
import ListingPlatform from "../sdk";
import {ListingAPIResponseType} from "../types";
import {StockLevelAlert} from "./StockAlert";
import {salesRecordsStore} from "../state/SalesRecord";
import {bidsStore} from "../state/Bids";
import {bestOffersStore} from "../state/BestOffer";
import {returnsStore} from "../state/returns";
import {getBestOffers} from "../api/best-offer";
import {getBids} from "../api/bids";
import {getReturns} from "../api/returns";
import {getSalesRecordsByFilter} from "../api/sales";
import {observer} from "mobx-react-lite";
import {inventoryStore} from "../state/Inventory";


export function ListingItem({listing, onClose, editClick, deleteClick, deListRelistClick, del, dels, edit, markAsSold}: {
	listing: Listing;
	del?: boolean;
	dels?: boolean;
	edit?: boolean;
	onClose: () => void;
	editClick: (listing: Listing) => void;
	deleteClick: (listing: Listing) => void;
	deListRelistClick: (listing: Listing) => void;
	markAsSold: (listing: Listing) => void;
}) {
	const [showDialog, setShowDialog] = useState(true);
	const [deleting, setDeleting] = useState<boolean>(del || false);
	const [delisting, setDelisting] = useState<boolean>(dels || false);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [editMode, setEditMode] = useState(edit || false);
	const [updatedListing, setUpdatedListing] = useState<Listing>(listing);
	const [loading, setLoading] = useState(false);
	const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
	const [sizeType, setSizeType] = useState<'US' | 'EU'>('US');
	const [openCategory, setOpenCategory] = useState<string | null>(null);
	const [openMenu, setOpenMenu] = useState(false);
	const [stockXSellerLevel, setStockXSellerLevel] = useState<number>(1);
	const [shippingPolicyNeedsDimension, setShippingPolicyNeedsDimension] = useState(false);
	const [createNewShippingTemplate, setCreateNewShippingTemplate] = useState(false);
	const [createNewPricingTemplate, setCreateNewPricingTemplate] = useState(false);
	const [createNewReturnsTemplate, setCreateNewReturnsTemplate] = useState(false);
	const [pricingTemplate, setPricingTemplate] = useState(initialPricingTemplate);
	const [returnTemplate, setReturnTemplate] = useState(initialReturnTemplate);
	const [shippingTemplate, setShippingTemplate] = useState(initialShippingTemplate);
	const [pricingErrors, setPricingErrors] = useState<Record<string, string>>({});
	const [shippingErrors, setShippingErrors] = useState<Record<string, string>>({});
	const [returnErrors, setReturnErrors] = useState<Record<string, string>>({});

	useEffect(() => {
		getBestOffers("listingId", updatedListing.id).then((res:any) => {
			if(!res.isError) { bestOffersStore.addBestOffers(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);});
		getBids("listingId", updatedListing.id).then((res:any) => {
			if(!res.isError) { bidsStore.addBids(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);});
		getReturns("listingId", updatedListing.id).then((res:any) => {
			if(!res.isError) { returnsStore.addReturns(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);});
		getSalesRecordsByFilter("listingId", updatedListing.id).then((res:any) => {
			if(!res.isError) { salesRecordsStore.addSalesRecords(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);})
		refreshListingStats(updatedListing.id)
			.then((res:any) => {
				console.log(res)
				if(res.data) { listingsStore.updateListing(res.data); }
				if(res.inventory) { inventoryStore.updateInventory(res.inventory); }
				if (res.bids && res.bids.length) { bidsStore.addBids(res.bids); }
				if (res.offers && res.offers.length) { bestOffersStore.addBestOffers(res.offers); }
				if (res.returns && res.returns.length) { returnsStore.addReturns(res.returns); }
				if (res.sales && res.sales.length) { salesRecordsStore.addSalesRecords(res.sales); }
			})
			.catch((err:any) => {
				console.log(err);
			})
	}, [])

	const menuPopupRef = useRef<HTMLDivElement | null>(null);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		let {name, value} = e.target;
		if (name === "tagsAndKeywords") {
			// @ts-ignore
			value = value.toString().split(", ")
		}
		setUpdatedListing({...updatedListing, [name]: value});
	};

	const handleToggleSizeType = () => {
		setSizeType((prevType) => (prevType === 'US' ? 'EU' : 'US'));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...updatedListing.sizes];
		newSizes[index] = {...newSizes[index], [field]: value};
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
		setOpenCategory(null);
		setOpenMenu(false);
	};

	const handleAddSize = () => {
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, {size: '', quantity: 0}],
		}));
	};

	const handleCategoryClick = (category: string) => {
		setOpenCategory(openCategory === category ? null : category);
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = updatedListing.sizes.filter((_: any, i: any) => i !== index);
		setUpdatedListing((prevInventory: Listing) => ({
			...prevInventory,
			sizes: newSizes,
			quantity: calculateTotalQuantity(newSizes),
		}));
	};

	const handleRemoveImage = (index: number) => {
		setUpdatedListing((prevListing: Listing) => {
			const newFiles = [...prevListing.imageUrls];
			newFiles.splice(index, 1);
			return {
				...prevListing,
				imageUrls: newFiles,
			}
		});
	};

	// Handle input changes for shipping fields
	const handleShippingChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setUpdatedListing({
			...updatedListing,
			shipping: {
				...updatedListing.shipping,
				[name]: value,
			} as Shipping,
		});
	};

	const updateListingFunction = async () => {
		updatedListing.user = userStore.getUser()?.id as string;
		updatedListing.updatedAt = new Date().getTime();
		await updateListing(updatedListing.id, {...updatedListing })
			.then((res: { [key: string]: any }) => {
				if (!res.isError) {
					listingsStore.updateListing(res.data as Listing);
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: `Listing Updated`,
						message: `Listing information successfully updated`
					});
				} else {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: "Error updating listing object",
						message: res.error?.errorMessage || res.message
					});
					console.log(res)
				}
			})
	}

	const handleFormSubmit = async () => {
		setLoading(true);

		// if ebay is part of the market places
		// let's do some verification first
		if (updatedListing.platform === MarketplacePlatform.EBAY) {
			if (!updatedListing.listingObject.returnPolicyId || !updatedListing.listingObject.pricingPolicyId || !updatedListing.listingObject.shippingPolicyId) {
				await createEbayPolicyResources();
			}
			if (!updatedListing.listingObject.returnPolicyId || !updatedListing.listingObject.pricingPolicyId || !updatedListing.listingObject.shippingPolicyId) {
				setLoading(false);
				return setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Ebay Policies",
					message: "Make sure shipping, pricing and return policies are set"
				});
			}
		}

		// if stockX is part of the listing objects
		// let's make sure everything is properly filled
		// else we show an error before the process begins
		if(updatedListing.platform === MarketplacePlatform.STOCKX) {
			if(!updatedListing.listingObject.productId) {
				setLoading(false);
				return setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "StockX Product",
					message: "Please make sure a StockX product is selected"
				});
			}
			if(!updatedListing.listingObject.variantId) {
				setLoading(false);
				return setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "StockX Product Variant",
					message: "Please select a product variant"
				});
			}
			if(updatedListing.price <= 0) {
				setLoading(false);
				return setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "StockX Product price",
					message: "Input price for the selected product"
				});
			}
		}

		try {
			if(updatedListing.status.toLowerCase() !== "delisted"){
				await new ListingPlatform()
					.updateListing(updatedListing)
					.then((res: ListingAPIResponseType) => {
						if (!res.isError) {
							listingsStore.updateListing(res.data as Listing);
							setNotificationObject({
								...notificationObject,
								show: true,
								isError: false,
								title: `Listing Updated`,
								message: `Listing information successfully updated`
							});
						} else {
							setNotificationObject({
								...notificationObject,
								show: true,
								isError: true,
								title: "Error updating listing object",
								message: res.message
							});
							console.log(res)
						}
					})
					.catch((err) => {
						setNotificationObject({
							...notificationObject,
							show: true,
							isError: true,
							title: "Error updating listing object",
							message: err.message
						});
					});
			}
			setEditMode(false);
		} catch (error:any) {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: "Error updating listing object",
				message: error.message
			});
			console.error('Error updating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		});
	};

	const handleMenuClose = (event: any) => {
		if (menuPopupRef.current && !menuPopupRef.current.contains(event.target as Node)) {
			setOpenMenu(false);
			setOpenCategory("");
		}
	};

	const handleCategory = async (category: any) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				category: category.CategoryID,
				categoryName: category.CategoryName,
				parentCategory: category.CategoryParentID,
				parentCategoryName: category.CategoryParentName,
			},
			category: category.CategoryID,
		}));
	};

	const handleCategorySpecifics = async (categorySpecificsValue: { [key: string]: any }) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				...categorySpecificsValue,
			}
		}));
	};

	const createEbayPolicyResources = async () => {
		if(createNewPricingTemplate) {
				setUpdatedListing((prevListing) => ({
					...prevListing,
					listingObject: {...prevListing.listingObject, pricingPolicyId: "",}
				}));

			if (Object.keys(pricingErrors).length > 0) {
				// 3. Combine them into a single string
				const combinedErrorMessage = Object.values(pricingErrors).join("\n ");
				// 4. Show them in a single notification
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error in pricing label",
					message: combinedErrorMessage
				});
				// Stop the flow, do NOT call the API
				return;
			}
			// create the templates making sure ids are assigned
			await createPricingFunction(pricingTemplate).catch((e:any) => {console.log(e);});
		}
		if(createNewShippingTemplate) {
			setUpdatedListing((prevListing) => ({
				...prevListing,
				listingObject: {...prevListing.listingObject, shippingPolicyId: "",}
			}));
			if (Object.keys(shippingErrors).length > 0) {
				// 3. Combine them into a single string
				const combinedErrorMessage = Object.values(shippingErrors).join("\n ");
				// 4. Show them in a single notification
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error in shipping label",
					message: combinedErrorMessage
				});
				// Stop the flow, do NOT call the API
				return;
			}
			await createShippingFunction(shippingTemplate).catch((e:any) => {console.log(e);});
		}
		if(createNewReturnsTemplate) {
			setUpdatedListing((prevListing) => ({...prevListing, listingObject: {...prevListing.listingObject, returnPolicyId: "",}}));
			if (Object.keys(returnErrors).length > 0) {
				// 3. Combine them into a single string
				const combinedErrorMessage = Object.values(returnErrors).join("\n ");
				// 4. Show them in a single notification
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: "Error in return label",
					message: combinedErrorMessage
				});
				return;
			}
			await createReturnFunction(returnTemplate).catch((e: any) => {console.log(e);});
		}
	}

	const createPricingFunction = async (template: PricingTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		template.label = `${template.listingType}: ${template.startingBid || template.buyItNowPrice} ${template.allowBestOffer && `(Min:${template.autoDeclineBelow} - Max:${template.minAcceptOffer})`}`
		return createPricingTemplate({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then(async (res: { [key: string]: any }) => {
				if (!res.isError) {
					pricingTemplatesStore.addPricingTemplates([res.data]);
					await handleEbayPricingChange(res.data);
				} else {
					console.log(res);
				}
				return res;
			});
	};

	const createReturnFunction = async (template: ReturnTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		template.label = `${template.domesticPolicy?.acceptReturns && "Return Accepted, "} ${template.domesticPolicy?.shippingCostPaidBy}, ${template.domesticPolicy?.returnsWithin}, ${template.domesticPolicy?.refundMethod}`
		return createReturnTemplate({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime })
			.then(async (res: { [key: string]: any }) => {
				if (!res.isError) {
					returnsTemplatesStore.addReturnTemplates([res.data]);
					await handleReturnsChange(res.data);
				} else {
					console.log(res);
				}
				return res;
			});
	};

	const createShippingFunction = async (template: ShippingTemplate): Promise<any> => {
		template.user = userStore.getUser()?.id as string;
		const currentTime = new Date().getTime();
		template.updatedAt = currentTime;
		template.label = `${template.domesticPolicy.costType}: ${shippingMethodTypeToJson(template.domesticPolicy.shippingMethod)} (${template.handlingTime})`
		return createShippingTemplate({ ...template, id: template.id || generateUUID(), createdAt: template.createdAt || currentTime } as ShippingTemplateType)
			.then(async (res: { [key: string]: any }) => {
				if (!res.isError) {
					shippingTemplatesStore.addShippingTemplates([res.data]);
					await handleEbayShippingChange(res.data);
				} else {
					console.log(res);
				}
				return res;
			});
	};

	const handleEbayShippingChange = async (shippingTemplate: ShippingTemplate) => {
		if(shippingTemplate.domesticPolicy?.needsDimensions || shippingTemplate.internationalPolicy?.needsDimensions) {
			setShippingPolicyNeedsDimension(true);
		}
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				shippingPolicyId: shippingTemplate.id,
			}
		}));
	}

	const handleEbayPricingChange = async (pricingTemplate: PricingTemplate) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				pricingPolicyId: pricingTemplate.id,
			}
		}));
	}

	const handleReturnsChange = async (returnTemplate: ReturnTemplate) => {
		setUpdatedListing((prevListing) => ({
			...prevListing,
			listingObject: {
				...prevListing.listingObject,
				returnPolicyId: returnTemplate.id,
			}
		}));
	}

	const calculateStockXFee = (salePrice: number, sellerCountry: string="US"): { totalFee: number; currency: string } => {
		// Mapping of seller levels to their respective transaction fee percentages.
		const levelFeeMapping: { [level: number]: number } = {
			1: 9.0,  // Level 1: 9%
			2: 8.5,  // Level 2: 8.5%
			3: 8.0,  // Level 3: 8%
			4: 7.5,  // Level 4: 7.5%
			5: 7.0   // Level 5: 7%
		};

		// Define minimum fee information by seller country.
		const minFees: { [country: string]: { fee: number; currency: string } } = {
			"Australia": { fee: 7.50, currency: "AUD" },
			"Canada": { fee: 7.00, currency: "CAD" },
			"EU": { fee: 5.00, currency: "EUR" },
			"Great Britain": { fee: 4.50, currency: "GBP" },
			"Hong Kong": { fee: 45.00, currency: "HKD" },
			"Japan": { fee: 800.00, currency: "JPY" },
			"Mexico": { fee: 100.00, currency: "MXN" },
			"New Zealand": { fee: 16.00, currency: "NZD" },
			"Singapore": { fee: 7.00, currency: "SGD" },
			"South Korea": { fee: 7500.00, currency: "KRW" },
			"Switzerland": { fee: 4.50, currency: "CHF" },
			"US": { fee: 5.00, currency: "USD" }
		};

		// Calculate the 3% payment processing fee.
		const processingFee = salePrice * 0.03;

		// Get the transaction fee percentage based on the seller level.
		// Defaults to Level 1 fee (9%) if an unexpected seller level is provided.
		const transactionFeePercentage = levelFeeMapping[stockXSellerLevel] || 9.0;
		const transactionFee = salePrice * (transactionFeePercentage / 100);
		// Fixed shipping fee from provided adjustment JSON.
		const shippingFee = 4;
		// Calculate the total fee from processing and transaction fees.
		const totalFee = processingFee + transactionFee + shippingFee;
		// Get the minimum fee info for the given seller country.
		// Defaults to a minimum fee of $5.00 USD if the country is not in our list.
		const minFeeInfo = minFees[sellerCountry] || { fee: 5.00, currency: "USD" };
		// The final fee is the greater of the calculated fee or the minimum fee.
		const finalFee = Math.max(totalFee, minFeeInfo.fee);

		return { totalFee: finalFee, currency: minFeeInfo.currency };
	}

	const calculateEbayFee = (jsonString: string): { totalFee: number; currency: string } => {
		// Parse the JSON string into an array of fee items.
		const feeItems: any[] = JSON.parse(jsonString);
		let totalFee = 0;

		// Iterate over each fee item.
		feeItems.forEach((item) => {
			// Skip empty objects or objects without an "amount" property.
			if (item && item.amount !== undefined) {
				// Convert the fee amount from string to number.
				const amount = parseFloat(item.amount);
				// Check for a promotional discount; if present, apply it.
				const discount = item.promotional_discount ? parseFloat(item.promotional_discount) : 0;
				// Calculate the fee after discount.
				const feeAfterDiscount = amount * (1 - discount);
				// Add to the running total.
				totalFee += feeAfterDiscount;
			}
		});

		// Assume the currency is USD (all fee items have "USD" in this JSON).
		return { totalFee, currency: "USD" };
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />;
	}

	let fees = {totalFee: 0.00, currency: "USD"};
	let stockXSellerLevelSelector;
	let category:any = <div className="mt-4">
		<label htmlFor="category"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Category <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<select
				id="category"
				name="category"
				value={updatedListing.category}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			>
				<option value="">Select a category</option>
				{Object.keys(Categories).filter(key => isNaN(Number(key))).map((key) => (
					<option key={key} value={key}>
						{key.replaceAll("_", " ")}
						</option>
					))}
			</select>
		</div>
	</div>;
	let shippingInformation:any = <div>
		<h1 className="block text-lg pt-4 font-medium leading-6 text-gray-900 dark:text-white">
			Shipping Information
		</h1>

		{/* First row: cost & estimated delivery */}
		<div className="flex mt-4 justify-between space-x-0 md:space-x-4 md:flex-row flex-col">
			<div className="md:w-1/2 w-full">
				<label
					htmlFor="shipping-cost"
					className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
				>
					Shipping Cost
				</label>
				<div className="mt-2">
					<input
						id="shipping-cost"
						name="cost"
						type="number"
						value={updatedListing.shipping?.cost}
						onChange={handleShippingChange}
						className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
			<div className="md:w-1/2 w-full">
				<label
					htmlFor="estimated-delivery-time"
					className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
				>
					Estimated Delivery Time (days)
				</label>
				<div className="mt-2">
					<input
						id="estimated-delivery-time"
						name="estimatedDeliveryTime"
						type="number"
						value={updatedListing.shipping?.estimatedDeliveryTime}
						onChange={handleShippingChange}
						className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
		</div>

		{/* Shipping method */}
		<div className="mt-4">
			<label
				htmlFor="shipping-method"
				className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
			>
				Shipping Method
			</label>
			<div className="mt-2">
				<input
					id="shipping-method"
					name="method"
					type="text"
					value={updatedListing.shipping?.method}
					onChange={handleShippingChange}
					className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
          text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
          dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
          sm:text-sm sm:leading-6"
				/>
			</div>
		</div>

		{/* the package weight component */}
		<ShippingDimensionsComponent handleShippingChange={handleShippingChange} shipping={updatedListing.shipping}/>
	</div>;
	let location:any = <div className="mt-4">
		<label htmlFor="location"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Location <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="location"
				name="location"
				type="text"
				autoComplete="location"
				value={updatedListing.location}
				onChange={handleInputChange}
				placeholder={"City, State, Country"}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>;
	let pricing: any = <div className="mt-4">
		<label htmlFor="price"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Price <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="price"
				name="price"
				type="number"
				autoComplete="price"
				value={updatedListing.price}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>;
	let title: any = <div className="mt-4">
		<label htmlFor="title"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Title <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="title"
				name="title"
				type="text"
				autoComplete="title"
				value={updatedListing.title}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let description: any = <div className="mt-4">
		<label htmlFor="description"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Description <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
                      <textarea
	                      id="description"
	                      name="description"
	                      autoComplete="description"
	                      value={updatedListing.description}
	                      onChange={handleInputChange}
	                      className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
	                      rows={4}
                      />
		</div>
	</div>
	let sku: any = <div className="mt-4">
		<label htmlFor="SKU"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			SKU <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="SKU"
				name="SKU"
				type="text"
				autoComplete="sku"
				value={updatedListing.SKU}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let brand: any = <div className="mt-4">
		<label htmlFor="brand"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Brand <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="brand"
				name="brand"
				type="text"
				autoComplete="brand"
				value={updatedListing.brand}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let color: any = <div className="mt-4">
		<label htmlFor="color"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Color
		</label>
		<div className="mt-2">
			<input
				id="color"
				name="color"
				type="text"
				autoComplete="color"
				value={updatedListing.color}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let seller: any = <div className="mt-4 hidden">
		<label htmlFor="seller"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Seller <span className={"text-red-500 font-bold"}>*</span>
		</label>
		<div className="mt-2">
			<input
				id="seller"
				name="seller"
				type="text"
				autoComplete="seller"
				value={updatedListing.seller}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let currencyCode: any = <div className="mt-4">
		<label htmlFor="currencyCode"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Currency Code
		</label>
		<div className="mt-2">
			<select
				id="currencyCode"
				name="currencyCode"
				value={updatedListing.currencyCode}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			>
				<option value="USD">USD ($)</option>
				<option value="EUR">EUR (€)</option>
				<option value="GBP">GBP (£)</option>
			</select>
		</div>
	</div>
	let condition: any = <div className="mt-4">
		<label htmlFor="condition"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Condition
		</label>
		<div className="mt-2">
			<select
				id="condition"
				name="condition"
				value={updatedListing.condition}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			>
				<option value="new">New</option>
				<option value="slightly used">Slightly Used</option>
				<option value="used">Used</option>
			</select>
		</div>
	</div>
	let quantity: any = <div className="mt-4">
		<label htmlFor="quantity"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Quantity
		</label>
		<div className="mt-2">
			<input
				id="quantity"
				name="quantity"
				type="number"
				autoComplete="quantity"
				value={updatedListing.quantity}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let sizes: any = <div className="mt-3 relative">
		<div className="flex w-24 items-center relative justify-between">
			<label htmlFor="sizes"
			       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
				Sizes
			</label>
			<Switch
				checked={sizeType === 'EU'}
				onChange={handleToggleSizeType}
				className={`${sizeType === 'EU' ? 'bg-indigo-600' : 'bg-gray-200'} relative inline-flex h-6 w-11 items-center rounded-full`}
			>
				<span className="sr-only">Toggle Size Type</span>
				<span
					className={`${sizeType === 'EU' ? 'translate-x-6' : 'translate-x-1'} inline-block text-[0.5rem] font-avenir-bold h-4 w-4 pt-[0.15rem] transform rounded-full bg-white transition`}>
														{sizeType}
														</span>
			</Switch>
		</div>
		{
			openMenu &&
			<Transition
				as={"div"}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<div
					ref={menuPopupRef}
					className="absolute left-0 bottom-10 border dark:border-gray-700 z-20 mt-2 w-56 origin-top-left rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none max-h-60 overflow-y-auto">
					<div className="py-1">
						{(sizeType === "US" ? defaultSizes : defaultSizesEu).map((sizeOption) => (
							<div key={sizeOption}>
								<button
									type="button"
									onClick={(e) => {
										e.preventDefault();
										handleSizeChange(currentSizeIndex, 'size', sizeOption)
									}}
									className={`block w-full px-4 py-2 text-left text-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700`}
								>
									{sizeOption}  {sizeType === "US" ? "(US)" : null}
								</button>
							</div>
						))}
					</div>
				</div>
			</Transition>
		}
		{updatedListing.sizes.map((size, index) => (
			<div key={index} className="flex items-center mt-1">
				<div className="relative inline-block text-left w-1/2 mr-2">
					<div>
						<div
							onClick={() => {
								setCurrentSizeIndex(index)
								setOpenMenu(!openMenu)
							}}
							className="inline-flex w-full cursor-pointer justify-center gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
							{size.size || 'Select Size'}
							<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
						</div>
					</div>
				</div>
				<input
					type="number"
					name={`quantity-${index}`}
					value={size.quantity}
					onChange={(e) => {
						e.preventDefault();
						handleSizeChange(index, 'quantity', parseInt(e.target.value))
					}}
					className="mr-2 block w-1/2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
					placeholder="Quantity"
				/>
				<button
					type="button"
					onClick={() => handleRemoveSize(index)}
					className={`${index === 0 && "invisible"} text-red-500 hover:text-red-700`}
				>
					<XCircleIcon className="h-6 w-6"/>
				</button>
				<button
					type="button"
					onClick={handleAddSize}
					className="text-blue-500 hover:text-blue-700 ml-2"
				>
					<PlusCircleIcon className="h-6 w-6"/>
				</button>
			</div>
		))}
	</div>
	let tagsAndKeywords: any = <div className="mt-4">
		<label htmlFor="tagsAndKeywords"
		       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
			Tags And Keywords
		</label>
		<div className="mt-2">
			<input
				id="tagsAndKeywords"
				name="tagsAndKeywords"
				type="text"
				autoComplete="tags-and-keywords"
				value={updatedListing.tagsAndKeywords.join(", ")}
				onChange={handleInputChange}
				className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
			/>
		</div>
	</div>
	let returnPolicy:any = <div>
		<h1 className="mb-4 text-lg flex w-full justify-between pt-4 font-medium leading-6 text-gray-900 dark:text-white">
			<span>Return Policy Information</span>
			<Field className="flex items-center">
				<Switch
					checked={createNewReturnsTemplate}
					onChange={setCreateNewReturnsTemplate}
					className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
				>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
				</Switch>
				<Label as="span" className="ml-3 text-sm">
					<span className="font-medium text-gray-900 dark:text-gray-200">Create New Return Policy?</span>
				</Label>
			</Field>
		</h1>
		{
			!createNewReturnsTemplate ? (
				<select
					value={updatedListing.listingObject.returnPolicyId}
					onChange={e => handleReturnsChange(returnsTemplatesStore.templates.filter(t => t.id === e.target.value)[0])}
					className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
				>
					<option key={0} value={""}>--Select Return Policy--</option>
					{returnsTemplatesStore.templates.map(t => (
						<option key={t.id} value={t.id}>{t.label}</option>
					))}
				</select>
			) : (
				// @ts-ignore
				<ReturnPolicyForm value={initialReturnTemplate} propagateErrors={setReturnErrors} isFormInListing={true} onChange={(updated) => setReturnTemplate(updated)}/>
			)
		}
	</div>
	if (updatedListing.platform === MarketplacePlatform.EBAY) {
		category = <CategoryMenu listingObject={updatedListing.listingObject} onChange={handleCategorySpecifics} onSelectCategory={handleCategory}/>
		shippingInformation = <div>
			<h1 className="mb-4 text-lg flex w-full justify-between pt-4 font-medium leading-6 text-gray-900 dark:text-white">
				<span>Shipping Information</span>
				<Field className="flex items-center">
					<Switch
						checked={createNewShippingTemplate}
						onChange={setCreateNewShippingTemplate}
						className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
					>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
					</Switch>
					<Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900 dark:text-gray-200">Create New Shipping?</span>
					</Label>
				</Field>
			</h1>
			{
				!createNewShippingTemplate ? (
					<select
						value={updatedListing.listingObject.shippingPolicyId}
						onChange={e => handleEbayShippingChange(shippingTemplatesStore.templates.filter(t => t.id === e.target.value)[0])}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					>
						<option key={0} value={""}>--Select Shipping Policy--</option>
						{shippingTemplatesStore.templates.map(t => (
							<option key={t.id} value={t.id}>{t.label}</option>
						))}
					</select>
				) : (
					<ShippingTemplateForm isFormInListing={true} handlingTimeCap={3} propagateErrors={setShippingErrors} value={initialShippingTemplate} onChange={(updated) => setShippingTemplate(updated)}/>
				)
			}
			{shippingPolicyNeedsDimension && (<ShippingDimensionsComponent handleShippingChange={handleShippingChange} shipping={updatedListing.shipping}/>)}
		</div>
		location = null;
		pricing = <div>
			<h1 className="mb-4 text-lg flex w-full justify-between pt-4 font-medium leading-6 text-gray-900 dark:text-white">
				<span>Pricing Information</span>
				<Field className="flex items-center">
					<Switch
						checked={createNewPricingTemplate}
						onChange={setCreateNewPricingTemplate}
						className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
					>
        <span
	        aria-hidden="true"
	        className="pointer-events-none inline-block size-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
        />
					</Switch>
					<Label as="span" className="ml-3 text-sm">
						<span className="font-medium text-gray-900 dark:text-gray-200">Create New Pricing?</span>
					</Label>
				</Field>
			</h1>
			{
				!createNewPricingTemplate ? (
					<select
						value={updatedListing.listingObject.pricingPolicyId}
						onChange={e => handleEbayPricingChange(pricingTemplatesStore.templates.filter(t => t.id === e.target.value)[0])}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					>
						<option key={0} value={""}>--Select Pricing Policy--</option>
						{pricingTemplatesStore.templates.map(t => (
							<option key={t.id} value={t.id}>{t.label}</option>
						))}
					</select>
				) : (
					<PricingForm propagateErrors={setPricingErrors} value={initialPricingTemplate} isFormInListing={true} onChange={(updated) => setPricingTemplate(updated)}/>
				)
			}
		</div>
		shippingInformation = null;
		sizes = null;
		returnPolicy = null;
		pricing = null;
		if(updatedListing.listingObject["ebayFees"])
			fees = calculateEbayFee(updatedListing.listingObject["ebayFees"])
	}
	if (updatedListing.platform === MarketplacePlatform.STOCKX) {
		title = <StockXListing listing={updatedListing} handleInputChange={handleInputChange}
		                       handlePlatformSpecifics={handleCategorySpecifics} setImages={(files) => {
			setUpdatedListing({...updatedListing, imageUrls: files,});
		}}/>
		sku = null;
		sizes = null;
		brand = null;
		color = null;
		seller = null;
		condition = null;
		description = null;
		currencyCode = null;
		tagsAndKeywords = null;
		quantity = null;
		location = null;
		category = null;
		pricing = null;
		shippingInformation = null;
		returnPolicy = null;
		fees = calculateStockXFee(updatedListing.price);
		stockXSellerLevelSelector = <>
			<span className={"text-sm dark:text-white text-black"}>Seller Level: </span>
			<select
				value={stockXSellerLevel}
				onChange={e => setStockXSellerLevel(parseInt(e.target.value))}
				className="mt-1 w-12 rounded-md border-0 bg-gray-100 dark:bg-white/5
	            py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
	            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
			>
				{[1, 2, 3, 4, 5].map(t => (<option key={t} value={t}>{t}</option>))}
			</select>
		</>
	}

	// 1) Filter each store by this listingId
	const itemSales = salesRecordsStore.salesRecords.filter((sr) => sr.listingId === updatedListing.id);
	const itemBids = bidsStore.bids.filter((bid) => bid.listingId === updatedListing.id);
	const itemBestOffers = bestOffersStore.bestOffers.filter((offer) => offer.listingId === updatedListing.id);
	const itemReturns = returnsStore.returns.filter((ret) => ret.listingId === updatedListing.id);

	// 2) Simple profit calculation: sum of all sales minus cost/fees
	const totalRevenue = itemSales.reduce((sum, sr) => sum + (sr.price || 0), 0);
	const profit = totalRevenue - (updatedListing.price || 0);

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
		<Dialog as="div" className="relative z-[100]" onClose={() => {
				setShowDialog(false);
				onClose();
			}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								onClick={handleMenuClose}
								className="w-full max-w-5xl transform overflow-hidden rounded-2xl bg-white dark:text-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<div className="mx-auto">
									<div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
										<div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
											{/* Image gallery */}
											<TabGroup as="div" className="flex flex-col">
												<TabPanels className="aspect-h-1 aspect-w-1 w-full">
													{updatedListing.imageUrls.map((image) => (
														<TabPanel key={image}>
															<img
																alt={updatedListing.title}
																src={image}
																className="h-full w-full object-cover object-center sm:rounded-lg"
															/>
														</TabPanel>
													))}
												</TabPanels>
												{/* Image selector */}
												<div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
													<TabList className="grid grid-cols-4 gap-6">
														{updatedListing.imageUrls.map((image, index) => (
															<Tab key={image}
															     className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white dark:bg-gray-800 text-sm font-medium uppercase text-gray-900 dark:text-white hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none">
																<span className="sr-only">{updatedListing.title}</span>
																<span className="absolute inset-0 overflow-hidden rounded-md">
								                  <img alt="" src={image} className="h-full w-full object-cover object-center"/>
								                </span>
																<span
																	aria-hidden="true"
																	className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
																/>
																{
																	editMode && (
																		<button
																			onClick={() => { handleRemoveImage(index) }}
																			className="absolute z-50 top-2 left-2 bg-red-500 text-white p-1 rounded-full duration-300"
																		>
																			<XMarkIcon className={"h-6 w-6"}/>
																		</button>
																	)
																}
															</Tab>
														))}
														{editMode && (
															<div
																className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-gray-100 dark:bg-gray-700 text-sm font-medium uppercase text-gray-500 hover:bg-gray-200 dark:hover:bg-gray-600 focus:outline-none">
																<ImageUploader
																	onUpload={(files) => {
																		setUpdatedListing({
																			...updatedListing,
																			imageUrls: [...updatedListing.imageUrls, ...files],
																		});
																	}}
																	files={[]}
																	maxFiles={25}
																	multiple={true}
																	miniUpload={true}
																/>
															</div>
														)}
													</TabList>
												</div>
												{
													!editMode && (
														<div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
															<section aria-labelledby="details-heading" className="mt-12">
																<div className="divide-y divide-gray-200 border-t border-b border-gray-200 dark:border-gray-700">
												{/*					/!* Sales History *!/*/}
												{/*					<Disclosure as="div">*/}
												{/*						{({open}) => (*/}
												{/*							<>*/}
												{/*								<DisclosureButton*/}
												{/*									className="group relative flex w-full items-center justify-between py-6 text-left">*/}
                        {/*<span className="text-sm font-medium text-gray-900 dark:text-gray-200">*/}
                        {/*  Sales History & Profit Estimations*/}
                        {/*</span>*/}
												{/*									<span className="ml-6 flex items-center">*/}
                        {/*  {open ? (*/}
	                      {/*    <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>*/}
                        {/*  ) : (*/}
	                      {/*    <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                        {/*  )}*/}
                        {/*</span>*/}
												{/*								</DisclosureButton>*/}
												{/*								<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">*/}
												{/*									/!* Sales Table *!/*/}
												{/*									{itemSales.length > 0 ? (*/}
												{/*										<div className="overflow-x-auto">*/}
												{/*											<table className="min-w-full text-left text-sm mb-4">*/}
												{/*												<thead>*/}
												{/*												<tr>*/}
												{/*													<th className="p-2 font-semibold">Platform</th>*/}
												{/*													<th className="p-2 font-semibold">Price</th>*/}
												{/*													<th className="p-2 font-semibold">Profit</th>*/}
												{/*												</tr>*/}
												{/*												</thead>*/}
												{/*												<tbody className={"divide-y divide-gray-500"}>*/}
												{/*												{itemSales.map((sr) => (*/}
												{/*													<tr key={sr.id} className="border-b border-gray-200 dark:border-gray-700">*/}
												{/*														<td className="p-2"><img src={marketplaceImages[sr.platform]?.src}*/}
												{/*														                         alt={marketplaceImages[sr.platform]?.alt}*/}
												{/*														                         className="h-6 w-6"/></td>*/}
												{/*														<td className="p-2">${sr.price?.toFixed(2)}</td>*/}
												{/*														<td className="p-2">{(sr.price - (updatedListing.price || 0)).toFixed(2)}</td>*/}
												{/*													</tr>*/}
												{/*												))}*/}
												{/*												</tbody>*/}
												{/*											</table>*/}
												{/*											<div className="flex flex-col space-y-1 text-gray-600 dark:text-gray-300">*/}
												{/*												<div>Total Revenue: <strong>${totalRevenue.toFixed(2)}</strong></div>*/}
												{/*												<div className="text-green-600 dark:text-green-400">*/}
												{/*													Profit: <strong>${profit.toFixed(2)}</strong>*/}
												{/*												</div>*/}
												{/*											</div>*/}
												{/*										</div>*/}
												{/*									) : (*/}
												{/*										<p className="italic text-gray-500 dark:text-gray-400">*/}
												{/*											No sales recorded yet.*/}
												{/*										</p>*/}
												{/*									)}*/}
												{/*								</DisclosurePanel>*/}
												{/*							</>*/}
												{/*						)}*/}
												{/*					</Disclosure>*/}

												{/*					/!* Offers *!/*/}
												{/*					<Disclosure as="div">*/}
												{/*						{({open}) => (*/}
												{/*							<>*/}
												{/*								<DisclosureButton*/}
												{/*									className="group relative flex w-full items-center justify-between py-6 text-left">*/}
                        {/*<span className="text-sm font-medium text-gray-900 dark:text-gray-200">*/}
                        {/*  Offers*/}
                        {/*</span>*/}
												{/*									<span className="ml-6 flex items-center">*/}
                        {/*  {open ? (*/}
	                      {/*    <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>*/}
                        {/*  ) : (*/}
	                      {/*    <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                        {/*  )}*/}
                        {/*</span>*/}
												{/*								</DisclosureButton>*/}
												{/*								<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">*/}
												{/*									/!* Best Offers Table *!/*/}
												{/*									{itemBestOffers.length > 0 ? (*/}
												{/*										<div className="overflow-x-auto">*/}
												{/*											<table className="min-w-full text-left text-sm">*/}
												{/*												<thead>*/}
												{/*												<tr>*/}
												{/*													<th className="p-2 font-semibold">Platform</th>*/}
												{/*													<th className="p-2 font-semibold">Offer Price</th>*/}
												{/*													<th className="p-2 font-semibold">Status</th>*/}
												{/*												</tr>*/}
												{/*												</thead>*/}
												{/*												<tbody>*/}
												{/*												{itemBestOffers.map((offer) => (*/}
												{/*													<tr*/}
												{/*														key={offer.id}*/}
												{/*														className="border-b border-gray-200 dark:border-gray-700"*/}
												{/*													>*/}
												{/*														<td className="p-2"><img src={marketplaceImages[offer.marketplace]?.src} alt={marketplaceImages[offer.marketplace]?.alt} className="h-6 w-6"/></td>*/}
												{/*														<td className="p-2">${offer.offerPrice?.toFixed(2)}</td>*/}
												{/*														<td className="p-2">{offer.status}</td>*/}
												{/*													</tr>*/}
												{/*												))}*/}
												{/*												</tbody>*/}
												{/*											</table>*/}
												{/*										</div>*/}
												{/*									) : (*/}
												{/*										<p className="italic text-gray-500 dark:text-gray-400">*/}
												{/*											No offers made on this product*/}
												{/*										</p>*/}
												{/*									)}*/}
												{/*								</DisclosurePanel>*/}
												{/*							</>*/}
												{/*						)}*/}
												{/*					</Disclosure>*/}

												{/*					/!* Bids *!/*/}
												{/*					<Disclosure as="div">*/}
												{/*						{({open}) => (*/}
												{/*							<>*/}
												{/*								<DisclosureButton*/}
												{/*									className="group relative flex w-full items-center justify-between py-6 text-left">*/}
								        {/*                <span className="text-sm font-medium text-gray-900 dark:text-gray-200">*/}
								        {/*                  Bids*/}
								        {/*                </span>*/}
												{/*									<span className="ml-6 flex items-center">*/}
								        {/*                  {open ? (*/}
									      {/*                    <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>*/}
								        {/*                  ) : (*/}
									      {/*                    <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
								        {/*                  )}*/}
								        {/*                </span>*/}
												{/*								</DisclosureButton>*/}
												{/*								<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">*/}
												{/*									/!* Bids Table *!/*/}
												{/*									{itemBids.length > 0 ? (*/}
												{/*										<div className="overflow-x-auto">*/}
												{/*											<table className="min-w-full text-left text-sm">*/}
												{/*												<thead>*/}
												{/*												<tr>*/}
												{/*													<th className="p-2 font-semibold">Platform</th>*/}
												{/*													<th className="p-2 font-semibold">Bid Amount</th>*/}
												{/*												</tr>*/}
												{/*												</thead>*/}
												{/*												<tbody>*/}
												{/*												{itemBids.map((bid) => (*/}
												{/*													<tr*/}
												{/*														key={bid.id}*/}
												{/*														className="border-b border-gray-200 dark:border-gray-700"*/}
												{/*													>*/}
												{/*														<td className="p-2"><img src={marketplaceImages[bid.marketplace]?.src}*/}
												{/*														                         alt={marketplaceImages[bid.marketplace]?.alt}*/}
												{/*														                         className="h-6 w-6"/></td>*/}
												{/*														<td className="p-2">${bid.bidAmount?.toFixed(2)}</td>*/}
												{/*													</tr>*/}
												{/*												))}*/}
												{/*												</tbody>*/}
												{/*											</table>*/}
												{/*										</div>*/}
												{/*									) : (*/}
												{/*										<p className="italic text-gray-500 dark:text-gray-400">*/}
												{/*											No bids made on this product*/}
												{/*										</p>*/}
												{/*									)}*/}
												{/*								</DisclosurePanel>*/}
												{/*							</>*/}
												{/*						)}*/}
												{/*					</Disclosure>*/}

												{/*					/!* Returns *!/*/}
												{/*					<Disclosure as="div">*/}
												{/*						{({open}) => (*/}
												{/*							<>*/}
												{/*								<DisclosureButton*/}
												{/*									className="group relative flex w-full items-center justify-between py-6 text-left">*/}
                        {/*<span className="text-sm font-medium text-gray-900 dark:text-gray-200">*/}
                        {/*  Returns*/}
                        {/*</span>*/}
												{/*									<span className="ml-6 flex items-center">*/}
                        {/*  {open ? (*/}
	                      {/*    <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>*/}
                        {/*  ) : (*/}
	                      {/*    <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>*/}
                        {/*  )}*/}
                        {/*</span>*/}
												{/*								</DisclosureButton>*/}
												{/*								<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">*/}
												{/*									/!* Returns Table *!/*/}
												{/*									{itemReturns.length > 0 ? (*/}
												{/*										<div className="overflow-x-auto">*/}
												{/*											<table className="min-w-full text-left text-sm">*/}
												{/*												<thead>*/}
												{/*												<tr>*/}
												{/*													<th className="p-2 font-semibold">Platform</th>*/}
												{/*													<th className="p-2 font-semibold">Return Status</th>*/}
												{/*													<th className="p-2 font-semibold">Creation Date</th>*/}
												{/*												</tr>*/}
												{/*												</thead>*/}
												{/*												<tbody>*/}
												{/*												{itemReturns.map((ret) => (*/}
												{/*													<tr*/}
												{/*														key={ret.id}*/}
												{/*														className="border-b border-gray-200 dark:border-gray-700"*/}
												{/*													>*/}
												{/*														<td className="p-2"><img src={marketplaceImages[ret.marketplace]?.src}*/}
												{/*														                         alt={marketplaceImages[ret.marketplace]?.alt}*/}
												{/*														                         className="h-6 w-6"/></td>*/}
												{/*														<td className="p-2">{ret.returnStatus}</td>*/}
												{/*														<td className="p-2">{ret.creationDate}</td>*/}
												{/*													</tr>*/}
												{/*												))}*/}
												{/*												</tbody>*/}
												{/*											</table>*/}
												{/*										</div>*/}
												{/*									) : (*/}
												{/*										<p className="italic text-gray-500 dark:text-gray-400">*/}
												{/*											No return information processed*/}
												{/*										</p>*/}
												{/*									)}*/}
												{/*								</DisclosurePanel>*/}
												{/*							</>*/}
												{/*						)}*/}
												{/*					</Disclosure>*/}
																</div>
															</section>
														</div>
													)
												}
											</TabGroup>

											{/* Product info */}
											<div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
												<StockLevelAlert totalQuantity={updatedListing.quantity} />
												{/* Display the listing platform */}
												<div className="flex mt-5 items-center gap-2">
													<img
														src={marketplaceImages[updatedListing.platform]?.src}
														alt={marketplaceImages[updatedListing.platform]?.alt}
														className="h-6 w-6"
													/>
													<span className="text-gray-800 dark:text-gray-200">
							            {marketplaceImages[updatedListing.platform]?.alt}
							          </span>
												</div>

												{!editMode ? (
													<>
														<h1 className="text-3xl font-bold tracking-tight dark:text-gray-200 text-gray-900 mt-4">
															{updatedListing.title}
														</h1>

														<div className="mt-3">
															<p className="text-3xl tracking-tight text-gray-900 dark:text-gray-200">
																{updatedListing.currencyCode  === 'USD' ? '$' : updatedListing.currencyCode  === 'EUR' ? '€' : updatedListing.currencyCode  === 'GBP' ? '£' : '$'}{updatedListing.price.toFixed(2)}
															</p>
															{
																(updatedListing.expirationDate && ["LISTED", "ACTIVE"].includes(updatedListing.status)) ? (
																	<p className="text-base mt-4">
																		Your listing will expire on <strong className={"text-black cursor-pointer dark:text-white font-medium underline"}>{new Date(updatedListing.expirationDate).toDateString()}</strong>
																	</p>
																) :
																(!["LISTED", "ACTIVE"].includes(updatedListing.status)) ? (
																	<p className="text-base mt-4">
																		Listing status: <strong className={"text-red-500 font-medium underline"}>{updatedListing.status}</strong>
																	</p>
																) : null
															}
														</div>

														{/* Important details */}
														<div className="mt-6 space-y-2">
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Brand:</strong> {updatedListing.brand}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>SKU:</strong> {updatedListing.SKU}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong
																	className={"text-black dark:text-white"}>Quantity:</strong> {updatedListing.quantity}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong className={"text-black dark:text-white"}>Color:</strong> {updatedListing.color}
															</p>
															<p className="text-base text-gray-700 dark:text-gray-400">
																<strong
																	className={"text-black dark:text-white"}>Condition:</strong> {updatedListing.condition}
															</p>
															<p className="text-base text-red-700 dark:text-red-400">
																<section className={"flex items-center justify-between"}><span><strong
																	className={"text-black dark:text-white"}>Fees:</strong> {fees.currency} {fees.totalFee} </span>
																	<span> {stockXSellerLevelSelector}</span></section>
															</p>
															<p className="text-base text-green-700 dark:text-green-400">
																<strong className={"text-black dark:text-white"}>Payout after
																	Fees:</strong> {fees.currency} {(updatedListing.price - fees.totalFee).toFixed(2)}
															</p>
														</div>

														{/* Description */}
														<div className="mt-6">
															<h3 className="text-lg font-medium text-black dark:text-white">Description</h3>
															<div
																className="mt-2 space-y-6 text-base whitespace-pre-wrap text-gray-700 dark:text-gray-400">
																{updatedListing.description}
															</div>
														</div>

														{/* Shipping and Tags as collapsible sections */}
														<section aria-labelledby="details-heading" className="mt-12">
															<h2 id="details-heading" className="sr-only">
																Additional details
															</h2>

															<div className="divide-y divide-gray-200 border-t border-b border-gray-200 dark:border-gray-700">

																{/* Sales History */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Sales History & Profit Estimations
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				{/* Sales Table */}
																				{itemSales.length > 0 ? (
																					<div className="overflow-x-auto">
																						<table className="min-w-full text-left text-sm mb-4">
																							<thead>
																							<tr>
																								<th className="p-2 font-semibold">Platform</th>
																								<th className="p-2 font-semibold">Price</th>
																								<th className="p-2 font-semibold">Profit</th>
																							</tr>
																							</thead>
																							<tbody className={"divide-y divide-gray-500"}>
																							{itemSales.map((sr) => (
																								<tr key={sr.id} className="border-b border-gray-200 dark:border-gray-700">
																									<td className="p-2"><img src={marketplaceImages[sr.platform]?.src}
																									                         alt={marketplaceImages[sr.platform]?.alt}
																									                         className="h-6 w-6"/></td>
																									<td className="p-2">${sr.price?.toFixed(2)}</td>
																									<td className="p-2">{(sr.price - (updatedListing.price || 0)).toFixed(2)}</td>
																								</tr>
																							))}
																							</tbody>
																						</table>
																						<div className="flex flex-col space-y-1 text-gray-600 dark:text-gray-300">
																							<div>Total Revenue: <strong>${totalRevenue.toFixed(2)}</strong></div>
																							<div className="text-green-600 dark:text-green-400">
																								Profit: <strong>${profit.toFixed(2)}</strong>
																							</div>
																						</div>
																					</div>
																				) : (
																					<p className="italic text-gray-500 dark:text-gray-400">
																						No sales recorded yet.
																					</p>
																				)}
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Offers */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Offers
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				{/* Best Offers Table */}
																				{itemBestOffers.length > 0 ? (
																					<div className="overflow-x-auto">
																						<table className="min-w-full text-left text-sm">
																							<thead>
																							<tr>
																								<th className="p-2 font-semibold">Platform</th>
																								<th className="p-2 font-semibold">Offer Price</th>
																								<th className="p-2 font-semibold">Status</th>
																							</tr>
																							</thead>
																							<tbody>
																							{itemBestOffers.map((offer) => (
																								<tr
																									key={offer.id}
																									className="border-b border-gray-200 dark:border-gray-700"
																								>
																									<td className="p-2"><img src={marketplaceImages[offer.marketplace]?.src} alt={marketplaceImages[offer.marketplace]?.alt} className="h-6 w-6"/></td>
																									<td className="p-2">${offer.offerPrice?.toFixed(2)}</td>
																									<td className="p-2">{offer.status}</td>
																								</tr>
																							))}
																							</tbody>
																						</table>
																					</div>
																				) : (
																					<p className="italic text-gray-500 dark:text-gray-400">
																						No offers made on this product
																					</p>
																				)}
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Bids */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
								                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
								                          Bids
								                        </span>
																				<span className="ml-6 flex items-center">
								                          {open ? (
									                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
								                          ) : (
									                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
								                          )}
								                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				{/* Bids Table */}
																				{itemBids.length > 0 ? (
																					<div className="overflow-x-auto">
																						<table className="min-w-full text-left text-sm">
																							<thead>
																							<tr>
																								<th className="p-2 font-semibold">Platform</th>
																								<th className="p-2 font-semibold">Bid Amount</th>
																							</tr>
																							</thead>
																							<tbody>
																							{itemBids.map((bid) => (
																								<tr
																									key={bid.id}
																									className="border-b border-gray-200 dark:border-gray-700"
																								>
																									<td className="p-2"><img src={marketplaceImages[bid.marketplace]?.src}
																									                         alt={marketplaceImages[bid.marketplace]?.alt}
																									                         className="h-6 w-6"/></td>
																									<td className="p-2">${bid.bidAmount?.toFixed(2)}</td>
																								</tr>
																							))}
																							</tbody>
																						</table>
																					</div>
																				) : (
																					<p className="italic text-gray-500 dark:text-gray-400">
																						No bids made on this product
																					</p>
																				)}
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Returns */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Returns
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				{/* Returns Table */}
																				{itemReturns.length > 0 ? (
																					<div className="overflow-x-auto">
																						<table className="min-w-full text-left text-sm">
																							<thead>
																							<tr>
																								<th className="p-2 font-semibold">Platform</th>
																								<th className="p-2 font-semibold">Return Status</th>
																								<th className="p-2 font-semibold">Creation Date</th>
																							</tr>
																							</thead>
																							<tbody>
																							{itemReturns.map((ret) => (
																								<tr
																									key={ret.id}
																									className="border-b border-gray-200 dark:border-gray-700"
																								>
																									<td className="p-2"><img src={marketplaceImages[ret.marketplace]?.src}
																									                         alt={marketplaceImages[ret.marketplace]?.alt}
																									                         className="h-6 w-6"/></td>
																									<td className="p-2">{ret.returnStatus}</td>
																									<td className="p-2">{ret.creationDate}</td>
																								</tr>
																							))}
																							</tbody>
																						</table>
																					</div>
																				) : (
																					<p className="italic text-gray-500 dark:text-gray-400">
																						No return information processed
																					</p>
																				)}
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Shipping Information */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Shipping Information
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul>
																					<li>
																						<strong className={"text-black dark:text-white"}>Cost:</strong> {updatedListing.shipping?.cost}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Estimated Delivery Time:</strong>{' '}
																						{updatedListing.shipping?.estimatedDeliveryTime} days
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Method:</strong> {updatedListing.shipping?.method}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Package Weight:</strong>
																						{updatedListing.shipping?.packageWeightMajor}.{updatedListing.shipping?.packageWeightMinor} {updatedListing.shipping?.packageWeightUnit}
																					</li>
																					<li>
																						<strong className={"text-black dark:text-white"}>Package Dimensions:</strong>{' '}
																						{updatedListing.shipping?.packageDimensionWidth} x {' '}
																						{updatedListing.shipping?.packageDimensionHeight} x {' '}
																						{updatedListing.shipping?.packageDimensionHeight} x {' '}
																						{updatedListing.shipping?.packageDimensionUnit} x {' '}
																					</li>
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Sizes */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Sizes
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (<MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>)}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul className="flex flex-wrap gap-2">
																					{updatedListing.sizes.map((size, index) => (
																						<li key={index}
																						    className="bg-lavender text-deepPurple px-2 py-1 rounded">
																							{size.size || "One-Size"}
																						</li>
																					))}
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>

																{/* Tags and Keywords */}
																<Disclosure as="div">
																	{({open}) => (
																		<>
																			<DisclosureButton
																				className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Tags and Keywords
                        </span>
																				<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
																			</DisclosureButton>
																			<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																				<ul className="flex flex-wrap gap-2">
																					{updatedListing.tagsAndKeywords.map((tag) => (
																						<li key={tag} className="bg-gray-200 dark:bg-gray-600 px-2 py-1 rounded">
																							{tag}
																						</li>
																					))}
																				</ul>
																			</DisclosurePanel>
																		</>
																	)}
																</Disclosure>
															</div>
														</section>

														{/* Action Buttons */}
														<div className="mt-6 flex flex-wrap gap-4">
															<a
																href={updatedListing.listingUrl}
																target="_blank"
																rel="noopener noreferrer"
																className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700"
															>
																Show Listing
															</a>
															<button
																onClick={() => setEditMode(true)}
																className="flex items-center justify-center rounded-md border border-transparent bg-gray-400 dark:bg-gray-700 px-4 py-2 text-sm font-medium text-white hover:bg-gray-500 dark:hover:bg-gray-600"
															>
																<PencilIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																Edit
															</button>
															<button
																disabled={deleting}
																onClick={async () => {
																	setDeleting(true);
																	await deleteClick(listing);
																	setShowDialog(false);
																}}
																className="flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700"
															>
																{deleting ? (
																	<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
																	     fill="none"
																	     viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																		        strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor"
																		      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																	</svg>
																) : (
																	<>
																		<TrashIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																		Delete
																	</>
																)}
															</button>
															<button
																onClick={async () => {
																	await markAsSold(listing);
																	setShowDialog(false);
																}}
																className="flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white hover:bg-yellow-700"
															>
																<>
																	<CheckCircleIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																	Mark as Sold
																</>
															</button>
															<button
																onClick={async () => {
																	setDelisting(true);
																	await deListRelistClick(listing);
																	setDelisting(false);
																}}
																className="flex items-center justify-center rounded-md border border-transparent bg-red-800 px-4 py-2 text-sm font-medium text-white hover:bg-red-950"
															>
																{delisting ? (
																	<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
																	     fill="none"
																	     viewBox="0 0 24 24">
																		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																		        strokeWidth="4"></circle>
																		<path className="opacity-75" fill="currentColor"
																		      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																	</svg>
																) : (
																	<>
																		<MinusCircleIcon className="h-5 w-5 mr-2" aria-hidden="true"/>
																		{listing.status === "DELISTED" ? "ReList" : "DeList"}
																	</>
																)}
															</button>
														</div>
													</>
												) : (
													/* Edit Mode Form */
													<form
														onSubmit={(e) => {
															e.preventDefault();
															handleFormSubmit();
														}}
													>
														<div className="space-y-4">
															{title}
															{description}
															{sku}
															{brand}
															{color}
															{seller}
															{currencyCode}
															{condition}
															{quantity}
															{sizes}
															{location}
															{category}
															{pricing}
															{shippingInformation}
															{returnPolicy}
															{tagsAndKeywords}
															<div className="flex space-x-4">
																<button
																	type="submit"
																	disabled={loading}
																	className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white hover:bg-indigo-700"
																>
																	{
																		loading ?
																			<svg className="animate-spin h-5 w-5 text-white"
																			     xmlns="http://www.w3.org/2000/svg" fill="none"
																			     viewBox="0 0 24 24">
																				<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
																				        strokeWidth="4"></circle>
																				<path className="opacity-75" fill="currentColor"
																				      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
																			</svg>
																			: 'Save Changes'
																	}
																</button>
																<button
																	type="button"
																	onClick={() => setEditMode(false)}
																	className="flex items-center justify-center rounded-md border border-transparent bg-gray-300 dark:bg-gray-700 px-4 py-2 text-base font-medium text-gray-900 dark:text-white hover:bg-gray-400 dark:hover:bg-gray-600"
																>
																	Cancel
																</button>
															</div>
														</div>
													</form>
												)}
											</div>
										</div>
									</div>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	)
}
export default observer(ListingItem)
