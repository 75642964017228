
export const productSizes: { [key: string]: {[key: string]: any[]} } = {
	shoes: {
		us: [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15, 16],
		eu: [17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 36, 36.5, 37, 37.5, 38, 38.5, 39, 39.5, 40, 40.5, 41, 41.5, 42, 42.5, 43, 43.5, 44, 44.5, 45, 45.5, 46, 47, 48, 49],
	},
	clothes: {
		us: ['2T', '3T', '4T', '5T', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
		eu: ['2T', '3T', '4T', '5T', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
	},
	hats: {
		us: ['XS', 'S', 'M', 'L', 'XL', 'One Size'],
		eu: ['XS', 'S', 'M', 'L', 'XL', 'One Size'],
	},
	rings: {
		us: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15],
		eu: [4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15],
	},
	gloves: {
		eu: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
		us: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
	},
	bags: {
		eu: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
		us: ['XS', 'S', 'M', 'L', 'XL', 'XXL']
	}
};

export const defaultSizes = [
	// GS sizes (Youth)
	'3.5Y', '4Y', '4.5Y', '5Y', '5.5Y', '6Y', '6.5Y', '7Y',
	// Women's sizes
	'5W', '5.5W', '6W', '6.5W', '7W', '7.5W', '8W', '8.5W', '9W', '9.5W', '10W', '10.5W', '11W', '11.5W', '12W',
	// Men's sizes
	6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 14, 15, 16,
	// Apparel sizes
	'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL', 'One-Size'
];

export const defaultSizesEu = [
	// GS sizes (EU equivalent of US Youth 3.5Y–7Y)
	35.5, 36, 36.5, 37.5, 38, 38.5, 39, 40,
	// Women's sizes (EU equivalent of US Women's 5W–12W)
	35.5, 36, 36.5, 37.5, 38, 38.5, 39, 40, 40.5, 41, 42, 42.5, 43,
	// Men's sizes
	38.5, 39, 40, 40.5, 41, 42, 43, 43.5, 44, 44.5, 45, 45.5, 46, 46.5, 47, 48, 49, 50,
	// Apparel sizes (EU)
	'44 (XS)', '46–48 (S)', '48–50 (M)', '52–54 (L)', '56–58 (XL)', '60–62 (XXL)', '64–66 (XXXL)', 'One-Size'
];


export const statuses: { [key:string]: string } = {
	UNLISTED: 'text-gray-400 bg-gray-400/10',
	PERSONAL: 'text-blue-400 bg-blue-400/10',
	IN_TRANSIT: 'text-yellow-400 bg-yellow-400/10',
	DELIVERED: 'text-green-400 bg-green-400/10',
	HIGH: 'text-green-400 bg-green-400/10',
	CONSIGNED: 'text-purple-400 bg-purple-400/10',
	LISTED: 'text-indigo-400 bg-indigo-400/10',
	ACTIVE: 'text-indigo-400 bg-indigo-400/10',
	SHIPPED: 'text-teal-400 bg-teal-400/10',
	LOW: 'text-red-400 bg-red-400/10',
	NORMAL: 'text-yellow-400 bg-yellow-400/10',
	CANCELLED: 'text-red-400 bg-red-400/10',
	ORDERED: 'text-indigo-400 bg-indigo-400/10',
	PENDING: 'text-yellow-400 bg-yellow-400/10',
	FAILED: 'text-red-500 bg-red-500/10',
	UNRECOGNIZED: 'text-red-400 bg-red-400/10'
};

export function generateUUID(): string {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (crypto.getRandomValues(new Uint8Array(1))[0] & 0x0f) | (c === 'x' ? 0 : 0x8);
		return r.toString(16);
	});
}

// Utility function: Generate a random alphanumeric string
export function generateRandomId(length: number = 10): string {
	const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * chars.length);
		result += chars.charAt(randomIndex);
	}
	return result;
}

export const copyToClipboard = async (text: string): Promise<void> => {
	if (navigator.clipboard && navigator.clipboard.writeText) {
		try {
			await navigator.clipboard.writeText(text);
			console.log("Copied to clipboard");
		} catch (error) {
			console.error("Failed to copy text: ", error);
		}
	} else {
		// Fallback for browsers that do not support the Clipboard API
		const textarea = document.createElement("textarea");
		textarea.value = text;
		textarea.setAttribute("readonly", "");
		textarea.style.position = "absolute";
		textarea.style.left = "-9999px";
		document.body.appendChild(textarea);
		textarea.select();
		try {
			document.execCommand("copy");
			console.log("Copied to clipboard");
		} catch (error) {
			console.error("Failed to copy text: ", error);
		}
		document.body.removeChild(textarea);
	}
};
