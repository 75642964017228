import { makeAutoObservable } from "mobx";
import { Bid } from "app-ts-types/bids";
import { MarketplacePlatform } from "app-ts-types/platforms";

// --- Sample Test Data for Bids ---
export const sampleBids: Bid[] = [
	{
		id: "bid1",
		user: "user1",
		marketplace: 0,
		sku: "SKU-001",
		inventoryId: "INV-001",
		listingId: "LIST-001",
		eventType: "BidReceived",
		timestamp: "2023-01-01T12:00:00Z",
		recipientId: "recipient1",
		itemId: "ITEM-001",
		title: "Cool Sneakers",
		listingStatus: "Active",
		minBid: 50,
		currency: "USD",
		viewItemUrl: "http://example.com/item1",
		startPrice: 45,
		bidderId: "bidder1",
		bidderFeedbackScore: 100,
		bidCurrency: "USD",
		bidAmount: 55,
	},
	{
		id: "bid2",
		user: "user2",
		marketplace: 0,
		sku: "SKU-002",
		inventoryId: "INV-002",
		listingId: "LIST-002",
		eventType: "BidReceived",
		timestamp: "2023-01-02T12:00:00Z",
		recipientId: "recipient2",
		itemId: "ITEM-002",
		title: "Stylish Sneakers",
		listingStatus: "Active",
		minBid: 60,
		currency: "USD",
		viewItemUrl: "http://example.com/item2",
		startPrice: 55,
		bidderId: "bidder2",
		bidderFeedbackScore: 200,
		bidCurrency: "USD",
		bidAmount: 65,
	},
];

class BidsStore {
	bids: Bid[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "ASC" };
	currentPage: number = 1;
	itemsPerPage: number = 100;
	selectedPlatform: MarketplacePlatform | "" = "";

	constructor() {
		makeAutoObservable(this);
	}

	setBids(bids: Bid[]) {
		this.bids = bids;
	}

	addBids(newItems: Bid[]) {
		newItems.forEach(newItem => {
			const index = this.bids.findIndex(item => item.id === newItem.id);
			if (index !== -1) {
				this.bids[index] = newItem;
			} else {
				this.bids.push(newItem);
			}
		});
	}

	updateBid(updatedItem: Bid) {
		const index = this.bids.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.bids[index] = updatedItem;
		}
	}

	deleteBid(itemId: string) {
		this.bids = this.bids.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	setSelectedPlatform(platform: MarketplacePlatform | "") {
		this.selectedPlatform = platform;
	}

	get filteredAndSortedBids(): Bid[] {
		let filteredBids = this.bids;

		if (this.filter) {
			// Filtering by bid title (or adjust to other bid fields as needed)
			filteredBids = filteredBids.filter(item =>
				item.title.toLowerCase().includes(this.filter.toLowerCase())
			);
		}

		if (this.selectedPlatform !== "") {
			filteredBids = filteredBids.filter(item =>
				item.marketplace === this.selectedPlatform
			);
		}

		if (this.sortOption.field) {
			filteredBids = filteredBids.slice().sort((a, b) => {
				if (this.sortOption.direction === "ASC") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}
		return filteredBids;
	}

	get paginatedBids(): Bid[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedBids.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedBids.length / this.itemsPerPage);
	}
}

export const bidsStore = new BidsStore();
