'use client'

import React, {useEffect, useState} from 'react'
import {
	Dialog,
	DialogBackdrop,
	DialogPanel,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
	TransitionChild
} from '@headlessui/react'
import {MinusIcon, PlusIcon, XMarkIcon, ArrowTrendingUpIcon, CheckCircleIcon} from '@heroicons/react/24/outline'
import {
	categoriesToJSON,
	Inventory, Listing,
	statusesToJSON,
	stockLevelToJSON
} from "../utils/types";
import {QuantitySizing} from "app-ts-types/generic";
import ListingImage from "./Image";
import {statuses} from "../utils/data";
import ListingImageCloud, {marketplaceImages} from "./ListingImageCloud";
import {StockLevelAlert} from "./StockAlert";
import {CurrencyDollarIcon} from "@heroicons/react/20/solid";
import {listingsStore} from "../state/Listing";
import {salesRecordsStore} from "../state/SalesRecord";
import {bidsStore} from "../state/Bids";
import {bestOffersStore} from "../state/BestOffer";
import {returnsStore} from "../state/returns";
import {getBestOffers} from "../api/best-offer";
import {getBids} from "../api/bids";
import {getReturns} from "../api/returns";
import {getSalesRecordsByFilter} from "../api/sales";
import {observer} from "mobx-react-lite";
import {refreshInventoryStats} from "../api/inventories";
import {inventoryStore} from "../state/Inventory";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

export function getListingPrice(listings: Listing[], inventoryId: string): string {
	const filteredListings = listings.filter(listing => listing.inventoryId === inventoryId);
	if (filteredListings.length === 0) {
		return "";
	}
	const prices = filteredListings.map(listing => listing.price);
	if (prices.length === 1) {
		return prices[0].toString();
	}
	return `${Math.min(...prices)} -> ${Math.max(...prices)}`;
}


export type PriceResult = number | { minPrice: number; maxPrice: number };
export function getListingPriceNumber(listings: Listing[], inventoryId: string): PriceResult | null {
	const filteredListings = listings.filter(listing => listing.inventoryId === inventoryId);
	if (filteredListings.length === 0) {
		return null;
	}
	const prices = filteredListings.map(listing => listing.price);
	if (prices.length === 1) {
		return prices[0];
	}
	return {
		minPrice: Math.min(...prices),
		maxPrice: Math.max(...prices),
	};
}

export function getPotentialProfit(listings: Listing[], inventoryId: string, inventoryPrice: number): string {
	const priceResult = getListingPriceNumber(listings, inventoryId);
	if (priceResult === null) {
		return "";
	}
	if (typeof priceResult === "number") {
		return (priceResult - inventoryPrice).toFixed(2).toString();
	}
	return `${(priceResult.minPrice - inventoryPrice).toFixed(2)} -> ${(priceResult.maxPrice - inventoryPrice).toFixed(2)}`;
}

export function InventoryItem({
	                                      inventory,
	                                      onClose,
	                                      editClick,
	                                      deleteClick,
	                                      del,
	                                      handleCreateListing,
	                                      showListingDisplay,
																				markAsSold,
                                      }: {
	inventory: Inventory;
	del?: boolean;
	onClose: () => void;
	editClick: (inventory: Inventory) => void;
	deleteClick: (inventory: Inventory) => void;
	markAsSold: (inventory: Inventory) => void;
	handleCreateListing: (inventory: Inventory) => void;
	showListingDisplay?: (listing: Listing) => void;
}) {
	const [open, setOpen] = useState(true);
	const [deleting, setDeleting] = useState<boolean>(del || false);

	useEffect(() => {
		getBestOffers("inventoryId", inventory.id).then((res:any) => {
			if(!res.isError) { bestOffersStore.addBestOffers(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);});
		getBids("inventoryId", inventory.id).then((res:any) => {
			if(!res.isError) { bidsStore.addBids(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);});
		getReturns("inventoryId", inventory.id).then((res:any) => {
			if(!res.isError) { returnsStore.addReturns(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);});
		getSalesRecordsByFilter("inventoryId", inventory.id).then((res:any) => {
			if(!res.isError) { salesRecordsStore.addSalesRecords(res.data) } else {console.log(res);}
		}).catch((err:any) => {console.log(err);})
		refreshInventoryStats(inventory.id)
			.then((res:any) => {
				console.log(res);
				if(res.data) { inventoryStore.updateInventory(res.data); }
				if(res.listings && res.listings.length) { listingsStore.updateListings(res.listings); }
				if (res.bids && res.bids.length) { bidsStore.addBids(res.bids); }
				if (res.offers && res.offers.length) { bestOffersStore.addBestOffers(res.offers); }
				if (res.returns && res.returns.length) { returnsStore.addReturns(res.returns); }
				if (res.sales && res.sales.length) { salesRecordsStore.addSalesRecords(res.sales); }
			})
			.catch((err:any) => {
				console.log(err);
			})
	}, [])

	// 1) Filter each store by this listingId
	const itemSales = salesRecordsStore.salesRecords.filter((sr) => sr.inventoryId === inventory.id);
	const itemBids = bidsStore.bids.filter((bid) => bid.inventoryId === inventory.id);
	const itemBestOffers = bestOffersStore.bestOffers.filter((offer) => offer.inventoryId === inventory.id);
	const itemReturns = returnsStore.returns.filter((ret) => ret.inventoryId === inventory.id);

	// 2) Simple profit calculation: sum of all sales minus cost/fees
	const totalRevenue = itemSales.reduce((sum, sr) => sum + (sr.price || 0), 0);
	const profit = totalRevenue - (inventory.purchaseDetails?.price || 0);

	return (
		<Dialog open={open} onClose={onClose} className="relative z-[100]">
			<DialogBackdrop
				transition
				className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
			/>

			<div className="fixed inset-0 overflow-hidden">
				<div className="absolute inset-0 overflow-hidden">
					<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
						<DialogPanel
							transition
							className="pointer-events-auto relative w-[30rem] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
						>
							<TransitionChild>
								<div
									className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 duration-500 ease-in-out data-[closed]:opacity-0 sm:-ml-10 sm:pr-4">
									<button
										type="button"
										onClick={() => {
											setOpen(false);
											onClose();
										}}
										className="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
									>
										<span className="absolute -inset-2.5"/>
										<span className="sr-only">Close panel</span>
										<XMarkIcon aria-hidden="true" className="h-6 w-6"/>
									</button>
								</div>
							</TransitionChild>
							<div className="h-full overflow-y-auto bg-white dark:bg-gray-900 p-8">
								<div className="space-y-6 pb-16">
									<StockLevelAlert totalQuantity={inventory.totalQuantity}/>
									<div>
										<div className="aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg">
											<ListingImage
												imageUrl={inventory.imageUrl}
												title={inventory.name + "\n(" + inventory.SKU + ")"}
												className="object-cover"
											/>
										</div>
										<div className="mt-4 flex items-start justify-between">
											<div>
												<h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-100">
													<span className="sr-only">Details for </span>{inventory.name}
												</h2>
												{/*@ts-ignore*/}
												<p className="text-sm font-medium text-gray-500">{inventory.currencyCode  === 'USD' ? '$' : inventory.currencyCode  === 'EUR' ? '€' : inventory.currencyCode  === 'GBP' ? '£' : '$'}{inventory.purchaseDetails!.price.toFixed(2)}</p>
											</div>
										</div>
									</div>
									<div>
										<h3 className="font-medium text-gray-900 dark:text-gray-100">Information</h3>
										<dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">SKU</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.SKU}</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Listed Pricing</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<span className={"flex items-center space-x-4"}> <CurrencyDollarIcon
														className={"w-4 h-4 text-amber-500"}/> &nbsp; {getListingPrice(listingsStore.listings, inventory.id)}</span>
												</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Potential Earnings</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<span className={"flex items-center space-x-4"}> <ArrowTrendingUpIcon
														className={"w-4 h-4 text-green-500"}/> &nbsp; {getPotentialProfit(listingsStore.listings, inventory.id, inventory.purchaseDetails?.price || 0)}</span>
												</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Quantity</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.totalQuantity}</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Description</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{inventory.description || "No Description"}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Colorway</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.color}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Brand</dt>
												<dd className="text-gray-900 dark:text-gray-100">{inventory.brand}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Purchase Place</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{inventory.purchaseDetails?.purchasePlace || "N/A"}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Purchase Date</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{inventory.purchaseDetails?.purchaseDate || "N/A"}</dd>
											</div>
											<div className="flex justify-between space-x-4 py-3 text-sm font-medium">
												<dt className="text-gray-500">Collection</dt>
												<dd
													className="text-gray-900 text-right text-sm dark:text-gray-100">{inventory.collectionId || "N/A"}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Category</dt>
												<dd className="text-gray-900 dark:text-gray-100">{categoriesToJSON(inventory.category).replaceAll("_", " ")}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Created</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{new Date(inventory.createdAt / 1000000).toDateString()}</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Last modified</dt>
												<dd
													className="text-gray-900 dark:text-gray-100">{new Date(inventory.updatedAt).toDateString()}</dd>
											</div>
											<div className="flex justify-between space-x-8 py-3 text-sm font-medium">
												<dt className="text-gray-500">Sizes</dt>
												<dd className="text-gray-900 dark:text-gray-100"> {inventory.sizes.map((size: QuantitySizing) =>
													<button
														type="button"
														className="ml-2 inline-flex p-2 items-center justify-center rounded bg-lavender text-deepPurple hover:bg-brightPurple focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
													>
														<span className="sr-only">{size.size}</span>
														<span className={"text-xs"}>{size.size || "One-Size"}</span>
													</button>)} </dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Status</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<div
														className={classNames(statuses[statusesToJSON(inventory.status)], 'flex-none rounded cursor-pointer px-2 py-1')}>
														{statusesToJSON(inventory.status)}
													</div>
												</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Stock Level</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<div
														className={classNames(statuses[stockLevelToJSON(inventory.stockLevel)], 'flex-none rounded cursor-pointer px-2 py-1')}>
														{stockLevelToJSON(inventory.stockLevel)}
													</div>
												</dd>
											</div>
											<div className="flex justify-between py-3 text-sm font-medium">
												<dt className="text-gray-500">Listed On</dt>
												<dd className="text-gray-900 dark:text-gray-100">
													<ListingImageCloud showListingDisplay={showListingDisplay} inventoryId={inventory.id}
													                   marketplaces={inventory.platform}/>
												</dd>
											</div>
										</dl>
										<section aria-labelledby="details-heading" className="mt-8">
											<h2 id="details-heading" className={"dark:text-white text-lg font-medium mb-2"}>
												Sales Information
											</h2>

											<div
												className="divide-y divide-gray-200 border-t border-b border-gray-200 dark:border-gray-700">
												{/* Sales History */}
												<Disclosure as="div">
													{({open}) => (
														<>
															<DisclosureButton
																className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Sales History & Profit Estimations
                        </span>
																<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
															</DisclosureButton>
															<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																{/* Sales Table */}
																{itemSales.length > 0 ? (
																	<div className="overflow-x-auto">
																		<table className="min-w-full text-left text-sm mb-4">
																			<thead>
																			<tr>
																				<th className="p-2 font-semibold">Platform</th>
																				<th className="p-2 font-semibold">Price</th>
																				<th className="p-2 font-semibold">Profit</th>
																			</tr>
																			</thead>
																			<tbody className={"divide-y divide-gray-500"}>
																			{itemSales.map((sr) => (
																				<tr key={sr.id} className="border-b border-gray-200 dark:border-gray-700">
																					<td className="p-2"><img src={marketplaceImages[sr.platform]?.src}
																					                         alt={marketplaceImages[sr.platform]?.alt}
																					                         className="h-6 w-6"/></td>
																					<td className="p-2">${sr.price?.toFixed(2)}</td>
																					<td className="p-2">{(sr.price - (inventory.purchaseDetails?.price || 0)).toFixed(2)}</td>
																				</tr>
																			))}
																			</tbody>
																		</table>
																		<div className="flex flex-col space-y-1 text-gray-600 dark:text-gray-300">
																			<div>Total Revenue: <strong>${totalRevenue.toFixed(2)}</strong></div>
																			<div className="text-green-600 dark:text-green-400">
																				Profit: <strong>${profit.toFixed(2)}</strong>
																			</div>
																		</div>
																	</div>
																) : (
																	<p className="italic text-gray-500 dark:text-gray-400">
																		No sales recorded yet.
																	</p>
																)}
															</DisclosurePanel>
														</>
													)}
												</Disclosure>

												{/* Offers */}
												<Disclosure as="div">
													{({open}) => (
														<>
															<DisclosureButton
																className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Offers
                        </span>
																<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
															</DisclosureButton>
															<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																{/* Best Offers Table */}
																{itemBestOffers.length > 0 ? (
																	<div className="overflow-x-auto">
																		<table className="min-w-full text-left text-sm">
																			<thead>
																			<tr>
																				<th className="p-2 font-semibold">Platform</th>
																				<th className="p-2 font-semibold">Offer Price</th>
																				<th className="p-2 font-semibold">Status</th>
																			</tr>
																			</thead>
																			<tbody>
																			{itemBestOffers.map((offer) => (
																				<tr
																					key={offer.id}
																					className="border-b border-gray-200 dark:border-gray-700"
																				>
																					<td className="p-2"><img src={marketplaceImages[offer.marketplace]?.src} alt={marketplaceImages[offer.marketplace]?.alt} className="h-6 w-6"/></td>
																					<td className="p-2">${offer.offerPrice?.toFixed(2)}</td>
																					<td className="p-2">{offer.status}</td>
																				</tr>
																			))}
																			</tbody>
																		</table>
																	</div>
																) : (
																	<p className="italic text-gray-500 dark:text-gray-400">
																		No offers made on this product
																	</p>
																)}
															</DisclosurePanel>
														</>
													)}
												</Disclosure>

												{/* Bids */}
												<Disclosure as="div">
													{({open}) => (
														<>
															<DisclosureButton
																className="group relative flex w-full items-center justify-between py-6 text-left">
								                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
								                          Bids
								                        </span>
																<span className="ml-6 flex items-center">
								                          {open ? (
									                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
								                          ) : (
									                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
								                          )}
								                        </span>
															</DisclosureButton>
															<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																{/* Bids Table */}
																{itemBids.length > 0 ? (
																	<div className="overflow-x-auto">
																		<table className="min-w-full text-left text-sm">
																			<thead>
																			<tr>
																				<th className="p-2 font-semibold">Platform</th>
																				<th className="p-2 font-semibold">Bid Amount</th>
																			</tr>
																			</thead>
																			<tbody>
																			{itemBids.map((bid) => (
																				<tr
																					key={bid.id}
																					className="border-b border-gray-200 dark:border-gray-700"
																				>
																					<td className="p-2"><img src={marketplaceImages[bid.marketplace]?.src}
																					                         alt={marketplaceImages[bid.marketplace]?.alt}
																					                         className="h-6 w-6"/></td>
																					<td className="p-2">${bid.bidAmount?.toFixed(2)}</td>
																				</tr>
																			))}
																			</tbody>
																		</table>
																	</div>
																) : (
																	<p className="italic text-gray-500 dark:text-gray-400">
																		No bids made on this product
																	</p>
																)}
															</DisclosurePanel>
														</>
													)}
												</Disclosure>

												{/* Returns */}
												<Disclosure as="div">
													{({open}) => (
														<>
															<DisclosureButton
																className="group relative flex w-full items-center justify-between py-6 text-left">
                        <span className="text-sm font-medium text-gray-900 dark:text-gray-200">
                          Returns
                        </span>
																<span className="ml-6 flex items-center">
                          {open ? (
	                          <MinusIcon className="h-5 w-5 text-indigo-500" aria-hidden="true"/>
                          ) : (
	                          <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                          )}
                        </span>
															</DisclosureButton>
															<DisclosurePanel className="pb-6 text-sm text-gray-500 dark:text-gray-300">
																{/* Returns Table */}
																{itemReturns.length > 0 ? (
																	<div className="overflow-x-auto">
																		<table className="min-w-full text-left text-sm">
																			<thead>
																			<tr>
																				<th className="p-2 font-semibold">Platform</th>
																				<th className="p-2 font-semibold">Return Status</th>
																				<th className="p-2 font-semibold">Creation Date</th>
																			</tr>
																			</thead>
																			<tbody>
																			{itemReturns.map((ret) => (
																				<tr
																					key={ret.id}
																					className="border-b border-gray-200 dark:border-gray-700"
																				>
																					<td className="p-2"><img src={marketplaceImages[ret.marketplace]?.src}
																					                         alt={marketplaceImages[ret.marketplace]?.alt}
																					                         className="h-6 w-6"/></td>
																					<td className="p-2">{ret.returnStatus}</td>
																					<td className="p-2">{ret.creationDate}</td>
																				</tr>
																			))}
																			</tbody>
																		</table>
																	</div>
																) : (
																	<p className="italic text-gray-500 dark:text-gray-400">
																		No return information processed
																	</p>
																)}
															</DisclosurePanel>
														</>
													)}
												</Disclosure>
											</div>
										</section>
									</div>
									<div className="flex flex-wrap gap-4">
										<button
											type="button"
											onClick={() => {
												setOpen(false);
												editClick(inventory);
											}}
											className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										>
											Edit
										</button>
										<button
											type="button"
											disabled={deleting}
											onClick={async () => {
												setDeleting(true);
												await deleteClick(inventory);
												setOpen(false)
											}}
											className="flex-1 rounded-md text-white !text-center bg-red-600 px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500"
										>
											{deleting ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
												     fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Delete'
											)}
										</button>
										<button
											onClick={async () => {
												await markAsSold(inventory);
												setOpen(false);
											}}
											className="flex items-center justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white hover:bg-yellow-700"
										>
											<>
												Mark as Sold
											</>
										</button>
										<button
											type="button"
											onClick={() => {
												setOpen(false);
												handleCreateListing(inventory);
											}}
											className="flex-1 rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
										>
											List
										</button>
									</div>
								</div>
							</div>
						</DialogPanel>
					</div>
				</div>
			</div>
		</Dialog>
	)
}

export default observer(InventoryItem);
