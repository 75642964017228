import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {ChevronDownIcon, EllipsisHorizontalIcon, MagnifyingGlassIcon} from "@heroicons/react/20/solid";
import {inventoryStore} from "./state/Inventory";
import logo from "./images/IconOnly_Transparent_NoBuffer.png";
import {MoonIcon, SunIcon, UserCircleIcon} from "@heroicons/react/16/solid";
import EmptyComponent from "./components/Empty";
import noMessagesImage from "./images/share02-removebg-preview.png";
import Loader from "./components/Loading";
import noInventoryImage from "./images/no-inventory-image.png";
import axiosInstance from "./api/server";
import {GetInventoriesResponse} from "app-ts-types/inventories";
import {
	Categories,
	Inventory as InventoryType,
	Statuses,
	statusesToJSON,
	StockLevel,
	stockLevelToJSON
} from "./utils/types";
import ListingImage from "./components/Image";
import ListingImageCloud from "./components/ListingImageCloud";
import Tooltip from "./components/Tooltip";
import {statuses} from "./utils/data";
import {Menu, Transition} from "@headlessui/react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import {SortableHeader} from "./pages/Inventory";
import {observer} from "mobx-react-lite";
import {getInventories} from "./api/inventories";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}
const PublicPage: React.FC = () => {
	const {id} = useParams<{ id?: string }>();
	const navigate = useNavigate();
	const [loadInventories, setLoadInventories] = useState(false);
	const [fetchingNextPage, setFetchingNextPage] = useState(false);
	const [isDark, setIsDark] = useState<boolean>(localStorage.getItem("darkMode") === "true");

	useEffect(() => {
		if (!id) return;
		setLoadInventories(true);
		getInventories()
			.then((res: any) => {
				if (!res.isError) {
					inventoryStore.setInventory(res.data)
				}
				setLoadInventories(false);
			})
			.catch((err: any) => {
				console.log(err);
				setLoadInventories(false);
			})
		// before this page goes off, remove an inventory in here.
		return () => { inventoryStore.setInventory([]); }
	}, []);

	useEffect(() => {
		// On connect, check if dark theme is stored in localStorage and enable it accordingly.
		const darkModeEnabled = localStorage.getItem("darkMode") === "true";
		if (darkModeEnabled) {
			document.documentElement.classList.add("dark");
			setIsDark(true);
		} else {
			document.documentElement.classList.remove("dark");
			setIsDark(false);
		}
	}, []);

	const getInventories = async (skip=0, limit=1000) => {
		// limit=int(request.query_params.get('limit', 100)),
		// 	skip=int(request.query_params.get('skip', 0)),
		// 	sort_order=request.query_params.get('sort_order', 'desc'),
		// 	sort_by=request.query_params.get('sort_by', 'createdAt')
		const response = await axiosInstance.get<GetInventoriesResponse>(`/public/share?shareKey=${id}&skip=${skip}&limit=${limit}`);
		return response.data;
	};

	const toggleTheme = () => {
		if (isDark) {
			document.documentElement.classList.remove("dark");
			localStorage.removeItem("darkMode");
			setIsDark(false);
		} else {
			document.documentElement.classList.add("dark");
			localStorage.setItem("darkMode", "true");
			setIsDark(true);
		}
	};

	const loginToApp = async () => {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refresh_token");
		if (!token || !refreshToken) {
			window.location.href = "https://www.tracknlist.com/login";
		} else {
			// Token exists, navigate to home route
			navigate("/");
		}
	}

	const handleSearch = (event: any) => {
		event.preventDefault();
		const query = event.target.value;
		inventoryStore.setFilter(query);
	};

	const handleSort = (field: string) => {
		if (inventoryStore.sortOption.field === field) {
			inventoryStore.setSortOption({
				...inventoryStore.sortOption,
				direction: inventoryStore.sortOption.direction === 'asc' ? 'desc' : 'asc'
			});
		} else {
			inventoryStore.setSortOption({field, direction: "asc"});
		}
	};

	const handlePrevPage = () => {
		if (inventoryStore.currentPage > 1) {
			inventoryStore.setCurrentPage(inventoryStore.currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (inventoryStore.currentPage < inventoryStore.totalPages) {
			inventoryStore.setCurrentPage(inventoryStore.currentPage + 1);
			return;
		}
		setFetchingNextPage(true);
		getInventories(inventoryStore.inventory.length)
			.then((res: any) => {
				if (!res.isError && res.data.length) {
					inventoryStore.loadNewInventories(res.data);
					inventoryStore.setCurrentPage(inventoryStore.currentPage + 1);
				}
				setFetchingNextPage(false);
			})
			.catch((err: any) => {
				console.log(err);
				setFetchingNextPage(false);
			})
			.finally(() => {
				setFetchingNextPage(false);
			})
	};

	let component;

	if (inventoryStore.inventory.length === 0) {
		component = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<EmptyComponent
					imageSrc={noInventoryImage}
					headerText={"No Inventory"}
					descriptionText={"Shared Link has no inventory!"}
				/>
			</div>
		);
	}

	if (loadInventories) {
		component = (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	if(!id) {
		component =  <div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900 p-4">
			<EmptyComponent
				imageSrc={noMessagesImage}
				headerText={"No Shared Inventory key"}
				descriptionText={"No shared inventory key provided. Please verify the link or contact support."}
			/>
		</div>
	}

	if(inventoryStore.inventory.length) {
		component = (
			<div className="px-4 sm:px-6 lg:px-8">
				<div className="mt-8 flow-root">
					<div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full min-h-[80vh] py-2 align-middle sm:px-6 lg:px-8">
							<div className="relative flex justify-between w-full">
								<div>
								</div>
								<div className="flex w-[80%] items-center justify-end space-x-8 mb-4">
									<div className="flex items-center space-x-4">
										{/*@ts-ignore*/}
										<select onChange={(e) => {inventoryStore.setSelectedCategory(e.target.value)}}
										        value={inventoryStore.selectedCategory}
										        className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
										>
											<option value="">Categories: All</option>
											{Object.keys(Categories).filter(key => isNaN(Number(key))).map((key) => (
												<option key={key} value={Categories[key as keyof typeof Categories]}>
													{key}
												</option>
											))}
										</select>
									</div>
									<button
										type="button"
										onClick={() => {
											inventoryStore.setSelectedStatus('');
											inventoryStore.setSelectedPlatform('');
											inventoryStore.setSelectedCategory('');
											inventoryStore.setSelectedStockLevel('');
											inventoryStore.setSelectedCollection('');
										}}
										className="block rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
									>
										Clear
									</button>
								</div>
							</div>
							<div className="relative">
								<table className="min-w-full table-fixed divide-y divide-gray-300 dark:divide-gray-700">
									<thead>
									<tr>
										<SortableHeader
											field="name"
											label="Name"
											sortField={inventoryStore.sortOption.field}
											sortOrder={inventoryStore.sortOption.direction}
											onSort={handleSort}
										/>
										<SortableHeader
											field="SKU"
											label="SKU"
											sortField={inventoryStore.sortOption.field}
											sortOrder={inventoryStore.sortOption.direction}
											onSort={handleSort}
										/>
										{/*<SortableHeader*/}
										{/*	field="purchaseDetails.price"*/}
										{/*	label="Price"*/}
										{/*	sortField={inventoryStore.sortOption.field}*/}
										{/*	sortOrder={inventoryStore.sortOption.direction}*/}
										{/*	onSort={handleSort}*/}
										{/*/>*/}
										<SortableHeader
											field="totalQuantity"
											label="Quantity"
											sortField={inventoryStore.sortOption.field}
											sortOrder={inventoryStore.sortOption.direction}
											onSort={handleSort}
										/>
										<th scope="col" className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter">
											Sizes
										</th>
										<SortableHeader
											field="color"
											label="Color"
											sortField={inventoryStore.sortOption.field}
											sortOrder={inventoryStore.sortOption.direction}
											onSort={handleSort}
										/>
										{/*<SortableHeader*/}
										{/*	field="purchaseDetails.purchaseDate"*/}
										{/*	label="Available Since"*/}
										{/*	sortField={inventoryStore.sortOption.field}*/}
										{/*	sortOrder={inventoryStore.sortOption.direction}*/}
										{/*	onSort={handleSort}*/}
										{/*/>*/}
									</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 dark:divide-gray-800 bg-white dark:bg-gray-900">
									{inventoryStore.paginatedInventory.map((item: InventoryType) => (
										// @ts-ignore
										<tr key={item.id + "-" + item.imageUrl + "-" + item.platform.length} className={'cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800'}>
											{/*// @ts-ignore*/}
											<td className={classNames('whitespace-nowrap py-4 pr-3 text-sm font-medium', 'text-gray-900 dark:text-white')}>
												<div className="flex items-center gap-x-4">
													<ListingImage title={item.SKU} imageUrl={item.imageUrl}/>
													<div
														className="truncate whitespace-normal break-words text-sm text-black dark:text-white font-medium leading-6">{item.name}</div>
												</div>
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.SKU}</td>
											{/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">${item.purchaseDetails!.price.toFixed(2)}</td>*/}
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.totalQuantity}</td>
											<td>
												<ul className="flex flex-wrap gap-1">
													{item.sizes.map((size, index) => (
														<li key={index}
														    className="bg-brightPurple text-sm text-white px-2 py-1 rounded">
															{size.size || "One-Size"}
														</li>
													))}
												</ul>
											</td>
											<td className="text-wrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.color}</td>
											{/*<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.purchaseDetails?.purchaseDate}</td>*/}
										</tr>
									))}
									</tbody>
								</table>
							</div>
							<div className="flex items-center justify-center space-x-4 mt-4">
								<button
									onClick={handlePrevPage}
									disabled={inventoryStore.currentPage === 1}
									className={`p-2 cursor-pointer rounded-full ${inventoryStore.currentPage === 1 ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
								>
									<ChevronLeftIcon className="h-6 w-6"/>
								</button>
								<span className="text-gray-900 dark:text-gray-100">
				        Page {inventoryStore.currentPage} of {inventoryStore.totalPages || inventoryStore.currentPage}
				      </span>
								<button
									onClick={handleNextPage}
									// disabled={inventoryStore.currentPage === inventoryStore.totalPages}
									className={`p-2 cursor-pointer rounded-full ${inventoryStore.currentPage === inventoryStore.totalPages ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
								>
									{
										fetchingNextPage ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
											     fill="none" viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											<ChevronRightIcon className="h-6 w-6"/>
										)
									}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div id={"app"} className="h-full bg-white dark:bg-gray-900">
			<div>
				<div
					className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-300 dark:border-slate-600 bg-white dark:bg-gray-900 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
					<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
						<div className="flex h-16 shrink-0 items-center">
							<img
								alt="Track and List"
								src={logo}
								className="h-8 w-auto grayscale"
							/>
						</div>
						<div className="relative self-center px-2 flex flex-1 h-8 border border-gray-300 dark:border-gray-600 rounded">
							<label htmlFor="search-field" className="sr-only">
								Search
							</label>
							<MagnifyingGlassIcon
								aria-hidden="true"
								className="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-gray-400 dark:text-gray-500"
							/>
							<input
								id="search-field"
								name="search"
								type="search"
								// value={inventoryStore.filter}
								placeholder="Search..."
								className="block outline-none focus:outline-none h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 dark:bg-gray-900 dark:text-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm"
								onChange={handleSearch}
							/>
						</div>
						<div className="flex items-center">
							<button onClick={toggleTheme} className="p-2">
								{isDark ? (
									<SunIcon className="h-6 w-6 text-yellow-400"/>
								) : (
									<MoonIcon className="h-6 w-6 text-gray-500"/>
								)}
							</button>
							<button
								onClick={loginToApp}
								className="flex items-center space-x-1 p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded"
							>
								<UserCircleIcon className="h-6 w-6 text-gray-700 dark:text-gray-300"/>
								<span className="text-gray-700 font-medium dark:text-gray-300">{localStorage.getItem("token") ? "Go to App" : "Sign In"}</span>
							</button>
						</div>
					</div>
				</div>
				<main className={"min-h-screen"}>
					{component}
				</main>
			</div>
		</div>
	);
};
export default observer(PublicPage);
