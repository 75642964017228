import React, { useState, useEffect } from "react";
import {
	ShippingTemplate,
	ShippingPolicyDetail,
	ShippingMethodType,
	MarketplacePlatform
} from "../utils/types";
import axiosInstance from "../api/server";

// The user either pays or the seller pays (which implies free shipping).
export type ShippingPayer = "Buyer" | "Seller";

// If costType is relevant, only "Flat" or "Calculated" are possible now.
export type CostType = "Flat" | "Calculated"; // "" means not chosen yet

// For multi-select checkboxes or <select multiple> we might store pairs:
export const ALL_PLATFORMS = [
	{ label: "eBay", value: MarketplacePlatform.EBAY },
	{ label: "Grailed", value: MarketplacePlatform.GRAILED },
	{ label: "Mercari", value: MarketplacePlatform.MERCARI },
	{ label: "Shopify", value: MarketplacePlatform.SHOPIFY },
	{ label: "StockX", value: MarketplacePlatform.STOCKX },
	{ label: "Poshmark", value: MarketplacePlatform.POSHMARK },
	{ label: "GOAT", value: MarketplacePlatform.GOAT },
	{ label: "Amazon", value: MarketplacePlatform.AMAZON },
	{ label: "Etsy", value: MarketplacePlatform.ETSY },
	{ label: "Depop", value: MarketplacePlatform.DEPOP },
	{ label: "Facebook", value: MarketplacePlatform.FACEBOOK },
	{ label: "Poizon", value: MarketplacePlatform.POIZON },
];

// Some sample shipping services (fetched externally).
// In real code, you'd likely pass these as props or from a context.
type ShippingServiceDetails = {
	ShippingServiceID: string;
	ShippingService: string;
	Description: string;
	ShippingCategory: "STANDARD" | "EXPEDITED" | "ECONOMY" | "ONE_DAY" | "FREIGHT" | string; // Add more if needed
	ExpeditedService: boolean;
	InternationalService: boolean;
	ShippingTimeMin: string;
	ShippingTimeMax: string;
	SurchargeApplicable: boolean;
	WeightRequired: boolean;
	ValidForSellingFlow: boolean;
	UpdateTime: string; // Consider using Date if you plan to parse it
};

export const ShippingDimensionsComponent = function ({handleShippingChange, shipping}:{handleShippingChange:(e:any) => void, shipping: any}) {
	return <>
		{/* Weight major/minor + unit */}
		<div className="mt-4">
			<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
				Package Weight
			</label>
			<div
				className="mt-2 flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0">
				<div className="md:w-1/3 w-full">
					{/* "Major" portion: e.g. pounds or kilograms */}
					<label
						htmlFor="packageWeightMajor"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Major
					</label>
					<input
						id="packageWeightMajor"
						name="packageWeightMajor"
						type="number"
						value={shipping?.packageWeightMajor}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>

				<div className="md:w-1/3 w-full">
					{/* "Minor" portion: e.g. ounces or grams */}
					<label
						htmlFor="packageWeightMinor"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Minor
					</label>
					<input
						id="packageWeightMinor"
						name="packageWeightMinor"
						type="number"
						value={shipping?.packageWeightMinor}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>

				<div className="md:w-1/3 w-full">
					{/* Weight unit selector: e.g. "lbs", "kg", etc. */}
					<label
						htmlFor="packageWeightUnit"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Weight Unit
					</label>
					<select
						id="packageWeightUnit"
						name="packageWeightUnit"
						value={shipping?.packageWeightUnit}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					>
						<option value="">Select Unit</option>
						<option value="lbs">lbs</option>
						<option value="oz">oz</option>
						<option value="kg">kg</option>
						<option value="g">g</option>
					</select>
				</div>
			</div>
		</div>

		{/* Dimensions + dimension unit */}
		<div className="mt-4 mb-4">
			<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
				Package Dimensions
			</label>
			<div
				className="mt-2 flex flex-col md:flex-row md:items-center md:space-x-4 space-y-2 md:space-y-0">
				<div className="md:w-1/4 w-full">
					<label
						htmlFor="packageDimensionLength"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Length
					</label>
					<input
						id="packageDimensionLength"
						name="packageDimensionLength"
						type="number"
						value={shipping?.packageDimensionLength}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>

				<div className="md:w-1/4 w-full">
					<label
						htmlFor="packageDimensionWidth"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Width
					</label>
					<input
						id="packageDimensionWidth"
						name="packageDimensionWidth"
						type="number"
						value={shipping?.packageDimensionWidth}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>

				<div className="md:w-1/4 w-full">
					<label
						htmlFor="packageDimensionHeight"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Height
					</label>
					<input
						id="packageDimensionHeight"
						name="packageDimensionHeight"
						type="number"
						value={shipping?.packageDimensionHeight}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					/>
				</div>

				<div className="md:w-1/4 w-full">
					<label
						htmlFor="packageDimensionUnit"
						className="text-xs text-gray-900 dark:text-white font-semibold"
					>
						Dimension Unit
					</label>
					<select
						id="packageDimensionUnit"
						name="packageDimensionUnit"
						value={shipping?.packageDimensionUnit}
						onChange={handleShippingChange}
						className="mt-1 block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2
            text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300
            dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500
            sm:text-sm sm:leading-6"
					>
						<option value="">Select Unit</option>
						<option value="in">in</option>
						<option value="cm">cm</option>
					</select>
				</div>
			</div>
		</div>
	</>
}

interface ShippingTemplateFormProps {
	value: ShippingTemplate;
	isFormInListing?:boolean;
	handlingTimeCap?:number;
	onChange: (updated: ShippingTemplate) => void;
	propagateErrors?:(errors:any) => void;
}

const ShippingTemplateForm: React.FC<ShippingTemplateFormProps> = ({ value, onChange, isFormInListing, propagateErrors, handlingTimeCap }: ShippingTemplateFormProps) => {
	// Start with an "empty" shipping template (or pass one in as a prop).
	const [formState, setFormState] = useState<ShippingTemplate>(value);
	const [shippingServices, setShippingServices] = useState<ShippingServiceDetails[]>([]);

	// Simple validation errors
	const [errors, setErrors] = useState<Record<string, string>>({});

	// For shipping-service selection, let's have a text filter
	const [serviceFilter, setServiceFilter] = useState("");

	// Decide how many services allowed (4 domestic, 5 international)
	const MAX_DOMESTIC_SERVICES = 4;
	const MAX_INTERNATIONAL_SERVICES = 5;

	// Sync local state if parent updates `value`
	useEffect(() => {
		setFormState(value);
	}, [value]);

	// Validate & notify parent whenever formState changes
	useEffect(() => {
		validate(formState);
		onChange(formState);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState]);

	useEffect(() => {
		fetchEbayShippingServices().catch((err) => {console.log(err)});
	}, [])

	const fetchEbayShippingServices = async(): Promise<any> => {
		// setLoadingTypes(true);
		// Fetch from backend
		try {
			const response = await axiosInstance.get('/ebay/shipping-services');
			if (response.status === 200) {
				setShippingServices(response.data ? response.data.data.shipping_services.filter((svc:ShippingServiceDetails) => svc.ValidForSellingFlow) : []);
			} else {
				console.log(response.data);
			}
		} catch (err) {
			console.error('Error fetching ebay shipping services', err);
		}
	}

	function validate(data: ShippingTemplate) {
		const newErrors: Record<string, string> = {};

		if (!data.label.trim() && !isFormInListing) {
			newErrors.label = "Shipping template name is required.";
		}
		if (handlingTimeCap && data.handlingTime > handlingTimeCap) {
			newErrors.handlingTime = `Handling time must not exceed ${handlingTimeCap} days`;
		}
		if (!data.city.trim()) {
			newErrors.city = "City is required.";
		}
		if (!data.state.trim()) {
			newErrors.state = "State is required.";
		}
		if (!data.country.trim()) {
			newErrors.country = "Country is required.";
		}
		if (!data.zipCode.trim()) {
			newErrors.zipCode = "Zip code is required.";
		}
		if (!data.domesticPolicy.shippingServices.length) {
			newErrors.shippingService = "At least 1 shipping service must be selected";
		}
		if (data.internationalEnabled && !data.internationalPolicy.shippingServices.length) {
			newErrors.IntlShippingService = "At least 1 shipping service must be selected";
		}
		propagateErrors && propagateErrors(newErrors);
		setErrors(newErrors);
	}

	function updateField(path: string[], value: any) {
		setFormState(prev => {
			const clone: any = structuredClone(prev);
			let obj = clone;
			for (let i = 0; i < path.length - 1; i++) {
				obj = obj[path[i]];
			}
			obj[path[path.length - 1]] = value;
			return clone;
		});
	}

	// Toggling shipping method: set fields accordingly
	function setShippingMethod(isIntl: boolean, method: ShippingMethodType) {
		const path = isIntl ? ["internationalPolicy"] : ["domesticPolicy"];
		setFormState(prev => {
			const next = structuredClone(prev) as ShippingTemplate;
			const policy: ShippingPolicyDetail = isIntl ? next.internationalPolicy : next.domesticPolicy;

			policy.shippingMethod = method;
			policy.needsDimensions = (method === ShippingMethodType.FREIGHT_OVERSIZED);
			if (method === ShippingMethodType.FREIGHT_OVERSIZED) {
				// costType automatically "Calculated"
				policy.costType = "Calculated";
				policy.shippingPayer = "Buyer"; // or the user can pick, but typically buyer pays for FREIGHT_OVERSIZED?
				policy.shippingServices = [];
			} else if (method === ShippingMethodType.LOCAL_PICKUP_ONLY) {
				// local pickup => no cost type, no services
				policy.costType = "";
				policy.shippingServices = [];
			} else {
				// STANDARD_SMALL_MEDIUM => reset to defaults
				policy.costType = "";
				policy.shippingServices = [];
				// shippingPayer can remain as is
			}

			if (isIntl) next.internationalPolicy = policy;
			else next.domesticPolicy = policy;
			return next;
		});
	}

	// Toggling shipping payer (Buyer => might show cost type, Seller => free shipping => no cost type)
	function setShippingPayer(isIntl: boolean, payer: "Buyer" | "Seller") {
		setFormState(prev => {
			const next = structuredClone(prev) as ShippingTemplate;
			const policy: ShippingPolicyDetail = isIntl ? next.internationalPolicy : next.domesticPolicy;

			policy.shippingPayer = payer;
			if (policy.shippingMethod === ShippingMethodType.FREIGHT_OVERSIZED) {
				// FREIGHT_OVERSIZED => costType = "Calculated", no change
			} else if (policy.shippingMethod === ShippingMethodType.LOCAL_PICKUP_ONLY) {
				// local => no cost type
			} else {
				// STANDARD_SMALL_MEDIUM
				if (payer === "Seller") {
					// free shipping => no costType needed
					policy.costType = "";
					policy.shippingServices = [];
				} else {
					// Buyer => must choose cost type
					policy.costType = "";
				}
			}

			if (isIntl) next.internationalPolicy = policy;
			else next.domesticPolicy = policy;

			return next;
		});
	}

	// Toggling costType
	function setCostType(isIntl: boolean, costType: CostType) {
		setFormState(prev => {
			const next = structuredClone(prev) as ShippingTemplate;
			const policy: ShippingPolicyDetail = isIntl ? next.internationalPolicy : next.domesticPolicy;
			policy.costType = costType;
			if(policy.costType === "Calculated") policy.needsDimensions = true;
			// if costType changes => possibly clear shippingServices
			policy.shippingServices = [];
			if (isIntl) next.internationalPolicy = policy;
			else next.domesticPolicy = policy;
			return next;
		});
	}

	function updateCostField(
		isIntl: boolean,
		field: keyof ShippingPolicyDetail,
		cost: number
	) {
		setFormState(prev => {
			const next = structuredClone(prev) as ShippingTemplate;
			const policy = isIntl
				? next.internationalPolicy
				: next.domesticPolicy;

			// let's update the polices appropriately
			if(field === "baseCost")
				policy.baseCost = cost
			else if(field === "additionalCost")
				policy.additionalCost = cost

			// Reassign back to next to ensure immutability
			if (isIntl) {
				next.internationalPolicy = policy;
			} else {
				next.domesticPolicy = policy;
			}

			return next;
		});
	}

	// Toggle a marketplace in the supportedPlatform array
	function togglePlatform(platformVal: number) {
		setFormState(prev => {
			const copy = structuredClone(prev);
			const idx = copy.supportedPlatform.indexOf(platformVal);
			if (idx >= 0) {
				copy.supportedPlatform.splice(idx, 1);
			} else {
				copy.supportedPlatform.push(platformVal);
			}
			return copy;
		});
	}

	// Toggling shipping services. We'll show a check list from the filtered data.
	function toggleService(isIntl: boolean, service: ShippingServiceDetails) {
		setFormState(prev => {
			const next = structuredClone(prev) as ShippingTemplate;
			const policy = isIntl ? next.internationalPolicy : next.domesticPolicy;
			const list = [...policy.shippingServices];
			const idx = list.findIndex((v) => v.serviceId === service.ShippingServiceID);
			const maxAllowed = isIntl ? MAX_INTERNATIONAL_SERVICES : MAX_DOMESTIC_SERVICES;

			if (idx >= 0) {
				// remove
				list.splice(idx, 1);
			} else {
				// add if not exceed max
				if (list.length < maxAllowed) {
					list.push({
						serviceId: service.ShippingServiceID,
						serviceName: service.ShippingService,
						cost: 0, // cost for 1 item, if costType = "Flat"/"Free"
						additionalCost: 0, // cost per extra item
						shippingTimeMin: parseInt(service.ShippingTimeMin),
						shippingTimeMax: parseInt(service.ShippingTimeMax),
						expeditedService: service.ExpeditedService,
						trackingIncluded: true,
						insuranceIncluded: "",
					});
				}
			}
			if(service.WeightRequired) policy.needsDimensions = true;
			policy.shippingServices = list;
			if (isIntl) next.internationalPolicy = policy;
			else next.domesticPolicy = policy;
			return next;
		});
	}

	// Filter shipping services
	const filteredServices = shippingServices.filter(svc =>
		svc.ShippingService.toLowerCase().includes(serviceFilter.toLowerCase())
	);

	// Render shipping method/payer/costType
	function renderPolicyFields(isIntl: boolean) {
		const policy = isIntl ? formState.internationalPolicy : formState.domesticPolicy;
		const labelPrefix = isIntl ? "International" : "Domestic";

		return (
			<div className="bg-gray-50 dark:bg-gray-700 p-3 rounded-md mb-4">
				<h2 className="text-sm font-semibold mb-2 text-gray-800 dark:text-gray-100">
					{labelPrefix} Shipping
				</h2>

				{/* Shipping Method */}
				<div className="mb-2">
					<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
						Shipping Method
					</label>
					<select
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
						value={policy.shippingMethod}
						onChange={e => setShippingMethod(isIntl, Number(e.target.value))}
					>
						<option value={ShippingMethodType.STANDARD_SMALL_MEDIUM}>Standard: Small to medium items</option>
						<option value={ShippingMethodType.FREIGHT_OVERSIZED}>Freight: Oversized items</option>
						<option value={ShippingMethodType.LOCAL_PICKUP_ONLY}>Local Pickup: Sell to buyers near you</option>
					</select>
				</div>

				{/* Shipping Payer */}
				{policy.shippingMethod !== ShippingMethodType.LOCAL_PICKUP_ONLY && (
					<div className="mb-2">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Shipping Payer
						</label>
						<select
							className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
							value={policy.shippingPayer}
							onChange={e => setShippingPayer(isIntl, e.target.value as ShippingPayer)}
						>
							<option value="Buyer">Buyer</option>
							<option value="Seller">Seller</option>
						</select>
					</div>
				)}

				{/* costType only if method=STANDARD_SMALL_MEDIUM and payer=Buyer */}
				{policy.shippingMethod === ShippingMethodType.STANDARD_SMALL_MEDIUM &&
					policy.shippingPayer === "Buyer" && (
						<div className="mb-2">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Cost Type
							</label>
							<select
								className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
                py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
                ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
								value={policy.costType}
								onChange={e => setCostType(isIntl, e.target.value as CostType)}
							>
								<option value="Flat">Flat</option>
								<option value="Calculated">Calculated</option>
							</select>
						</div>
					)}

				{/* If costType=Flat, show these cost fields */}
				{policy.costType === "Flat" && (
					<>
						<div className="mb-2">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Base Shipping Cost
							</label>
							<input
								type="number"
								value={policy.baseCost}
								onChange={e => updateCostField(isIntl, "baseCost", parseFloat(e.target.value) || 0)}
								className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
							/>
						</div>

						<div className="mb-2">
							<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
								Additional Cost (each extra item)
							</label>
							<input
								type="number"
								value={policy.additionalCost}
								onChange={e => updateCostField(isIntl, "additionalCost", parseFloat(e.target.value) || 0)}
								className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
							/>
						</div>
					</>
				)}

				{/* If shippingMethod=FREIGHT_OVERSIZED => costType is auto "Calculated", no UI needed
            If shippingMethod=LocalPickup => no shipping services to pick
            Otherwise => shipping services UI if method=STANDARD_SMALL_MEDIUM & either payer
            We'll do a simple check: if method=STANDARD_SMALL_MEDIUM or method=FREIGHT_OVERSIZED & payer=Buyer => show services?
            Actually up to your logic. Let's assume STANDARD_SMALL_MEDIUM or FREIGHT_OVERSIZED => allow services, local => none. */}
				{policy.shippingMethod !== ShippingMethodType.LOCAL_PICKUP_ONLY && (
					<>
						<p className="text-xs text-gray-500 dark:text-gray-300 mb-1">Select shipping services</p>
						{/* Search input */}
						<input
							type="text"
							placeholder="Find a shipping service"
							className="mb-2 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1 px-2
                text-sm text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300
                dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
							value={serviceFilter}
							onChange={e => setServiceFilter(e.target.value)}
						/>
						<div className="max-h-40 overflow-y-auto border border-gray-200 dark:border-gray-600 rounded p-2">
							{filteredServices.filter(shipping => isIntl ? shipping.InternationalService : !shipping.InternationalService).map(svc => {
								const checked = !!policy.shippingServices.find((v) => v.serviceId === svc.ShippingServiceID);
								return (
									<label key={svc.ShippingService} className="flex items-start mb-2 text-sm">
										<input
											type="checkbox"
											className="h-4 w-4 mt-1 mr-2"
											checked={checked}
											onChange={() => toggleService(isIntl, svc)}
										/>
										<span className="text-gray-700 dark:text-gray-200">
                      <strong>{svc.ShippingService}</strong><br/>
											{svc.ShippingTimeMin} - {svc.ShippingTimeMax} business days<br/>
											Shipping Category: {svc.ShippingCategory}<br/>
											Expedited: {svc.ExpeditedService.toString()}<br/>
											Description: {svc.Description}
                    </span>
									</label>
								);
							})}
						</div>
						{isIntl ? (errors.IntlShippingService && (<p className="text-red-500 text-xs mt-1">{errors.IntlShippingService}</p>)) : (errors.shippingService && (<p className="text-red-500 text-xs mt-1">{errors.shippingService}</p>))}
					</>
				)}
			</div>
		);
	}

	return (
		<div className="w-full">
			{/* Template label */}
			{
				!isFormInListing && (
					<div className="mb-4">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
							Shipping Label <span className="text-red-500">*</span>
						</label>
						<input
							type="text"
							value={formState.label}
							onChange={e => updateField(["label"], e.target.value)}
							className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
		            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
		            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
						/>
						{errors.label && <p className="text-red-500 text-xs mt-1">{errors.label}</p>}
					</div>
				)
			}

			{/* isDefault */}
			<div className="mb-4 flex items-center">
				<input
					id="isDefault"
					type="checkbox"
					checked={formState.isDefault}
					onChange={e => updateField(["isDefault"], e.target.checked)}
					className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
				/>
				<label htmlFor="isDefault" className="ml-2 text-sm text-blue-400">
					Make this my default shipping template
				</label>
			</div>

			{/* HandlingTime */}
			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
					Handling Time (days)
				</label>
				<input
					type="number"
					value={formState.handlingTime}
					onChange={e => updateField(["handlingTime"], Number(e.target.value))}
					className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
				/>
				{errors.handlingTime && <p className="text-red-500 text-xs mt-1">{errors.handlingTime}</p>}
			</div>

			{/* Address line*/}
			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
					Address
				</label>
				<input
					type="text"
					value={formState.address}
					onChange={e => updateField(["address"], e.target.value)}
					className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
				/>
				{errors.address && <p className="text-red-500 text-xs mt-1">{errors.address}</p>}
			</div>

			{/* Address line*/}
			<div className="mb-4">
				<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
					Address Line 2
				</label>
				<input
					type="text"
					value={formState.addressOther}
					onChange={e => updateField(["addressOther"], e.target.value)}
					className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
            py-1 px-2 text-gray-900 dark:text-white ring-1 ring-inset
            ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500 text-sm"
				/>
				{errors.addressOther && <p className="text-red-500 text-xs mt-1">{errors.addressOther}</p>}
			</div>

			{/* Shipping location fields */}
			<div className="mb-4 grid grid-cols-2 gap-2">
				<div>
					<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
						City
					</label>
					<input
						type="text"
						value={formState.city}
						onChange={e => updateField(["city"], e.target.value)}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					/>
					{errors.city && (
						<p className="text-red-500 text-xs mt-1">{errors.city}</p>
					)}
				</div>
				<div>
					<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
						State
					</label>
					<input
						type="text"
						value={formState.state}
						onChange={e => updateField(["state"], e.target.value)}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					/>
					{errors.state && (
						<p className="text-red-500 text-xs mt-1">{errors.state}</p>
					)}
				</div>
				<div>
					<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
						ZIP Code
					</label>
					<input
						type="text"
						value={formState.zipCode}
						onChange={e => updateField(["zipCode"], e.target.value)}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					/>
					{errors.zipCode && (
						<p className="text-red-500 text-xs mt-1">{errors.zipCode}</p>
					)}
				</div>
				<div>
					<label className="block text-sm font-medium text-gray-700 dark:text-gray-200">
						Country
					</label>
					<input
						type="text"
						value={formState.country}
						onChange={e => updateField(["country"], e.target.value)}
						className="mt-1 w-full rounded-md border-0 bg-gray-100 dark:bg-white/5
              py-1 px-2 text-sm text-gray-900 dark:text-white ring-1 ring-inset
              ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-indigo-500"
					/>
					{errors.country && (
						<p className="text-red-500 text-xs mt-1">{errors.country}</p>
					)}
				</div>
			</div>

			{/* Domestic policy */}
			{formState.domesticEnabled && renderPolicyFields(false)}

			{/* International toggle */}
			<div className="mb-4 flex items-center">
				<input
					id="internationalEnabled"
					type="checkbox"
					checked={formState.internationalEnabled}
					onChange={e => updateField(["internationalEnabled"], e.target.checked)}
					className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
				/>
				<label
					htmlFor="internationalEnabled"
					className="ml-2 text-sm text-gray-700 dark:text-gray-200"
				>
					Enable International Shipping
				</label>
			</div>

			{/* If toggled, show international policy */}
			{formState.internationalEnabled && renderPolicyFields(true)}

			{/* supportedPlatform multi-select */}
			{
				!isFormInListing && (
					<div className="mb-4 hidden">
						<label className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
							Supported Platforms
						</label>
						<div className="flex flex-wrap gap-2">
							{ALL_PLATFORMS.map(plat => {
								const checked = formState.supportedPlatform.includes(plat.value);
								return (
									<label key={plat.value} className="flex items-center text-sm text-gray-700 dark:text-gray-200">
										<input
											type="checkbox"
											className="h-4 w-4 text-indigo-600 border-gray-300 rounded mr-1"
											checked={checked}
											onChange={() => togglePlatform(plat.value)}
										/>
										{plat.label}
									</label>
								);
							})}
						</div>
					</div>
				)
			}

			{/* Show overall errors if any */}
			{Object.keys(errors).length > 0 && (
				<p className="text-red-500 text-sm mt-2">Please correct the indicated fields.</p>
			)}
		</div>
	);
};

export default ShippingTemplateForm;
