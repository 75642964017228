import { makeAutoObservable } from "mobx";
import { Notification } from "app-ts-types/notifications";

class NotificationsStore {
	notifications: Notification[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = { field: "", direction: "desc" };
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setNotifications(notifications: Notification[]) {
		this.notifications = notifications;
	}

	addNotifications(newItems: Notification[]) {
		this.notifications = [...newItems, ...this.notifications];
	}

	updateNotification(updatedItem: Notification) {
		const index = this.notifications.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.notifications[index] = updatedItem;
		}
	}

	deleteNotification(itemId: string) {
		this.notifications = this.notifications.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedNotifications(): Notification[] {
		let filteredNotifications = this.notifications;

		if (this.filter) {
			filteredNotifications = filteredNotifications.filter(item => this.filter === "read" ? item.read : !item.read);
		}

		if (this.sortOption.field) {
			filteredNotifications = filteredNotifications.slice().sort((a, b) => {
				if (this.sortOption.direction === "asc") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}

		return filteredNotifications;
	}

	get paginatedNotifications(): Notification[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedNotifications.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedNotifications.length / this.itemsPerPage);
	}
}

export const notificationsStore = new NotificationsStore();
