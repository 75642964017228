import axiosInstance from './server';
import { Message, CreateMessageRequest, UpdateMessageRequest, GetMessageResponse, GetMessagesResponse } from 'app-ts-types/messages';
import { initNotification } from './notifications';
import {PostMemberMessageResponseRequest, RespondToBestOfferRequest} from "../utils/types";
import {generateUUID} from "../utils/data";

// Get a message by ID
export const getMessageById = async (id: string): Promise<GetMessageResponse> => {
	const response = await axiosInstance.get<GetMessageResponse>(`/message/${id}`);
	return response.data;
};

// Get a messages
export const getMessages = async (): Promise<GetMessagesResponse> => {
	const response = await axiosInstance.get<GetMessagesResponse>(`/message/`);
	return response.data;
};

// Create a new message
export const createMessage = async (data: Message): Promise<GetMessageResponse> => {
	const response = await axiosInstance.post<GetMessageResponse>('/message', data);
	return response.data;
};

// Update a message by ID
export const updateMessage = async (id: string, data: Message): Promise<GetMessageResponse> => {
	const response = await axiosInstance.put<GetMessageResponse>(`/message/${id}`, data);
	return response.data;
};

// Delete a message by ID
export const deleteMessage = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/message/${id}`);
};

// respond to a best offer message
export const respondToBestOffer = async (
	params: RespondToBestOfferRequest
): Promise<any> => {
	try {
		const response = await axiosInstance.post(
			"/ebay/respond-to-best-offer",
			params
		);
		initNotification(
			params.best_offer_id,
			"Best Offer Response",
			`Best offer ${params.action.toLowerCase()}ed successfully`
		).catch((e) => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			generateUUID(),
			"Error Responding to Best Offer",
			e.response?.data?.error ||
			"An unexpected error occurred while responding to the best offer."
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// post member messages response
export const postMemberMessageResponse = async (
	params: PostMemberMessageResponseRequest
): Promise<any> => {
	try {
		const response = await axiosInstance.post(
			"/ebay/respond-to-message",
			params
		);
		initNotification(
			params.item_id,
			"Message Response",
			"Member message sent successfully"
		).catch((e) => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			generateUUID(),
			"Error Sending Message Response",
			e.response?.data?.error ||
			"An unexpected error occurred while sending the member message response."
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};
