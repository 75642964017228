import React from 'react';
import DonutChart from "../components/PieChart";

import amazonIcon from "../images/Amazon.svg";
import ebayIcon from "../images/eBay.svg";
import mercariIcon from "../images/Mercari.svg";
import grailedIcon from "../images/Grailed.svg";
import etsyIcon from "../images/Etsy.svg";
import facebookIcon from "../images/FacebookMarketplaces.svg";
import shopifyIcon from "../images/Shopify.svg";
import lightSpeedIcon from "../images/lightspeed.svg";
import stockXIcon from "../images/stockx.svg";
import goatIcon from "../images/goat-rounded.svg";
import depopIcon from "../images/Depop.svg";
import woocommerceIcon from "../images/woocommerce-icon-svgrepo-com.svg";
import SalesLineChart from "../components/LineGraph";
import {salesRecordsStore} from "../state/SalesRecord";

const salesData = [
	{ platform: "eBay", count: 30, color: "#f5AF02", img: ebayIcon },
	{ platform: "Amazon", count: 20, color: "#ff9900", img: amazonIcon },
	{ platform: "Shopify", count: 15, color: "#96bf48", img: shopifyIcon },
	{ platform: "Etsy", count: 10, color: "#eb6d20", img: etsyIcon },
	{ platform: "Depop", count: 10, color: "#ff2300", img: depopIcon },
	{ platform: "WooCommerce", count: 10, color: "#7f54b3", img: woocommerceIcon },
	{ platform: "Lightspeed", count: 25, color: "#ea6265", img: lightSpeedIcon },
	{ platform: "Goat", count: 10, color: "#000000", img: goatIcon },
	{ platform: "Facebook", count: 10, color: "#0866ff", img: facebookIcon },
	{ platform: "Mercari", count: 15, color: "#007AFF", img: mercariIcon },
	{ platform: "Grailed", count: 17, color: "#808080", img: grailedIcon },
	{ platform: "StockX", count: 10, color: "#166a4a", img: stockXIcon },
];


const categoryData = [
	{ platform: "Sneakers", count: 1, color: "#283D3B", img: null }, // Dark greenish-gray for a classic sneaker look
	{ platform: "Street Wear", count: 1, color: "#772E25", img: null }, // Deep red-brown for urban style
	{ platform: "Collectibles", count: 1, color: "#197278", img: null }, // Teal for a unique and collectible feel
	{ platform: "Handbags", count: 1, color: "#EDDDD4", img: null }, // Soft beige for elegance
	{ platform: "Tops", count: 1, color: "#413c3c", img: null }, // Standard gray for versatility
	{ platform: "Watches", count: 1, color: "#C0C0C0", img: null }, // Silver for a classic watch appearance
	{ platform: "NFT", count: 1, color: "#DAB08C", img: null }, // Light brown for digital assets
	{ platform: "Shorts", count: 1, color: "#915B25", img: null }, // Dark brown for casual wear
	{ platform: "Pants", count: 1, color: "#C44536", img: null }, // Brick red for a bold statement
	{ platform: "Unrecognized", count: 1, color: "#616161", img: null }, // Gray for undefined categories
];


const Reports = () => {
	return (
		<div>
			<h1 className={"text-2xl font-bold mb-10"}>Analytics</h1>
			<div className="flex flex-col lg:flex-row items-center justify-between rounded">
				<div className="w-full h-full lg:w-2/3 mt-4 lg:mt-0 self-start">
					<SalesLineChart salesRecords={salesRecordsStore.salesRecords}/>
				</div>
				<div className="w-full lg:w-1/3 flex flex-col sm:flex-col md:flex-row lg:flex-col items-center space-y-4 space-x-0 md:space-x-4 sm:space-y-4 md:space-y-0 lg:space-y-4 justify-center md:justify-around lg:justify-center">
					<div className="shadow border dark:border-gray-700 rounded-lg w-fit lg:place-self-end flex justify-end">
						<DonutChart width={300} height={300} margin={20} data={salesData}/>
					</div>
					<div className="shadow border dark:border-gray-700 rounded-lg w-fit lg:place-self-end flex justify-end">
						<DonutChart width={300} height={300} margin={20} data={categoryData}/>
					</div>
				</div>
		</div>
		</div>

	);
};

export default Reports;
