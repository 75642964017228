import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {
	Bars3Icon,
	ChartPieIcon,
	BanknotesIcon,
	Cog6ToothIcon,
	RectangleGroupIcon,
	ArchiveBoxIcon,
	HomeIcon,
	XMarkIcon,
	PlusCircleIcon,
	EnvelopeIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	BuildingStorefrontIcon,
	ChatBubbleLeftRightIcon,
	Square3Stack3DIcon,
	CreditCardIcon
} from '@heroicons/react/24/outline';
import {ChevronDownIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';
import logo from "./images/IconOnly_Transparent_NoBuffer.png";
import salesIcon from "./images/noun-expense-2270496.png";
import Inventory from './pages/Inventory';
import Categories from './pages/Categories';
import Marketplaces from './pages/Marketplaces';
import Sales from './pages/Sales';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import UnderConstruction from "./pages/UnderConstruction";
import {userStore} from "./state/User";
import Messages from "./pages/Messages";
import Chats from "./pages/Chats";
import Notifications from "./pages/Notifications";
import InventoryForm from "./components/CreateInventory";
import ListingForm from "./components/CreateListing";
import CollectionForm from "./components/CreateCollection";
import {Inventory as InventoryType, Collection, Listing, Expense} from "./utils/types"
import {inventoryStore} from "./state/Inventory";
import {getUserById} from "./api/user";
import {getInventories} from "./api/inventories";
import {getListings} from "./api/listings";
import {getCollections} from "./api/collections";
import {collectionsStore} from "./state/Collection";
import {listingsStore} from "./state/Listing";
import {getSalesRecords} from "./api/sales";
import {salesRecordsStore} from "./state/SalesRecord";
import {getPricingTemplates, getReturnTemplates, getShippingTemplates, getTemplates} from "./api/template";
import {templatesStore} from "./state/Template";
import notificationStore from "./state/PopupNotification";
import {observer} from "mobx-react-lite";
import {NotificationPopupComponent} from "./components/NotificationPopup";
import Templates from "./pages/Templates";
import {shippingTemplatesStore} from "./state/ShippingTemplate";
import {pricingTemplatesStore} from "./state/PricingTemplate";
import {returnsTemplatesStore} from "./state/ReturnsTemplate";
import {startPollingChats, startPollingMessages, startPollingNotifications} from "./api/polling";
import NotificationsHeaderPopup from "./components/NotificationsHeader";
import MessagesHeaderPopup from "./components/MessagesHeader";
import ChatsHeaderPopup from "./components/ChatsHeader";
import Expenses from "./pages/Expenses";
import ExpenseForm from "./components/CreateExpense";


const navigation = [
	{name: 'Home', href: '/', icon: HomeIcon, current: true},
	{name: 'Inventory', href: '/inventory', icon: ArchiveBoxIcon, current: false},
	{name: 'Listings', href: '/marketplaces', icon: BuildingStorefrontIcon, current: false},
	{name: 'Templates ', href: '/templates', icon: Square3Stack3DIcon, current: false},
	{name: 'Collections', href: '/categories', icon: RectangleGroupIcon, current: false},
	{name: 'Sales', href: '/sales', icon: BanknotesIcon, current: false},
	{name: 'Expenses', href: '/expenses', icon: CreditCardIcon, current: false},
	{name: 'Reports', href: '/reports', icon: ChartPieIcon, current: false},
];

const userNavigation = [
	{name: 'Your profile', href: '/profile', onClick: undefined},
	{
		name: 'Sign out', href: '#', onClick: () => {
			window.localStorage.removeItem('user');
			window.localStorage.removeItem('token');
			window.localStorage.removeItem('refresh_token');
			window.location.href = '/login'; // Redirect to login page
		}
	},
];

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}

// is th page under construction or nuh?
const isUnderConstruction = false;

const App: React.FC = observer(() => {
	const location = useLocation();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [sidebarExpanded, setSidebarExpanded] = useState(() => {
		const savedValue = localStorage.getItem('sidebarExpanded');
		return savedValue ? JSON.parse(savedValue) : true;
	});
	const [notifications, setNotifications] = useState(false);
	const [chats, setChats] = useState(false);
	const [messages, setMessages] = useState(false);
	const [createInventory, setCreateInventory] = useState(false);
	const [createListing, setListing] = useState(false);
	const [createCollection, setCollection] = useState(false);
	const [createExpense, setCreateExpense] = useState(false);
	const [currentInventory, setCurrentInventory] = useState<InventoryType | undefined>(undefined);
	const [currentListing, setCurrentListing] = useState<Listing | undefined>(undefined);
	const [currentCollection, setCurrentCollection] = useState<Collection | undefined>(undefined);
	const [currentExpense, setCurrentExpense] = useState<any | undefined>(undefined);
	const [current, setCurrent] = useState('/');

	// Update current state whenever the route changes
	useEffect(() => {
		setCurrent(location.pathname);
	}, [location.pathname]);

	useEffect(() => {
		getUser();
		toggleTheme();
	}, []);

	const getUser = () => {
		const user = JSON.parse(window.localStorage.getItem("user") || "null");
		let userId;
		if (user) {
			userId = user.id;
		}
		if (!user) {
			userId = parseJwt()!.user_id;
		}
		getUserById(userId)
			.then((res) => {
				// if there's no error fetching the user details
				if (!res.isError) {
					// @ts-ignore
					window.localStorage.setItem('user', JSON.stringify(res.data) || 'null')
					// @ts-ignore
					userStore.setUser(res.data)
					getInventoryObjects();
					getListingObjects();
					getCollectionObjects();
					getSalesRecordsObjects();
					getTemplatesObjects();
					getShippingTemplatesObjects();
					getPricingTemplatesObjects();
					getReturnsTemplatesObjects();
					startPollingMessages();
					startPollingChats();
					startPollingNotifications();
				}
			}).catch((error) => {
			console.log(error);
		})
	}

	const getInventoryObjects = () => {
		getInventories()
			.then((res: any) => {
				// console.log(res);
				if (!res.isError) {
					inventoryStore.setInventory(res.data)
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getListingObjects = () => {
		getListings()
			.then((res: any) => {
				if (!res.isError) {
					listingsStore.setListings(res.data)
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getCollectionObjects = () => {
		getCollections()
			.then((res: any) => {
				if (!res.isError) {
					collectionsStore.setCollections(res.data)
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getSalesRecordsObjects = () => {
		getSalesRecords()
			.then((res: any) => {
				if (!res.isError) {
					salesRecordsStore.setSalesRecords(res.data);
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getTemplatesObjects = () => {
		getTemplates()
			.then((res: any) => {
				if (!res.isError) {
					templatesStore.setTemplates(res.data);
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getShippingTemplatesObjects = () => {
		getShippingTemplates()
			.then((res: any) => {
				if (!res.isError) {
					shippingTemplatesStore.setShippingTemplates(res.data);
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getPricingTemplatesObjects = () => {
		getPricingTemplates()
			.then((res: any) => {
				if (!res.isError) {
					pricingTemplatesStore.setPricingTemplates(res.data);
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const getReturnsTemplatesObjects = () => {
		getReturnTemplates()
			.then((res: any) => {
				if (!res.isError) {
					returnsTemplatesStore.setReturnTemplates(res.data);
				}
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const toggleTheme = () => {
		if (localStorage.getItem("darkMode")) {
			document.documentElement.classList.add('dark');
		} else {
			document.documentElement.classList.remove('dark');
		}
	}

	const parseJwt = () => {
		try {
			const base64Url = window.localStorage.getItem("token")!.split('.')[1];
			const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
			const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			}).join(''));
			return JSON.parse(jsonPayload);
		} catch (e) {
			return null;
		}
	}

	const handleSearch = (event: any) => {
		event.preventDefault();
		const query = event.target.value;
		// if there's no search data
		if (!query.toString().trim()) {
			listingsStore.setFilter("");
			inventoryStore.setFilter("");
			return;
		}
		// Perform search action here
		if (location.pathname === "/marketplaces") {
			listingsStore.setFilter(query);
		} else {
			inventoryStore.setFilter(query);
		}
	};

	const handleCreate = (inv: boolean, col: boolean, list: boolean, exp:boolean, data: any, inventory?: InventoryType) => {
		if (inv) setCurrentInventory(data);
		if (col) setCurrentCollection(data);
		if (exp) setCurrentExpense(data);
		if (list) setCurrentListing(data);
		setCreateInventory(inv);
		setCollection(col);
		setCreateExpense(exp);
		if (inventory) setCurrentInventory(inventory);
		setListing(list);
	}

	const handleAddProductClick = () => {
		handleCreate(true, false, false, false, undefined);
	};

	const notificationCloseCallback = () => {
		notificationStore.resetNotification()
	};

	useEffect(() => {
		const token = localStorage.getItem('token');
		const refreshToken = localStorage.getItem('refresh_token');
		if (!token || !refreshToken) {
			window.location.href = 'https://www.tracknlist.com/login';
		}
	});

	// Update localStorage whenever sidebarExpanded changes
	useEffect(() => {
		localStorage.setItem('sidebarExpanded', JSON.stringify(sidebarExpanded));
	}, [sidebarExpanded]);

	let pageElements = <>
		<Routes>
			<Route path="/" element={<Homepage handleCreateInventory={(data?: InventoryType) => {
				handleCreate(true, false, false, false, data)
			}} handleCreateCollection={(data?: Collection) => {
				handleCreate(false, true, false, false, data)
			}} handleCreateListing={(data?: Listing, inventory?: InventoryType) => {
				handleCreate(false, false, true, false, data, inventory)
			}}/>}/>
			<Route path="/inventory/*" element={<Inventory handleCreateInventory={(data?: InventoryType) => {
				handleCreate(true, false, false, false, data)
			}} handleCreateCollection={(data?: Collection) => {
				handleCreate(false, true, false, false, data)
			}} handleCreateListing={(data?: Listing, inventory?: InventoryType) => {
				handleCreate(false, false, true, false, data, inventory)
			}}/>}/>
			<Route path="/categories/*" element={<Categories handleCreateInventory={(data?: InventoryType) => {
				handleCreate(true, false, false, false, data)
			}} handleCreateCollection={(data?: Collection) => {
				handleCreate(false, true, false, false, data)
			}} handleCreateListing={(data?: Listing, inventory?: InventoryType) => {
				handleCreate(false, false, true, false, data, inventory)
			}}/>}/>
			<Route path="/marketplaces/*" element={<Marketplaces handleCreateInventory={(data?: InventoryType) => {
				handleCreate(true, false, false, false, data)
			}} handleCreateCollection={(data?: Collection) => {
				handleCreate(false, true, false, false, data)
				// 	@ts-ignore
			}} handleCreateListing={(data?: Listing, inventory?: InventoryType) => {
				handleCreate(false, false, true, false, data, inventory)
			}}/>}/>
			<Route path="/templates/*" element={<Templates/>}/>
			<Route path="/sales/*" element={<Sales/>}/>
			<Route path="/expenses/*" element={<Expenses handleCreateInventory={(data?: InventoryType) => {
				handleCreate(true, false, false, false, data)
			}} handleCreateNewExpense={(data?: Expense) => {
				handleCreate(false, false, false, true, data)
			}} handleCreateListing={(data?: Listing, inventory?: InventoryType) => {
				handleCreate(false, false, true, false, data, inventory)
			}}/>}/>
			<Route path="/reports" element={<Reports/>}/>
			<Route path="/settings/*" element={<Settings/>}/>
			<Route path="/profile/*" element={<Profile/>}/>
			<Route path="/notifications" element={<Notifications/>}/>
			<Route path="/chats" element={<Chats/>}/>
			<Route path="/messages" element={<Messages/>}/>
			<Route path="*" element={<NotFound/>}/>
		</Routes>
	</>

	if (isUnderConstruction) {
		pageElements = <UnderConstruction/>
	}

	let notificationComponent;
	if (notificationStore.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationStore.title}
			                            message={notificationStore.message} isError={notificationStore.isError}
			                            autoClose={notificationStore.autoClose} closeTime={notificationStore.closeTime}/>
	}

	return (
		<div id={"app"}>
			{notificationComponent}
			<div className="h-full bg-white dark:bg-gray-900">
				{createInventory && <InventoryForm initialData={currentInventory} onClose={() => {
					setCreateInventory(false)
				}}/>}
				{createListing && <ListingForm initialInventory={currentInventory} isUpdateInventory={!!currentInventory}
				                               initialData={currentListing} onClose={() => {
					setListing(false);
					setCurrentInventory(undefined);
				}}/>}
				{createCollection && <CollectionForm initialData={currentCollection} onClose={() => {
					setCollection(false)
				}}/>}
				{createExpense && <ExpenseForm onClose={() => {setCreateExpense(false);}} initialData={currentExpense} />}
				<Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
					<div className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear"/>
					<div className="fixed inset-0 flex">
						<Dialog.Panel
							className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out">
							<Transition.Child>
								<div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out">
									<button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
										<span className="sr-only">Close sidebar</span>
										<XMarkIcon aria-hidden="true" className="h-6 w-6 text-white"/>
									</button>
								</div>
							</Transition.Child>
							<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
								<div className="flex h-16 shrink-0 items-center">
									<img
										alt="Track and List"
										src={logo}
										className="h-8 w-auto"
									/>
								</div>
								<nav className="flex flex-1 flex-col">
									<ul role="list" className="flex flex-1 flex-col gap-y-7">
										<li>
											<ul role="list" className="-mx-2 space-y-1">
												{navigation.map((item) => (
													<li key={item.name}>
														<Link
															to={item.href}
															onClick={(e: any) => {
																setSidebarOpen(false);
															}}
															className={classNames(
																item.href === current
																	? 'bg-indigo-700 text-white'
																	: 'text-indigo-200 hover:bg-indigo-700 hover:text-white',
																'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
															)}
														>
															<item.icon
																aria-hidden="true"
																className={classNames(
																	item.href === current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
																	'h-6 w-6 shrink-0',
																)}
															/>
															{item.name}
														</Link>
													</li>
												))}
											</ul>
										</li>
										<li className="mt-auto">
											<button
												onClick={handleAddProductClick}
												className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
											>
												<PlusCircleIcon
													aria-hidden="true"
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
												/>
												Add Products
											</button>
											<Link
												to="/settings"
												className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
											>
												<Cog6ToothIcon
													aria-hidden="true"
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
												/>
												Settings
											</Link>
										</li>
									</ul>
								</nav>
							</div>
						</Dialog.Panel>
					</div>
				</Dialog>
				<div
					className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-${sidebarExpanded ? '52' : '20'} lg:flex-col transition-width duration-300`}>
					<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brightPurple px-6 pb-4">
						<div className="flex h-16 shrink-0 items-center">
							<img
								alt="Track and List"
								src={logo}
								className="h-8 w-auto grayscale"
							/>
						</div>
						<nav className="flex flex-1 flex-col">
							<ul role="list" className={`${sidebarExpanded ? "flex" : "inline-flex"} flex-1 flex-col gap-y-7`}>
								<li>
									<ul role="list" className="-mx-2 space-y-1">
										{navigation.map((item) => (
											<li key={item.name}>
												<Link
													to={item.href}
													className={classNames(
														item.href === current
															? 'bg-deepPurple text-white'
															: 'text-indigo-200 hover:bg-lavender hover:text-white',
														`group ${sidebarExpanded ? "flex" : "inline-flex"} gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`,
													)}
												>
													<item.icon
														aria-hidden="true"
														className={classNames(
															item.href === current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
															'h-6 w-6 shrink-0',
														)}
													/>
													{sidebarExpanded && item.name}
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li className="mt-auto">
									<section>
										<a
											onClick={handleAddProductClick}
											className={`group ${sidebarExpanded ? "flex" : "inline-flex"} -mx-2 cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-lavender hover:text-white`}
										>
											<PlusCircleIcon
												aria-hidden="true"
												className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
											/>
											{sidebarExpanded && 'Add Products'}
										</a>
									</section>
									<section>
										<Link
											to="/settings"
											className={classNames(
												"/settings" === current
													? 'bg-deepPurple text-white'
													: 'text-indigo-200 hover:bg-lavender hover:text-white',
												`group -mx-2 ${sidebarExpanded ? "flex" : "inline-flex"} gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`,
											)}
										>
											<Cog6ToothIcon
												aria-hidden="true"
												className={classNames(
													"/settings" === current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
													'h-6 w-6 shrink-0',
												)}
											/>
											{sidebarExpanded && 'Settings'}
										</Link>
									</section>
								</li>
							</ul>
						</nav>
					</div>
					<button
						onClick={() => setSidebarExpanded(!sidebarExpanded)}
						className="absolute top-[70%] right-0 transform -translate-y-1/2 border hover:bg-white border-r-0 hover:text-deepPurple border-white p-2 rounded-l-md text-white"
					>
						{sidebarExpanded ? <ChevronLeftIcon className="h-6 w-6"/> : <ChevronRightIcon className="h-6 w-6"/>}
					</button>
				</div>
				<div className={`${sidebarExpanded ? 'lg:pl-40' : 'lg:pl-20'}`}>
					<div
						className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white dark:bg-gray-900 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
						<button type="button" onClick={() => setSidebarOpen(true)}
						        className="-m-2.5 p-2.5 text-gray-700 dark:text-gray-300 lg:hidden">
							<span className="sr-only">Open sidebar</span>
							<Bars3Icon aria-hidden="true" className="h-6 w-6"/>
						</button>

						{/* Separator */}
						<div aria-hidden="true" className="h-6 w-px bg-gray-900/10 dark:bg-gray-700 lg:hidden"/>

						<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
							<div className="relative flex flex-1">
								<label htmlFor="search-field" className="sr-only">
									Search
								</label>
								<MagnifyingGlassIcon
									aria-hidden="true"
									className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400 dark:text-gray-500"
								/>
								<input
									id="search-field"
									name="search"
									type="search"
									// value={inventoryStore.filter}
									placeholder="Search..."
									className="block outline-none focus:outline-none h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 dark:bg-gray-900 dark:text-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm"
									onChange={handleSearch}
								/>
							</div>
							{/*@ts-ignore*/}
							<div className="flex items-center gap-x-4 lg:gap-x-6">
								{/* Profile dropdown */}
								<NotificationsHeaderPopup/>
								<MessagesHeaderPopup/>
								<ChatsHeaderPopup/>
								{/* Separator */}
								<div aria-hidden="true"
								     className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 dark:bg-gray-700"/>

								{/* Profile dropdown */}
								<Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5">
										<span className="sr-only">Open user menu</span>
										<span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true"
                            className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                        Account
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400 dark:text-gray-300"/>
                    </span>
									</Menu.Button>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															// Toggle theme logic here
															document.documentElement.classList.toggle('dark');
															if (localStorage.getItem("darkMode")) {
																localStorage.removeItem("darkMode")
															} else {
																localStorage.setItem("darkMode", "true")
															}
														}}
														className={classNames(
															active ? 'bg-gray-50 dark:bg-gray-700' : '',
															'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-100'
														)}
													>
														Change Theme
													</button>
												)}
											</Menu.Item>
											{userNavigation.map((item) => (
												<Menu.Item key={item.name}>
													{({active}) => (
														<Link
															to={item.href}
															onClick={item.onClick}
															className={classNames(
																active ? 'bg-gray-50 dark:bg-gray-700' : '',
																'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-100'
															)}
														>
															{item.name}
														</Link>
													)}
												</Menu.Item>
											))}
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>
					<main className="py-10 w-full min-h-[calc(100vh)]">
						<div className="px-4 sm:px-6 text-gray-900 dark:text-white lg:px-8">
							{pageElements}
						</div>
					</main>
				</div>
			</div>
		</div>
	);
});

export default App;
