import axiosInstance from './server';
import {Listing, MarketplacePlatform} from "../utils/types";
import {initNotification} from "./notifications";

// Define Types for StockX API (temporary, update with exact response structures)
export interface StockXListing {
	listingId: string;
	operationId?: string;
	operationStatus?: string;
	operationUrl?: string;
	error?: string | null;
}
export interface CreateListingRequest {
	variantId: string;
	price: string;
	currencyCode: string;
	expiresInDays?: number;
}
export interface UpdateListingRequest {
	amount?: string;
	expiresAt?: string;
}
export interface StockXProduct {
	productId: string;
	styleId: string;
	title: string;
	brand: string;
	productType: string;
	productAttributes: {[key:string]: string};
	variants?: StockXVariant[];
}
export interface StockXVariant {
	variantId: string;
	variantName: string;
}


// **LISTINGS MANAGEMENT**
// Get the status of a listing operation
export const getStockXListingStatus = async (operationId: string, operationUrl:string): Promise<StockXListing> => {
	const response = await axiosInstance.get<StockXListing>(`/stockx/listing/status/${operationId}?operationUrl=${operationUrl}`);
	return response.data;
};
// Create a new listing
export const createStockXListing = async (data: Listing): Promise<any> => {
	try {
		const response = await axiosInstance.post<any>('/stockx/listing', data);
		initNotification(
			data.id,
			`StockX Listing Creation - ${data.title} `,
			`Successfully created the StockX Listing for ${data.title}. All details have been correctly processed and the listing is now queued for review.`,
			data.platform,
		).catch((e) => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			data.id,
			`Error Creating StockX Listing for ${data.title}`,
			e.response?.data?.error ||
			`An unexpected error occurred while creating the StockX listing for ${data.title}. Please check your data and try again.`,
			data.platform
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Activate a listing
export const activateStockXListing = async (listingId: string, data: Listing): Promise<void> => {
	try {
		await axiosInstance.put(`/stockx/listing/${listingId}/activate`, data);
		initNotification(
			listingId,
			`StockX Listing Activation - ${data.title}`,
			`The StockX listing for ${data.title} has been successfully activated and is now live on the marketplace.`,
			data.platform,
		).catch((e) => console.log(e));
	} catch (e: any) {
		initNotification(
			listingId,
			`Error Activating StockX Listing for ${data.title}`,
			e.response?.data?.error ||
			`Failed to activate the StockX listing for ${data.title}. Please verify your credentials and listing details before trying again.`,
			data.platform,
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Deactivate a listing
export const deactivateStockXListing = async (listingId: string, data: Listing): Promise<void> => {
	try {
		await axiosInstance.put(`/stockx/listing/${listingId}/deactivate`, data);
		initNotification(
			listingId,
			`StockX Listing Deactivation - ${data.title}`,
			`The StockX listing for ${data.title} has been successfully deactivated and is no longer visible on the marketplace.`,
			data.platform
		).catch((e) => console.log(e));
	} catch (e: any) {
		initNotification(
			listingId,
			`Error Deactivating StockX Listing for ${data.title}`,
			e.response?.data?.error ||
			`Failed to deactivate the StockX listing for ${data.title}. Please review the listing status and try again.`,
			data.platform
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Update a listing
export const updateStockXListing = async (listingId: string, data: Listing): Promise<StockXListing> => {
	try {
		const response = await axiosInstance.patch<StockXListing>(`/stockx/listing/${listingId}`, data);
		initNotification(
			data.id,
			`StockX Listing Update - ${data.title}`,
			`The StockX listing for ${data.title} has been successfully updated with the latest information provided.`,
			data.platform
		).catch((e) => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			data.id,
			`Error Updating StockX Listing for ${data.title}`,
			e.response?.data?.error ||
			`An error occurred while updating the StockX listing for ${data.title}. Please ensure all fields are correct and try again.`,
			data.platform
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a listing
export const deleteStockXListing = async (listingId: string, tnlId: string): Promise<void> => {
	try {
		await axiosInstance.delete(`/stockx/listing/${listingId}/delete?listingId=${tnlId}`);
		initNotification(
			listingId,
			"StockX Listing Deletion",
			"The StockX listing has been successfully deleted from the system and will no longer be available.",
			MarketplacePlatform.STOCKX
		).catch((e) => console.log(e));
	} catch (e: any) {
		initNotification(
			listingId,
			"Error Deleting StockX Listing",
			e.response?.data?.error ||
			"An error occurred while attempting to delete the StockX listing. Please try again later.",
			MarketplacePlatform.STOCKX
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};



// **PRODUCT SEARCH & VARIANTS**
// Search for a product
export const searchStockXProduct = async (query: string): Promise<any> => {
	const response = await axiosInstance.get<StockXProduct[]>(`/stockx/product?query=${query}`);
	return response.data;
};

// Get a product by ID
export const getStockXProductById = async (productId: string): Promise<StockXProduct> => {
	const response = await axiosInstance.get<StockXProduct>(`/stockx/product/${productId}`);
	return response.data;
};

// Get product variants
export const getStockXProductVariants = async (productId: string): Promise<any[]> => {
	const response = await axiosInstance.get<StockXVariant[]>(`/stockx/product/${productId}/variants`);
	return response.data;
};
