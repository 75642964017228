import { getMessages } from './messages';
import { getChatMessages } from './chats';
import { getNotifications } from './notifications';
import { GetMessagesResponse } from 'app-ts-types/messages';
import { GetChatMessagesResponse } from 'app-ts-types/chats';
import { GetNotificationsResponse } from 'app-ts-types/notifications';
import { messagesStore } from '../state/Message';
import { chatMessagesStore } from '../state/Chats';
import { notificationsStore } from '../state/Notification';

const POLLING_INTERVAL = 20000; // Poll every 5 seconds

// Helper to merge arrays by unique id
export const mergeUnique = <T extends { id: string }>(existing: T[], incoming: T[]): T[] => {
	const map = new Map<string, T>();
	existing.forEach(item => map.set(item.id, item));
	incoming.forEach(item => map.set(item.id, item));
	return Array.from(map.values());
};

// Poll messages API and update store without duplicates
export const startPollingMessages = (): (() => void) => {
	const messagePollingFunction = async () => {
		try {
			const data: GetMessagesResponse = await getMessages();
			// Assuming response.messages is the array of Message items
			messagesStore.setMessages(
				mergeUnique(messagesStore.messages, data.data)
			);
		} catch (err) {
			console.error("Error polling messages:", err);
		}
	}
	messagePollingFunction().catch((err:any) => {console.log(err)});
	const intervalId = setInterval(async () => {
		messagePollingFunction().catch((err:any) => {console.log(err)});
	}, POLLING_INTERVAL);
	return () => clearInterval(intervalId);
};

// Poll chat messages API and update store without duplicates
export const startPollingChats = (): (() => void) => {
	const chatPollingFunction = async () => {
		try {
			const data: GetChatMessagesResponse = await getChatMessages();
			// Assuming response.chatMessages is the array of ChatMessage items
			chatMessagesStore.setChatMessages(
				mergeUnique(chatMessagesStore.chatMessages, data.data)
			);
		} catch (err) {
			console.error("Error polling chats:", err);
		}
	}
	chatPollingFunction().catch((err:any) => {console.log(err)});
	const intervalId = setInterval(async () => {
		chatPollingFunction().catch((err:any) => {console.log(err)});
	}, POLLING_INTERVAL);
	return () => clearInterval(intervalId);
};

// Poll notifications API and update store without duplicates
export const startPollingNotifications = async (): Promise<void> => {
	try {
		const data: GetNotificationsResponse = await getNotifications();
		// Assuming response.notifications is the array of Notification items
		notificationsStore.setNotifications(
			mergeUnique(data.data, notificationsStore.notifications)
		);
	} catch (err) {
		console.error("Error polling notifications:", err);
	}
	// const intervalId = setInterval(async () => {
	// }, POLLING_INTERVAL);
	// return () => clearInterval(intervalId);
};
