import axiosInstance from './server';
import { Listing, CreateListingRequest, UpdateListingRequest, GetListingResponse, GetListingsResponse } from '../utils/types';

// Get a listing by ID
export const getListingById = async (id: string): Promise<GetListingResponse> => {
	const response = await axiosInstance.get<GetListingResponse>(`/listing/${id}`);
	return response.data;
};

// Get a listings
export const getListings = async (skip=0, limit=500): Promise<GetListingsResponse> => {
	const response = await axiosInstance.get<GetListingsResponse>(`/listing?skip=${skip}&limit=${limit}`);
	return response.data;
};

// Create a new listing
export const createListing = async (data: Listing): Promise<GetListingResponse> => {
	const response = await axiosInstance.post<GetListingResponse>('/listing', data);
	return response.data;
};

// Update a listing by ID
export const updateListing = async (id: string, data: Listing): Promise<GetListingResponse> => {
	const response = await axiosInstance.put<GetListingResponse>(`/listing/${id}`, data);
	return response.data;
};

// Delete a listing by ID
export const deleteListing = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/listing/${id}`);
};

export const refreshListingStats = async (id: string): Promise<any> => {
	const response = await axiosInstance.get<any>(`/listing/${id}/refresh/stats`);
	return response.data;
};
