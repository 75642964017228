import axiosInstance from './server';
import {
	Bid,
	CreateBidRequest,
	UpdateBidRequest,
	GetBidResponse,
	GetBidsResponse,
} from 'app-ts-types/bids';

// Get a bid by ID
export const getBidById = async (id: string): Promise<GetBidResponse> => {
	const response = await axiosInstance.get<GetBidResponse>(`/bid/${id}`);
	return response.data;
};

// Get multiple bids
export const getBids = async (filter?:string, filterId?:string): Promise<GetBidsResponse> => {
	let searchParams = "";
	if(filter && filterId) { searchParams=`?filter=${filter}&filterId=${filterId}`}
	const response = await axiosInstance.get<GetBidsResponse>(`/bid${searchParams}`);
	return response.data;
};

// Create a new bid
export const createBid = async (data: CreateBidRequest): Promise<GetBidResponse> => {
	const response = await axiosInstance.post<GetBidResponse>('/bid', data);
	return response.data;
};

// Update a bid by ID
export const updateBid = async (id: string, data: UpdateBidRequest): Promise<GetBidResponse> => {
	const response = await axiosInstance.put<GetBidResponse>(`/bid/${id}`, data);
	return response.data;
};

// Delete a bid by ID
export const deleteBid = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/bid/${id}`);
};
