import axiosInstance from './server';
import {
	CreatePricingTemplateResponse,
	CreateReturnTemplateResponse,
	CreateShippingTemplateResponse,
	CreateTemplateResponse,
	UpdateTemplateResponse,
	GetPricingTemplatesResponse,
	GetReturnTemplatesResponse,
	GetShippingTemplatesResponse,
	GetTemplateResponse,
	GetTemplatesResponse,
	PricingTemplate,
	RemovePricingTemplateResponse,
	RemoveReturnTemplateResponse,
	RemoveShippingTemplateResponse,
	RemoveTemplateResponse,
	UpdatePricingTemplateResponse,
	UpdateReturnTemplateResponse,
	UpdateShippingTemplateResponse,
	ShippingTemplate,
	Template,
	ReturnTemplate
} from "app-ts-types/templates";
import {initNotification} from "./notifications";

// Get a template by ID
export const getTemplateById = async (id: string): Promise<GetTemplateResponse> => {
	const response = await axiosInstance.get<GetTemplateResponse>(`/template/${id}`);
	return response.data;
};

// Get templates
export const getTemplates = async (): Promise<GetTemplatesResponse> => {
	const response = await axiosInstance.get<GetTemplatesResponse>(`/template`);
	return response.data;
};

// Create a new template
export const createTemplate = async (data: Template): Promise<CreateTemplateResponse> => {
	try {
		const response = await axiosInstance.post<CreateTemplateResponse>('/template', data);
		initNotification(data.id, "Template creation", "Template successfully created").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error creating template", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};

// Update a template by ID
export const updateTemplate = async (id: string, data: Template): Promise<UpdateTemplateResponse> => {
	try {
		const response = await axiosInstance.put<UpdateTemplateResponse>(`/template/${id}`, data);
		initNotification(data.id, "Template update", "Template successfully updated").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error updating template", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a template by ID
export const deleteTemplate = async (id: string): Promise<RemoveTemplateResponse> => {
	try {
		const response = await axiosInstance.delete<RemoveTemplateResponse>(`/template/${id}`);
		initNotification(id, "Template deletion", "Template successfully deleted").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(id, "Error deleting template", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};

// Get shipping templates
export const getShippingTemplates = async (): Promise<GetShippingTemplatesResponse> => {
	const response = await axiosInstance.get<GetShippingTemplatesResponse>(`/template/shipping`);
	return response.data;
};

// Create a shipping template
export const createShippingTemplate = async (data: ShippingTemplate): Promise<CreateShippingTemplateResponse> => {
	try {
		const response = await axiosInstance.post<CreateShippingTemplateResponse>('/template/shipping', data);
		initNotification(
			data.id,
			"Shipping template creation",
			"Shipping template successfully created"
		).catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			data.id,
			"Error creating shipping template",
			e.response?.data?.error
		).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Update a shipping template by ID
export const updateShippingTemplate = async (id: string, data: ShippingTemplate): Promise<UpdateShippingTemplateResponse> => {
	try {
		const response = await axiosInstance.put<UpdateShippingTemplateResponse>(`/template/shipping/${id}`, data);
		initNotification(
			data.id,
			"Shipping template update",
			"Shipping template successfully updated"
		).catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			data.id,
			"Error updating shipping template",
			e.response?.data?.error
		).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a shipping template by ID
export const deleteShippingTemplate = async (id: string): Promise<RemoveShippingTemplateResponse> => {
	try {
		const response = await axiosInstance.delete<RemoveShippingTemplateResponse>(`/template/shipping/${id}`);
		initNotification(
			id,
			"Shipping template deletion",
			"Shipping template successfully deleted"
		).catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			id,
			"Error deleting shipping template",
			e.response?.data?.error
		).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Get pricing templates
export const getPricingTemplates = async (): Promise<GetPricingTemplatesResponse> => {
	const response = await axiosInstance.get<GetPricingTemplatesResponse>(`/template/pricing`);
	return response.data;
};

// Create a pricing template
export const createPricingTemplate = async (data: PricingTemplate): Promise<CreatePricingTemplateResponse> => {
	try {
		const response = await axiosInstance.post<CreatePricingTemplateResponse>('/template/pricing', data);
		initNotification(data.id, "Pricing template creation", "Pricing template successfully created").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error creating pricing template", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Update a pricing template by ID
export const updatePricingTemplate = async (id: string, data: PricingTemplate): Promise<UpdatePricingTemplateResponse> => {
	try {
		const response = await axiosInstance.put<UpdatePricingTemplateResponse>(`/template/pricing/${id}`, data);
		initNotification(data.id, "Pricing template update", "Pricing template successfully updated").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error updating pricing template", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a pricing template by ID
export const deletePricingTemplate = async (id: string): Promise<RemovePricingTemplateResponse> => {
	try {
		const response = await axiosInstance.delete<RemovePricingTemplateResponse>(`/template/pricing/${id}`);
		initNotification(id, "Pricing template deletion", "Pricing template successfully deleted").catch(e => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(id, "Error deleting pricing template", e.response?.data?.error).catch(e => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Get return templates
export const getReturnTemplates = async (): Promise<GetReturnTemplatesResponse> => {
	const response = await axiosInstance.get<GetReturnTemplatesResponse>(`/template/return`);
	return response.data;
};

// Create a return template
export const createReturnTemplate = async (data: ReturnTemplate): Promise<CreateReturnTemplateResponse> => {
	try {
		const response = await axiosInstance.post<CreateReturnTemplateResponse>('/template/return', data);
		initNotification(data.id, "Return template creation", "Return template successfully created").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error creating return template", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};

// Update a return template by ID
export const updateReturnTemplate = async (id: string, data: ReturnTemplate): Promise<UpdateReturnTemplateResponse> => {
	try {
		const response = await axiosInstance.put<UpdateReturnTemplateResponse>(`/template/return/${id}`, data);
		initNotification(data.id, "Return template update", "Return template successfully updated").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(data.id, "Error updating return template", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a return template by ID
export const deleteReturnTemplate = async (id: string): Promise<RemoveReturnTemplateResponse> => {
	try {
		const response = await axiosInstance.delete<RemoveReturnTemplateResponse>(`/template/return/${id}`);
		initNotification(id, "Return template deletion", "Return template successfully deleted").catch((e) =>
			console.log(e)
		);
		return response.data;
	} catch (e: any) {
		initNotification(id, "Error deleting return template", e.response?.data?.error).catch((e) =>
			console.log(e)
		);
		console.log(e.response?.data);
		throw e;
	}
};
