import axiosInstance from './server';
import { SalesRecord, CreateSalesRecordRequest, UpdateSalesRecordRequest, GetSalesRecordResponse, GetSalesRecordsResponse } from 'app-ts-types/sales';

// Get a sales record by ID
export const getSalesRecordById = async (id: string): Promise<GetSalesRecordResponse> => {
	const response = await axiosInstance.get<GetSalesRecordResponse>(`/salesrecord/${id}`);
	return response.data;
};

// Get a sales records
export const getSalesRecords = async (): Promise<GetSalesRecordsResponse> => {
	const response = await axiosInstance.get<GetSalesRecordsResponse>(`/salesrecord`);
	return response.data;
};

// Get a sales records
export const getSalesRecordsByFilter = async (filter?:string, filterId?:string): Promise<GetSalesRecordsResponse> => {
	let searchParams = "";
	if(filter && filterId) { searchParams=`?filter=${filter}&filterId=${filterId}`}
	const response = await axiosInstance.get<GetSalesRecordsResponse>(`/salesrecord${searchParams}`);
	return response.data;
};

// Create a new sales record
export const createSalesRecord = async (data: SalesRecord): Promise<GetSalesRecordResponse> => {
	const response = await axiosInstance.post<GetSalesRecordResponse>('/salesrecord', data);
	return response.data;
};

// Update a sales record by ID
export const updateSalesRecord = async (id: string, data: UpdateSalesRecordRequest): Promise<GetSalesRecordResponse> => {
	const response = await axiosInstance.put<GetSalesRecordResponse>(`/salesrecord/${id}`, data);
	return response.data;
};

// Delete a sales record by ID
export const deleteSalesRecord = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/salesrecord/${id}`);
};
