import { XCircleIcon, ExclamationTriangleIcon, CheckCircleIcon } from '@heroicons/react/20/solid';


export default function StockAlert({ header, message, error }: {header: string, message:string, error?:boolean}) {
	return (
		<div className={`rounded-md p-4 ${error ? "bg-red-100" : "bg-green-100"}`}>
			<div className="flex flex-col sm:flex-row">
				<div className="shrink-0">
					{
						error ? (
							// <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
							<ExclamationTriangleIcon aria-hidden="true" className="size-5 text-red-400" />
						) : (
							<></>
							// <CheckCircleIcon aria-hidden="true" className="size-5 text-green-400"/>
						)
					}
				</div>
				<div className="mt-3 sm:mt-0 sm:ml-3">
					<h3 className={`text-sm font-medium ${error ? "text-red-800" : "text-green-800"}`}>{header}</h3>
					<div className={`mt-2 text-sm ${error ? "text-red-700" : "text-green-700"}`}>
						{message}
					</div>
				</div>
			</div>
		</div>
	)
}


export function StockLevelAlert({ totalQuantity }: { totalQuantity: number }) {
	const header = (totalQuantity === 0) ? "Out of Stock" : (totalQuantity < 10) ? 'Low Stock Warning' : 'Stock Level'
	const message = (totalQuantity === 0)
		? "You're out of stock for this item. Please restock soon."
		: (totalQuantity < 10)
		? `Only ${totalQuantity} items left. Please restock soon.`
		: `${totalQuantity} items available in stock.`

	return <StockAlert header={header} message={message} error={totalQuantity < 10} />
}
