import React, {useEffect, useState} from 'react';
import noMessagesImage from "../images/no-chats.png";
import EmptyComponent from "../components/Empty";
import {observer} from "mobx-react-lite";
import Loader from "../components/Loading";
import {marketplaceImages} from "../components/ListingImageCloud";
import {postMemberMessageResponse, updateMessage} from "../api/messages";
import {ChatMessage, ChatUserMessage, GetChatMessagesResponse} from "app-ts-types/chats";
import {getChatMessages, updateChatMessage} from "../api/chats";
import {chatMessagesStore} from "../state/Chats";
import {mergeUnique} from "../api/polling";
import {messagesStore} from "../state/Message";
import {PaperClipIcon} from "@heroicons/react/16/solid";
import {userStore} from "../state/User";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import {PostMemberMessageResponseRequest} from "../utils/types";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

export default observer(() => {
	const [loading, setLoading] = useState(true);
	// Local state for the selected chat conversation.
	const [selectedChat, setSelectedChat] = useState<ChatMessage | null>(null);
	const [newMessage, setNewMessage] = useState("");
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	// Fetch chat messages on mount.
	useEffect(() => {
		// If there are already messages in the store, skip loading.
		if (chatMessagesStore.chatMessages.length) {
			setLoading(false);
			return;
		}
		setLoading(true);
		getChatMessages()
			.then((data: GetChatMessagesResponse) => {
				// Assuming response.chatMessages is the array of ChatMessage items
				chatMessagesStore.setChatMessages(
					mergeUnique(chatMessagesStore.chatMessages, data.data)
				);
			})
			.catch((err: any) => {
				console.log("Error fetching messages", err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		if (!selectedChat) return;
		if (!selectedChat?.read) {
			try {
				updateChatMessage(selectedChat?.id, {
					...selectedChat,
					read: true,
					content: selectedChat.content.map(m => ({...m, read: true}))
				})
					.then((data) => {

					})
					.catch((data) => {

					});
				chatMessagesStore.updateChatMessage({
					...selectedChat,
					read: true,
					content: selectedChat.content.map(m => ({...m, read: true}))
				})
			} catch (err) {
				console.error("Error marking chat as read", err);
			}
		}
	}, [selectedChat])

	const handlePostMessage = async (e: any) => {
		e.preventDefault();
		if (!newMessage.trim() || !selectedChat) return;
		const currentUser = userStore.getUser()?.id;
		// Determine recipient based on current chat. For example, if the current user is the sender, send to receiver.
		const recipient = currentUser === selectedChat.sender ? selectedChat.receiver : selectedChat.sender;

		try {
			await sendChatMemberMessageResponse(selectedChat, newMessage);
		} catch (e) {
			return;
		}

		// Create a new message object.
		const newMsg = {
			from: currentUser || "",
			to: recipient,
			message: newMessage,
			timestamp: new Date().toISOString(),
			read: true,
		};
		// Append new message to the selected chat content.
		const updatedChat = {
			...selectedChat,
			content: [...selectedChat.content, newMsg],
		};
		setSelectedChat(updatedChat);
		// Optionally, update the chat in your store (e.g. chatMessagesStore.updateChat(updatedChat))
		setNewMessage("");
		// now update the chats in the chat message store
		chatMessagesStore.updateChatMessage(updatedChat);
	};

	/**
	 * Constructs the payload for postMemberMessageResponse by siphoning necessary fields from a ChatMessage.
	 * Assumes the current user's ID is available in userStore.
	 *
	 * @param chat - The ChatMessage object representing the conversation.
	 * @param body - The message body to send.
	 */
	const sendChatMemberMessageResponse = async (
		chat: ChatMessage,
		body: string
	): Promise<any> => {
		// Determine the current user and then the recipient
		const currentUser = userStore.getUser()?.id;
		const recipient = currentUser === chat.sender ? chat.receiver : chat.sender;

		// Use chat.messageId as the item ID (adjust if needed)
		const payload: PostMemberMessageResponseRequest = {
			message_id: chat.messageId,
			item_id: chat.messageLink,
			recipient_id: recipient,
			body,
			email_copy_to_sender: false,
			// subject, display_to_public, and question_type can be set as required
			subject: undefined,
			display_to_public: false,
			question_type: undefined,
		};

		try {
			const response = await postMemberMessageResponse(payload);
			console.log("Member message response sent:", response);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: false,
				title: `Chat Message sent!`,
				message: `Message has been successfully sent to the user`
			});
			return response;
		} catch (err: any) {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Message response err`,
				message: `Error sending message to user: ${err.response?.data?.error}`
			});
			throw err;
		}
	}

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	// If Enter is pressed (without Shift), post the message.
	const handleKeyDown = (e: any) => {
		if (e.key === "Enter" && !e.shiftKey) {
			handlePostMessage(e);
		}
	};

	// Handler for action response (Yes/No) when a message requires action.
	const handleActionResponse = async (message: ChatMessage, response: string) => {
		try {

		} catch (err) {
			console.error("Error updating message", err);
		}
	};

	// Compute the number of unread messages in a chat.
	const getUnreadCount = (chat: ChatMessage) => {
		return chat.content.filter((msg) => !msg.read).length;
	};

	// Sort the chat conversation by timestamp (older messages first).
	const sortedMessages = (chat: ChatMessage) => {
		return [...chat.content].sort((a, b) => +new Date(a.timestamp) - +new Date(b.timestamp));
	};

	if (loading) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	return (
		<main className="dark:bg-gray-900">
			{notificationComponent}
			<div className="space-y-8">
				<div className="flex flex-col md:flex-row min-h-[60vh]">
					{/* Sidebar */}
					<div
						className="md:flex md:flex-col space-y-4 md:w-1/4 border-b md:border-b-0 md:border-r border-gray-200 dark:border-gray-700 pr-8">
						<h1 className="text-3xl font-bold text-gray-900 dark:text-white">
							Chats
						</h1>
						<div className="flex items-center">
							<select
								value={chatMessagesStore.filter}
								onChange={(e) => {
									chatMessagesStore.setFilter(e.target.value);
									setSelectedChat(null);
								}}
								className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-800 dark:text-white"
							>
								<option value="">All Messages</option>
								<option value="read">Read</option>
								<option value="unread">Unread</option>
							</select>
						</div>
						<div className={"min-h-[60vh] overflow-y-scroll"}>
							{chatMessagesStore.filteredAndSortedChatMessages.length === 0 ? (
								<p className="text-gray-500">No chats available</p>
							) : (
								chatMessagesStore.filteredAndSortedChatMessages.map((chat) => (
									<div
										key={chat.id}
										onClick={() => setSelectedChat(chat)}
										className={`p-2 mb-2 cursor-pointer rounded 
	                ${selectedChat && selectedChat.id === chat.id
											? "bg-gray-300 dark:bg-gray-600"
											: "bg-gray-100 dark:bg-gray-800"
										}`}
									>
										<div className="flex justify-between items-center">
	                <span className="font-medium text-gray-900 dark:text-white">
	                  {chat.sender}
	                </span>
											{getUnreadCount(chat) > 0 && (
												<span className="bg-red-500 text-white text-xs rounded-full px-2">
	                    {getUnreadCount(chat)}
	                  </span>
											)}
										</div>
										<p className="text-sm text-gray-500 dark:text-gray-400">
											{chat.content[chat.content.length - 1].message}
										</p>
									</div>
								))
							)}
						</div>
					</div>
					{/* Main Content */}
					<div className="flex-grow p-4 flex">
						{selectedChat ? (
							<div className="flex w-full flex-col h-full rounded justify-between">
								<div>
									<h2 className="text-xl font-semibold mb-4">Chat with {selectedChat.sender}</h2>
									<div className="flex-grow max-h-[60vh] overflow-y-scroll space-y-4">
										{sortedMessages(selectedChat).map((msg, index) => (
											<div
												key={index}
												className={classNames(
													"p-2 rounded mb-2 max-w-fit",
													msg.from === userStore.getUser()?.id
														? "bg-indigo-600 mr-8 text-white self-end justify-self-end text-left"
														: "bg-gray-100 dark:bg-gray-700 self-start text-left"
												)}
											>
												<div className={classNames(
													"text-sm whitespace-pre-wrap",
													msg.from === userStore.getUser()?.id
														? "text-white"
														: "text-gray-700 dark:text-gray-300"
												)}>
													{msg.message}
												</div>
												<div className={classNames(
													"text-xs",
													msg.from === userStore.getUser()?.id
														? "text-gray-300"
														: "text-gray-500 dark:text-gray-400"
												)}>
													{new Date(msg.timestamp).toLocaleString()}
												</div>
											</div>
										))}
									</div>
								</div>
								<div className={"flex w-full self-end mt-8 justify-end space-x-4"}>
									<form action="#" className="relative w-full">
										<div
											className="rounded-lg w-full outline outline-1 -outline-offset-1 outline-gray-300 focus-within:outline focus-within:outline-2 focus-within:-outline-offset-2 focus-within:outline-indigo-600">
											<label htmlFor="comment" className="sr-only">
												Add your comment
											</label>
											<textarea
												id="comment"
												name="comment"
												rows={3}
												placeholder="Add your comment..."
												className="block w-full dark:text-white resize-none bg-transparent px-3 py-1.5 text-base text-gray-900 placeholder:text-gray-400 focus:outline focus:outline-0 sm:text-sm/6"
												value={newMessage}
												onChange={(e) => setNewMessage(e.target.value)}
												onKeyDown={handleKeyDown}
											/>

											{/* Spacer element to match the height of the toolbar */}
											<div aria-hidden="true" className="py-2">
												{/* Matches height of button in toolbar (1px border + 36px content height) */}
												<div className="py-px">
													<div className="h-9"/>
												</div>
											</div>
										</div>
										<div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
											<div className="flex items-center space-x-5">
												<div className="flex items-center">
													<button
														type="button"
														className="-m-2.5 flex size-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
													>
														<PaperClipIcon aria-hidden="true" className="size-5"/>
														<span className="sr-only">Attach a file</span>
													</button>
												</div>
											</div>
											<div className="shrink-0">
												<button
													type="submit"
													className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
												>
													Post
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						) : (
							<div className="flex-grow flex items-center justify-center">
								<EmptyComponent
									imageSrc={noMessagesImage}
									headerText={"No Messages"}
									descriptionText={"Select a chat to start messaging"}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</main>
	);
});
