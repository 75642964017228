import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as XLSX from 'xlsx';
import EmptyComponent from "../components/Empty";
import {observer} from 'mobx-react-lite';
import {Menu, Transition} from '@headlessui/react';
import {EllipsisHorizontalIcon, ChevronUpIcon, ChevronDownIcon, CheckIcon, CogIcon, CurrencyDollarIcon} from '@heroicons/react/20/solid';
import {inventoryStore} from '../state/Inventory';
import noInventoryImage from '../images/no-inventory-image.png';
import ListingImage from '../components/Image';
import {
	Categories, categoriesToJSON, createBaseInventory,
	Inventory as InventoryType, Listing,
	MarketplacePlatform, Sources, Statuses,
	statusesToJSON, StockLevel,
	stockLevelToJSON
} from "../utils/types";
import ListingImageCloud from "../components/ListingImageCloud";
import Tooltip from "../components/Tooltip";
import InventoryItem from "../components/InventoryItem";
import {copyToClipboard, generateRandomId, generateUUID, statuses} from "../utils/data";
import {deleteInventory, getInventories} from "../api/inventories";
import {NotificationPopupComponent} from "../components/NotificationPopup";
import Loader from "../components/Loading";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/outline";
import ListingItem from "../components/ListingItem";
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import { templatesStore } from "../state/Template";
import {Link} from "react-router-dom";
import {initialInventory} from "../components/CreateInventory";
import ListingPlatform from "../sdk";
import {ListingAPIResponseType} from "../types";
import {listingsStore} from "../state/Listing";
import {createShare, getShares, updateShare} from "../api/share";
import {userStore} from "../state/User";
import {importInventory} from "../api/import-export";
import SaleForm from "../components/CreateSale";
function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}
export const SortableHeader = ({field, label, sortField, sortOrder, onSort}: any) => {
	return (
		<th
			scope="col"
			className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter cursor-pointer"
			onClick={() => onSort(field)}
		>
			<div className="flex items-center">
				{label}
				{sortField === field && (
					sortOrder === 'asc' ? (
						<ChevronUpIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white"/>
					) : (
						<ChevronDownIcon className="ml-2 h-4 w-4 text-gray-900 dark:text-white"/>
					)
				)}
			</div>
		</th>
	);
};
const Inventory = observer(({ handleCreateInventory, handleCreateCollection, handleCreateListing }: {
	handleCreateInventory: (inventory?: InventoryType) => void;
	handleCreateCollection: () => void;
	handleCreateListing: (listing?: Listing, inventory?: InventoryType) => void;
}) => {
	const checkbox = useRef(null);
	const [checked, setChecked] = useState(false);
	const [loadImport, setLoadImport] = useState(false);
	const [saleCreation, setSaleCreation] = useState<boolean>(false);
	const [saleCreationComponent, setSaleCreationComponent] = useState<any|null>(null);
	const [exporting, setExporting] = useState(false);
	const [loadInventories, setLoadInventories] = useState(false);
	const [sharingLink, setSharingLink] = useState(false);
	const [excludingSharingLink, setExcludingSharingLink] = useState(false);
	const [currentInventory, setCurrentInventory] = useState(null);
	const [indeterminate, setIndeterminate] = useState(false);
	const [selectedItems, setSelectedItems] = useState<InventoryType[]>([]);
	const [deletingItems, setDeletingItems] = useState<InventoryType[]>([]);
	const [deletingListingItems, setListingDeletingItems] = useState<Listing[]>([]);
	const [editListing, setEditListing] = useState(false);
	const [fetchingNextPage, setFetchingNextPage] = useState(false);
	const [currentListing, setCurrentListing] = useState<Listing | null>(null);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	const handleSort = (field: string) => {
		if (inventoryStore.sortOption.field === field) {
			inventoryStore.setSortOption({
				...inventoryStore.sortOption,
				direction: inventoryStore.sortOption.direction === 'asc' ? 'desc' : 'asc'
			});
		} else {
			inventoryStore.setSortOption({field, direction: "asc"});
		}
	};

	useLayoutEffect(() => {
		const isIndeterminate = selectedItems.length > 0 && selectedItems.length < inventoryStore.filteredAndSortedInventory.length;
		setChecked(selectedItems.length === inventoryStore.filteredAndSortedInventory.length);
		setIndeterminate(isIndeterminate);
		if (checkbox.current) {
			// @ts-ignore
			checkbox.current.indeterminate = isIndeterminate;
		}
	}, [selectedItems, inventoryStore.filteredAndSortedInventory.length]);

	useEffect(() => {
		getSharedLinks().catch((e: any) => {
			console.log(e)
		});
		if (inventoryStore.inventory.length) return;
		setLoadInventories(true);
		getInventories()
			.then((res: any) => {
				if (!res.isError) {
					inventoryStore.setInventory(res.data)
				}
				setLoadInventories(false);
			})
			.catch((err: any) => {
				console.log(err);
				setLoadInventories(false);
			});
	}, []);

	// get all the shared links
	const getSharedLinks = async () => {
		return getShares()
			.then((res: any) => {
				// let's set the default data
				inventoryStore.setSharedLink(res.data[0] || null);
			})
			.catch((err: any) => {
				console.log(err);
			})
	}

	const inventoryItemClick = (item: any) => {
		setCurrentInventory(item);
	};

	const inventoryItemClickPopupOnClose = () => {
		setCurrentInventory(null);
	};

	const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		setLoadImport(true);
		const file = event.target.files?.[0];
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			try {
				const response = await importInventory(formData)
				console.log(response);
				if (response.success) {
					inventoryStore.loadNewInventories(response.data);
					setNotificationObject({
						title: "Import Success",
						message: "New inventory objects imported successfully",
						isError: false,
						show: true,
						autoClose: true,
					});
				} else {
					setNotificationObject({
						title: "Import Error",
						message: response.error || "Error importing inventory objects. please check your file",
						isError: true,
						show: true,
						autoClose: true,
					});
				}
			} catch (error: any) {
				console.error('Error importing inventory:', error);
				setNotificationObject({
					title: "Import Error",
					message: error.toString() || "Error importing inventory objects. please check your file",
					isError: true,
					show: true,
					autoClose: true,
				});
			} finally {
				setLoadImport(false);
				event.target.value = '';
			}
		} else {
			setLoadImport(false);
		}
	};

	const editClick = (inventory: InventoryType) => {
		handleCreateInventory(inventory)
		setCurrentInventory(null);
	}

	const deleteClick = async (inventory: InventoryType) => {
		setDeletingItems([...deletingItems, inventory]);
		await deleteInventoryRequest(inventory.id)
		setDeletingItems(deletingItems.filter((dit) => dit !== inventory));
		setCurrentInventory(null);
	}

	const handlePrevPage = () => {
		if (inventoryStore.currentPage > 1) {
			inventoryStore.setCurrentPage(inventoryStore.currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (inventoryStore.currentPage < inventoryStore.totalPages) {
			inventoryStore.setCurrentPage(inventoryStore.currentPage + 1);
			return;
		}
		setFetchingNextPage(true);
		getInventories(inventoryStore.inventory.length)
			.then((res: any) => {
				if (!res.isError && res.data.length) {
					inventoryStore.loadNewInventories(res.data);
					inventoryStore.setCurrentPage(inventoryStore.currentPage + 1);
				}
				setFetchingNextPage(false);
			})
			.catch((err: any) => {
				console.log(err);
				setFetchingNextPage(false);
			})
			.finally(() => {
				setFetchingNextPage(false);
			})
	};

	const deleteInventoryRequest = async (id: string) => {
		return deleteInventory(id).then((res: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: false,
				title: `Inventory Removed`,
				message: `Inventory object successfully removed`
			});
			inventoryStore.deleteInventory(id)
		}).catch((err: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Inventory Delete error`,
				message: err.message
			})
		});
	}

	const deleteSelectedItems = async (e: any) => {
		e.preventDefault();
		try {
			setDeletingItems(selectedItems)
			// Wait for all delete operations to complete
			await Promise.all(selectedItems.map((inv) => deleteInventory(inv.id)));
			// Update the inventory store and clear selected items
			inventoryStore.setInventory(inventoryStore.filteredAndSortedInventory.filter((item: InventoryType) => !selectedItems.includes(item)));
			setSelectedItems([]);
		} catch (error) {
			console.error("Error deleting items:", error);
			// Handle error if needed
		}
	};

	const shareInventoryLink = async (e: any) => {
		if (!inventoryStore.sharedLink) {
			setSharingLink(true);
			createShare({
				id: generateUUID(),
				shareKey: generateRandomId(),
				createdAt: Date.now(),
				expiresAt: Date.now(),
				shareAll: true,
				userId: userStore.user?.id || "",
				user: userStore.user?.id || "",
				inventoryIds: [],
			})
				.then((res: any) => {
					inventoryStore.setSharedLink(res.data);
					copyToClipboard(`https://app.tracknlist.com/public/share/${res.data.shareKey}`)
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: `Inventory successfully shared`,
						message: "Inventory is successfully shared and the link is copied to clipboard"
					});
				})
				.catch((err: any) => {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error sharing inventory`,
						message: err.message
					});
				})
				.finally(() => {
					setSharingLink(false);
				})
		} else {
			copyToClipboard(`https://app.tracknlist.com/public/share/${inventoryStore.sharedLink.shareKey}`)
				.then(() => {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: false,
						title: `Shared Link successfully copied`,
						message: "Shared Link is successfully copied to clipboard"
					});
				})
				.catch((err: any) => {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						title: `Error copying shared link`,
						message: err.message
					});
				})
		}
	}

	const excludeFromSharedInventory = async (e: any) => {
		if (!inventoryStore.sharedLink) return;
		setExcludingSharingLink(true);
		const selectedIds = selectedItems.map(si => si.id);
		const newIds = [
			...((inventoryStore.sharedLink.inventoryIds) || []).filter(id => !selectedIds.includes(id)),
			...selectedIds,
		];
		updateShare(inventoryStore.sharedLink.id, {
			...inventoryStore.sharedLink,
			inventoryIds: newIds,
		})
			.then((res: any) => {
				inventoryStore.setSharedLink(res.data);
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: false,
					title: `Inventory successfully excluded`,
					message: "Inventory item is successfully excluded from shared list"
				});
			})
			.catch((err: any) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: `Error excluding item from shared inventory`,
					message: err.message
				});
			})
			.finally(() => {
				setExcludingSharingLink(false);
			})
	}

	function toggleAll() {
		setSelectedItems(checked || indeterminate ? [] : inventoryStore.filteredAndSortedInventory);
		setChecked(!checked && !indeterminate);
		setIndeterminate(false);
	}

	function createListingFromInventory(inventory: InventoryType): Listing {
		return {
			platform: inventory.platform[0], // Assuming the first platform is used
			id: inventory.id,
			user: inventory.user,
			title: inventory.name,
			description: inventory.description,
			price: inventory.purchaseDetails?.price || 0, // Default value, as price is not in Inventory
			currencyCode: 'USD', // Default currency
			quantity: inventory.totalQuantity,
			imageUrls: [inventory.imageUrl],
			category: categoriesToJSON(inventory.category),
			condition: 'new', // Default condition
			seller: '', // Default or derived value
			location: '', // Default or derived value
			shipping: undefined, // Default or derived value
			scheduleDateTime: inventory.scheduleListing,
			createdAt: Date.now(),
			updatedAt: Date.now(),
			status: 'LISTED', // Default status
			inventoryId: inventory.id,
			listingUrl: '', // Default or derived value
			tagsAndKeywords: [], // Default or derived value
			SKU: inventory.SKU,
			brand: inventory.brand,
			color: inventory.color,
			sizes: inventory.sizes, // Assuming sizes is an array of objects with a size property
			listingObject: {},
			expirationDate: 0,
		};
	}

	const listingItemClickPopupOnClose = () => {
		setCurrentListing(null);
	}

	const createListingFromInventoryClick = (inventory: InventoryType) => {
		handleCreateListing(createListingFromInventory(inventory), inventory);
		setCurrentInventory(null);
	}

	const markAsSold = async(inventory:InventoryType) => {
		setSaleCreationComponent(<SaleForm onClose={() => {setSaleCreation(false); setCurrentListing(null); setCurrentInventory(null);}} inventory={inventory} listing={null} />);
		setSaleCreation(true);
	}

	const markListingAsSold = async(item:Listing) => {
		setSaleCreationComponent(<SaleForm onClose={() => {setSaleCreation(false); setCurrentListing(null); setCurrentInventory(null);}} inventory={null} listing={item} />);
		setSaleCreation(true);
	}

	const showListingDisplay = (listing: Listing) => {
		setCurrentInventory(null);
		setCurrentListing(listing);
	}

	const deleteListingRequest = async (listing: Listing) => {
		return new ListingPlatform().deleteListing(listing).then((res: ListingAPIResponseType) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: res.isError,
				title: `Listing Removed`,
				message: res.message,
			});
			if (!res.isError) {
				listingsStore.deleteListing(listing.id)
			}
		}).catch((err: any) => {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Listing Delete error`,
				message: err.message
			})
		});
	}

	const deListOrRelist = async (listing: Listing) => {
		if (listing.status === "DELISTED") {
			return new ListingPlatform().reListListing(listing);
		}
		return new ListingPlatform().deListListing(listing);
	}

	const DeListOrRelistlistingItemClick = async (item: Listing) => {
		setListingDeletingItems([...deletingListingItems, item]);
		await deListOrRelist(item).then((res: ListingAPIResponseType) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: res.isError,
					title: `Listing Removed`,
					message: res.message
				});
				listingsStore.updateListing({...item, status: item.status === "DELISTED" ? "LISTED" : "DELISTED"})
			})
			.catch((err: any) => {
				setNotificationObject({
					...notificationObject,
					show: true,
					isError: true,
					title: `Listing Delete error`,
					message: err.message
				})
			})
			.finally(() => {
				setListingDeletingItems(deletingListingItems.filter((dit) => dit !== item));
			})
	}

	const editListingClick = (listing: Listing) => {
		setCurrentListing(listing);
		setEditListing(true);
	}

	const deleteListingClick = async (listing: Listing) => {
		setListingDeletingItems([...deletingListingItems, listing]);
		await deleteListingRequest(listing)
		setListingDeletingItems(deletingListingItems.filter((dit) => dit !== listing));
		setCurrentListing(null);
	}

	const exportSelectedListings = async () => {
		try {
			setExporting(true);
			// Create a new workbook
			const workbook = XLSX.utils.book_new();

			// Define the header row with all necessary fields
			const headers = [
				"Name",
				"SKU",
				"Brand",
				"Color",
				"Total Quantity",
				"Description",
				"Sizes",
				"Purchase Price",
				"Purchase Shipping",
				"VAT Percentage",
				"Purchase Place",
				"Purchase Place Number",
				"Purchase Place Website",
				"Purchase Place Location",
				"Purchase Date",
				"Order Number",
				"Deleted",
				"Created At",
				"Updated At",
				"Out Of Stock",
				"ID",
				"User",
				"Image URL",
				"Auto List",
				"Listing Price",
				"Sold Price",
			];

			// Build worksheet data: first row is headers, then each inventory row
			const worksheetData = [headers];

			selectedItems.forEach((item) => {
				// If sizes is an array of objects with a "size" property, adjust accordingly
				const sizesStr = item.sizes ? item.sizes.map((s) => (s.size ? s.size : "One-Size")).join(", ") : "";

				// Flatten purchase details if available
				const purchaseDetails = item.purchaseDetails;

				// Create a row array with all fields
				const row = [
					item.name,
					item.SKU,
					item.brand,
					item.color,
					item.totalQuantity.toString(),
					item.description,
					sizesStr,
					purchaseDetails?.price?.toString() || "",
					purchaseDetails?.shipping || "",
					purchaseDetails?.vatPercentage.toString() || "",
					purchaseDetails?.purchasePlace || "",
					purchaseDetails?.purchasePlaceNumber || "",
					purchaseDetails?.purchasePlaceWebsite || "",
					purchaseDetails?.purchasePlaceLocation || "",
					purchaseDetails?.purchaseDate || "",
					purchaseDetails?.orderNumber || "",
					item.deleted ? "Yes" : "No",
					new Date(item.createdAt / 1000000).toISOString(),
					new Date(item.updatedAt).toISOString(),
					item.outOfStock ? "Yes" : "No",
					item.id,
					item.user,
					item.imageUrl,
					item.autoList ? "Yes" : "No",
					item.listingPrice.toString(),
					item.soldPrice.toString(),
				];
				worksheetData.push(row);
			});

			// Convert the array of arrays to a worksheet
			const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

			// Append the worksheet to the workbook
			XLSX.utils.book_append_sheet(workbook, worksheet, "Inventories");

			// Write the workbook to a binary string
			const workbookBinary = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

			// Utility function to convert binary string to ArrayBuffer
			const s2ab = (binaryString: any) => {
				const buffer = new ArrayBuffer(binaryString.length);
				const view = new Uint8Array(buffer);
				for (let i = 0; i < binaryString.length; i++) {
					view[i] = binaryString.charCodeAt(i) & 0xff;
				}
				return buffer;
			};

			// Create a Blob from the ArrayBuffer
			const blob = new Blob([s2ab(workbookBinary)], { type: "application/octet-stream" });

			// Trigger the file download (using FileSaver.js or similar)
			// @ts-ignore
			window.saveAs(blob, "selected_inventories.xlsx");
			setExporting(false);
		} catch (e) {
			console.log(e);
			setExporting(false);
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}

	const totalCost = selectedItems.reduce((acc, item: InventoryType) => acc + (item.purchaseDetails!.price * item.totalQuantity), 0).toFixed(2);

	if (loadInventories) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<Loader/>
			</div>
		);
	}

	if (inventoryStore.inventory.length === 0) {
		return (
			<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
				<EmptyComponent
					imageSrc={noInventoryImage}
					headerText={"Inventory"}
					descriptionText={"You have no inventory items"}
					actionText={"Create New Inventory"}
					onActionClick={handleCreateInventory}
					extraComponent={(
						<>
							<label htmlFor="import-file"
							       className="cursor-pointer inline-block mb-4 rounded-md bg-indigo-500 px-3 py-2 font-semibold text-center text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
								{loadImport ? (
									<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
									     viewBox="0 0 24 24">
										<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
										<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
									</svg>
								) : (
									'Import'
								)}
							</label>
							<input
								type="file"
								accept=".xlsx"
								onChange={handleImport}
								className="hidden"
								id="import-file"
							/>
						</>
					)}
				/>
			</div>
		);
	}

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	let headerActionsComponent;
	if (inventoryStore.inventory) {
		headerActionsComponent = <>
			<label htmlFor="import-file"
			       className="cursor-pointer inline-block rounded-md bg-indigo-500 px-3 py-2 font-semibold text-center text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
				{loadImport ? (
					<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
					     viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				) : (
					'Import'
				)}
			</label>
			<input
				type="file"
				accept=".xlsx"
				onChange={handleImport}
				className="hidden"
				id="import-file"
			/>
			<button
				disabled={!selectedItems.length}
				onClick={() => { exportSelectedListings().catch((err:any) => {console.log(err)}); }}
				className={`rounded-md ${!selectedItems.length && "grayscale"} bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
			>
				{exporting ? (
					<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
					     viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				) : (
					'Export'
				)}
			</button>
			<button
				disabled={!selectedItems.length}
				onClick={() => {
					// use the first item in the selection to create the listing
					createListingFromInventoryClick(selectedItems[0]);
				}}
				className={`rounded-md ${!selectedItems.length && "grayscale"} bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
			>
				List
			</button>
			{
				inventoryStore.sharedLink && <button
					disabled={!selectedItems.length || excludingSharingLink}
					onClick={excludeFromSharedInventory}
					className={`rounded-md ${!selectedItems.length && "grayscale"} bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
				>
					{excludingSharingLink ? <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
					                             fill="none"
					                             viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
						        strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor"
						      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg> : "Exclude from shared inventory"}
				</button>
			}
			<button
				disabled={sharingLink}
				onClick={shareInventoryLink}
				className={`rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500`}
			>
				{sharingLink ? <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
				                    fill="none"
				                    viewBox="0 0 24 24">
					<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
					        strokeWidth="4"></circle>
					<path className="opacity-75" fill="currentColor"
					      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
				</svg> : inventoryStore.sharedLink ? "Copy Shared Link" : "Share Inventory"}
			</button>
		</>
	}

	return (
		<div className="">
			{notificationComponent}
			{currentInventory &&
				<InventoryItem markAsSold={markAsSold} showListingDisplay={showListingDisplay} handleCreateListing={createListingFromInventoryClick}
				               del={deletingItems.includes(currentInventory)} inventory={currentInventory}
				               onClose={inventoryItemClickPopupOnClose} deleteClick={deleteClick} editClick={editClick}/>}
			{currentListing &&
				<ListingItem markAsSold={markListingAsSold} del={deletingListingItems.includes(currentListing)} edit={editListing} listing={currentListing}
				             onClose={listingItemClickPopupOnClose} deleteClick={deleteListingClick}
				             deListRelistClick={DeListOrRelistlistingItemClick}
				             editClick={editListingClick} dels={deletingListingItems.includes(currentListing)}/>}
			{ saleCreation && saleCreationComponent }
			<div className="sm:flex sm:items-center">
				<div
					className="flex md:flex-row flex-col md:justify-between md:items-center w-full gap-y-8 sm:gap-y-0 sm:flex-auto">
					<h1 className="text-3xl font-semibold leading-6 text-gray-900 dark:text-white">Inventory</h1>
					<div className={"md:space-x-4 space-y-2 md:space-y-0 flex md:flex-row flex-col"}>
						{headerActionsComponent}
						<Listbox>
							<Label className="sr-only">Create New Inventory</Label>
							<div className="relative">
								<div className="inline-flex divide-x divide-indigo-700 rounded-md outline-none">
									<button
										onClick={() => {
											handleCreateInventory(undefined)
										}}
										className="rounded-l-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
									>
										Create New Inventory
									</button>
									<Tooltip className={"w-24 bottom-8 -left-8"} text={"Select Template"}>
										<ListboxButton
											className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-500 p-2 outline-none hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-400">
											<span className="sr-only">Select Template</span>
											<ChevronDownIcon aria-hidden="true" className="size-5 text-white forced-colors:text-[Highlight]"/>
										</ListboxButton>
									</Tooltip>
								</div>

								<ListboxOptions
									transition
									className="absolute right-0 z-[1000] mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white dark:bg-gray-800 dark:text-white shadow-lg ring-1 ring-black/5 focus:outline-none border dark:border-gray-600 max-h-[30rem] overflow-y-auto"
								>
									<ListboxOption
										key="manage-templates"
										value="Manage Templates"
										className="group cursor-default select-none p-4 text-sm text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
									>
										<Link to="/templates">
											<div className="flex flex-col">
												<p className="font-normal flex space-x-4 dark:text-white group-data-[selected]:font-semibold">
													<CogIcon aria-hidden="true" className="size-5"/>
													<span>Manage Templates</span>
												</p>
											</div>
										</Link>
									</ListboxOption>
									{templatesStore.templates.map((template, index) => (
										<ListboxOption
											key={template.id + index}
											value={template}
											onClick={() => {
												// @ts-ignore
												handleCreateInventory({
													...initialInventory, ...template,
													name: template.title,
													id: "",
													createdAt: Date.now(),
													updatedAt: Date.now()
												})
											}}
											className="group cursor-pointer select-none p-4 text-sm text-gray-900 dark:text-white data-[focus]:bg-indigo-600 data-[focus]:text-white"
										>
											<div className="flex flex-col">
												<div className="flex justify-between">
													<p className="font-normal group-data-[selected]:font-semibold">{template.title}</p>
													<span
														className="text-indigo-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
                            {/*<CheckIcon aria-hidden="true" className="size-5"/>*/}
                          </span>
												</div>
												<p
													className="mt-2 text-gray-500 dark:text-gray-300 group-data-[focus]:text-indigo-200">{template.description}</p>
											</div>
										</ListboxOption>
									))}
								</ListboxOptions>
							</div>
						</Listbox>
					</div>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full min-h-[80vh] py-2 align-middle sm:px-6 lg:px-8">
						<div className="relative flex justify-between w-full">
							<div>
								{selectedItems.length > 0 && (
									<div
										className="left-14 top-0 flex h-12 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
										<span
											className="text-sm font-medium text-gray-900 dark:text-white">{selectedItems.length} selected</span>
										<span className="text-sm font-medium text-gray-900 dark:text-white">${totalCost} total cost</span>
									</div>
								)}
							</div>
							<div className="flex w-[80%] items-center justify-end space-x-8 mb-4">
								<div className="flex items-center space-x-4">
									{/*@ts-ignore*/}
									<select onChange={(e) => {inventoryStore.setSelectedCategory(e.target.value)}}
										value={inventoryStore.selectedCategory}
										className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									>
										<option value="">Categories: All</option>
										{Object.keys(Categories).filter(key => isNaN(Number(key))).map((key) => (
											<option key={key} value={Categories[key as keyof typeof Categories]}>
												{key}
											</option>
										))}
									</select>
									{/*@ts-ignore*/}
									<select onChange={(e) => {inventoryStore.setSelectedStatus(e.target.value)}}
										value={inventoryStore.selectedStatus}
										className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									>
										<option value="">Status: All</option>
										{Object.keys(Statuses).filter(key => isNaN(Number(key))).map((key) => (
											<option key={key} value={Statuses[key as keyof typeof Statuses]}>
												{key}
											</option>
										))}
									</select>
									{/*@ts-ignore*/}
									<select onChange={(e) => {inventoryStore.setSelectedStockLevel(e.target.value) }}
									        value={inventoryStore.selectedStockLevel}
									        className="block w-full rounded-md dark:bg-gray-900 dark:text-white border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
									>
										<option value="">Stock Level: All</option>
										{Object.keys(StockLevel).filter(key => isNaN(Number(key))).map((key) => (
											<option key={key} value={StockLevel[key as keyof typeof StockLevel]}>
												{key}
											</option>
										))}
									</select>
								</div>
								<button
									type="button"
									onClick={() => {
										inventoryStore.setSelectedStatus('');
										inventoryStore.setSelectedPlatform('');
										inventoryStore.setSelectedCategory('');
										inventoryStore.setSelectedStockLevel('');
										inventoryStore.setSelectedCollection('');
									}}
									className="block rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
								>
									Clear
								</button>
							</div>
						</div>
						<div className="relative">
							{selectedItems.length > 0 && (
								<div
									className="absolute left-14 top-0 z-[20] flex h-16 items-center space-x-3 bg-white dark:bg-gray-900 sm:left-12">
									<button
										type="button"
										className="inline-flex items-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
									>
										Bulk edit
									</button>
									{/*@ts-ignore*/}
									<button onClick={deleteSelectedItems}
									        disabled={Boolean(deletingItems.length)}
									        type="button"
									        className="inline-flex items-center !text-center rounded bg-white dark:bg-gray-900 px-2 py-1 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-30 disabled:hover:bg-white dark:disabled:hover:bg-gray-900"
									>
										{Boolean(deletingItems.length) ? (
											<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
											     viewBox="0 0 24 24">
												<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
												        strokeWidth="4"></circle>
												<path className="opacity-75" fill="currentColor"
												      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
											</svg>
										) : (
											"Delete all"
										)
										}
									</button>
								</div>
							)}
							<table className="min-w-full table-fixed divide-y divide-gray-300 dark:divide-gray-700">
								<thead>
								<tr>
									<th scope="col" className="relative px-7 sm:w-12 sm:px-6">
										<input
											type="checkbox"
											className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
											ref={checkbox}
											checked={checked}
											onChange={toggleAll}
										/>
									</th>
									<SortableHeader
										field="name"
										label="Name"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<SortableHeader
										field="SKU"
										label="SKU"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<SortableHeader
										field="purchaseDetails.price"
										label="Price"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<SortableHeader
										field="totalQuantity"
										label="Quantity"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<th scope="col"
									    className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter">
										Sizes
									</th>
									<th scope="col"
									    className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter">
										Listings
									</th>
									<SortableHeader
										field="color"
										label="Color"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<th scope="col"
									    className="sticky top-0 z-10 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter">
										Status
									</th>
									<SortableHeader
										field="purchaseDetails.purchaseDate"
										label="Purchase Date"
										sortField={inventoryStore.sortOption.field}
										sortOrder={inventoryStore.sortOption.direction}
										onSort={handleSort}
									/>
									<th scope="col"
									    className="sticky top-0 z-10 py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 dark:text-white bg-white dark:bg-gray-900 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
										Actions
									</th>
								</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 dark:divide-gray-800 bg-white dark:bg-gray-900">
								{inventoryStore.paginatedInventory.map((item: InventoryType) => (
									// @ts-ignore
									<tr key={item.id + "-" + item.imageUrl + "-" + item.platform.length}
									    className={selectedItems.includes(item) ? 'bg-gray-50 dark:bg-gray-800' : '' + ' cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-800/10'}>
										<td className="relative px-7 sm:w-12 sm:px-6">
											{/*// @ts-ignore*/}
											{selectedItems.includes(item) && (
												<div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"/>
											)}
											{/*// @ts-ignore*/}
											<input checked={selectedItems.includes(item)}
											       type="checkbox"
											       className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 dark:border-gray-600 text-indigo-600 focus:ring-indigo-600"
											       value={item.id}
											       onChange={(e) =>
												       // @ts-ignore*
												       setSelectedItems(e.target.checked ? [...selectedItems, item] : selectedItems.filter((p) => p !== item))
											       }
											/>
										</td>
										{/*// @ts-ignore*/}
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className={classNames('py-4 pr-3 text-sm font-medium', selectedItems.includes(item) ? 'text-indigo-600' : 'text-gray-900 dark:text-white')}>
											<div className="flex items-center gap-x-4">
												<ListingImage title={item.SKU} imageUrl={item.imageUrl}/>
												<div
													className="truncate whitespace-normal break-words min-w-12 text-sm text-black dark:text-white font-medium leading-6">{item.name}</div>
											</div>
										</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.SKU}</td>
										<td onClick={() => {inventoryItemClick(item)}} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
											{/*@ts-ignore*/}
											{item.currencyCode  === 'USD' ? '$' : item.currencyCode  === 'EUR' ? '€' : item.currencyCode  === 'GBP' ? '£' : '$'}{item.purchaseDetails!.price.toFixed(2)}
										</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.totalQuantity}</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}}>
											<ul className="flex flex-wrap gap-1">
												{item.sizes.map((size, index) => (
													<li key={index}
													    className="bg-brightPurple text-xs text-white px-2 py-1 rounded">
														{size.size || "One-Size"}
													</li>
												))}
											</ul>
										</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
											<ListingImageCloud showListingDisplay={showListingDisplay} inventoryId={item.id}
											                   marketplaces={item.platform}/>
										</td>
										<td onClick={() => {
											inventoryItemClick(item)
										}}
										    className="text-wrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.color}</td>
										<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">
											<div className={"inline-flex space-x-2"}>
												<Tooltip className={"-left-14"} text={`status: ${statusesToJSON(item.status)}`}>
													<div
														className={classNames(statuses[statusesToJSON(item.status)], 'flex-none rounded-full p-1')}>
														<div className="h-4 w-4 rounded-full bg-current"/>
													</div>
												</Tooltip>
												<Tooltip className={"-left-16"} text={`stockLevel: ${stockLevelToJSON(item.stockLevel)}`}>
													<div
														className={classNames(statuses[stockLevelToJSON(item.stockLevel)], 'flex-none rounded-full p-1')}>
														<div className="h-4 w-4 rounded-full bg-current"/>
													</div>
												</Tooltip>
											</div>
										</td>
										<td
											className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-400">{item.purchaseDetails?.purchaseDate}</td>
										<td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
											<Menu as="div" className="relative inline-block text-left">
												<div>
													{deletingItems.includes(item) ? (
														<svg className="animate-spin h-5 w-5 text-deepPurple" xmlns="http://www.w3.org/2000/svg"
														     fill="none"
														     viewBox="0 0 24 24">
															<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
															        strokeWidth="4"></circle>
															<path className="opacity-75" fill="currentColor"
															      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
														</svg>
													) : (
														<Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
															<span className="sr-only">Open options</span>
															<EllipsisHorizontalIcon aria-hidden="true" className="h-5 w-5"/>
														</Menu.Button>
													)}
												</div>
												<Transition
													as={React.Fragment}
													enter="transition ease-out duration-100"
													enterFrom="transform opacity-0 scale-95"
													enterTo="transform opacity-100 scale-100"
													leave="transition ease-in duration-75"
													leaveFrom="transform opacity-100 scale-100"
													leaveTo="transform opacity-0 scale-95"
												>
													<Menu.Items
														className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		inventoryItemClick(item)
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	View
																</button>
															)}
														</Menu.Item>
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		editClick(item)
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	Edit
																</button>
															)}
														</Menu.Item>
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		// use the first item in the selection to create the listing
																		createListingFromInventoryClick(item);
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	List
																</button>
															)}
														</Menu.Item>
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		// use the first item in the selection to create the listing
																		markAsSold(item);
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	Mark as Sold
																</button>
															)}
														</Menu.Item>
														<Menu.Item>
															{({active}) => (
																<button
																	onClick={() => {
																		deleteClick(item)
																	}}
																	className={classNames(
																		active ? 'bg-gray-100 dark:bg-gray-700' : '',
																		'block px-3 py-1 w-full text-sm leading-6 text-gray-900 dark:text-white'
																	)}
																>
																	Delete
																</button>
															)}
														</Menu.Item>
													</Menu.Items>
												</Transition>
											</Menu>
										</td>
									</tr>
								))}
								</tbody>
							</table>
						</div>
						<div className="flex items-center justify-center space-x-4 mt-4">
							<button
								onClick={handlePrevPage}
								disabled={inventoryStore.currentPage === 1}
								className={`p-2 cursor-pointer rounded-full ${inventoryStore.currentPage === 1 ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
							>
								<ChevronLeftIcon className="h-6 w-6"/>
							</button>
							<span className="text-gray-900 dark:text-gray-100">
				        Page {inventoryStore.currentPage} of {inventoryStore.totalPages || inventoryStore.currentPage}
				      </span>
							<button
								onClick={handleNextPage}
								// disabled={inventoryStore.currentPage === inventoryStore.totalPages}
								className={`p-2 cursor-pointer rounded-full ${inventoryStore.currentPage === inventoryStore.totalPages ? 'text-gray-400' : 'text-gray-700 dark:text-gray-300'} hover:bg-gray-200 dark:hover:bg-gray-700`}
							>
								{
									fetchingNextPage ? (
										<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
										     fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
											        strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor"
											      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
										</svg>
									) : (
										<ChevronRightIcon className="h-6 w-6"/>
									)
								}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
export default Inventory;
