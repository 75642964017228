import React, {useEffect, useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {userStore} from "../state/User";
import {generateUUID} from "../utils/data";
import {NotificationPopupComponent} from "./NotificationPopup";
import {categoriesToJSON, Inventory, Listing, Statuses, StockLevel} from "../utils/types";
import {inventoryStore} from "../state/Inventory";
import {listingsStore} from "../state/Listing";
import {createSalesRecord} from "../api/sales";
import {SalesRecord} from "app-ts-types/sales";
import {salesRecordsStore} from "../state/SalesRecord";
import {updateInventory} from "../api/inventories";
import {updateListing} from "../api/listings";

const initialExpense = {
	platforms: [],
	title: '',
	currencyCode: 'USD',
	price: 0,
	totalPrice: 0,
	platform: -1,
	listingId: "",
	quantity: 1,
	isOutOfStock: false,
};
const SaleForm = ({ initialData=initialExpense, onClose, inventory:inInv, listing }: { onClose: () => void; inventory?:Inventory|null, listing?:Listing|null, initialData?: any }) => {
	const [sale, setSale] = useState<any>({...initialData, title: listing?.title || inInv?.name || "N/A", price: listing?.price || inInv?.purchaseDetails?.price || 0});
	const [loading, setLoading] = useState(false);
	const [inventory, setInventory] = useState(inInv || inventoryStore.inventory.filter(inv => inv.id === listing?.inventoryId)[0]);
	const [listings, setListings] = useState<Listing[]>([]);
	const [showDialog, setShowDialog] = useState(true);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});

	useEffect(() => {
		setListings(listingsStore.listings.filter((lst) => lst.inventoryId === inventory.id));
	}, []);

	// Assume the presence of API functions:
// createSalesRecord(salesData: any): Promise<any>
// updateInventoryAPI(id: string, data: any): Promise<any>
// updateListingAPI(id: string, data: any): Promise<any>

	const handleSale = async () => {
		// 1. Create sales data object based on sample.
		const salesData: SalesRecord = {
			id: generateUUID(),
			user: userStore.user?.id || "",
			platform: sale.platform || -1, // or use appropriate enum/value if available
			productId: inventory.id,
			productTitle: listing?.title || inventory.name || "",
			listedPrice: sale.quantity * (inventory.purchaseDetails?.price || listing?.price || sale.price),
			price: sale.quantity * sale.price,
			currencyCode: sale.currencyCode || "USD",
			saleTimestamp: Date.now(),
			createdAt: Date.now(),
			category: listing?.category || categoriesToJSON(inventory.category) || "UNRECOGNIZED",
			imageUrl: listing?.imageUrls[0] || inventory.imageUrl,
			listingId: listing?.id || sale.listingId,
			inventoryId: inventory.id,
			SKU: listing?.SKU || inventory.SKU,
		};

		// 2. Create the sales record via API and update store.
		try {
			await createSalesRecord(salesData);
			salesRecordsStore.addSalesRecords([salesData]);
		} catch (error) {
			console.error("Error creating sales record:", error);
			return;
		}

		// 3. Calculate new inventory total quantity.
		const newTotalQuantity = (inventory.totalQuantity || 0) - sale.quantity;
		inventory.totalQuantity = newTotalQuantity > 0 ? newTotalQuantity : 0;

		// 4. Update the inventory via API and update store.
		try {
			if(inventory.totalQuantity <= 0) {
				inventory.status = Statuses.ORDERED
				inventory.outOfStock = true
				inventory.stockLevel = StockLevel.LOW
			}
			await updateInventory(inventory.id, inventory);
			inventoryStore.updateInventory(inventory);
		} catch (error) {
			console.error("Error updating inventory:", error);
		}

		// 5. Update related listings.
		if (listing) {
			if (inventory.totalQuantity === 0) {
				// Inventory sold out. Loop through all listings for this inventory and mark them as delisted.
				const relatedListings = listingsStore.listings.filter(
					(lst) => lst.inventoryId === inventory.id
				);
				for (const lst of relatedListings) {
					lst.status = "DELISTED"; // Mark the listing as delisted.
					try {
						await updateListing(lst.id, lst);
						// Optionally update each listing in the store individually.
						listingsStore.updateListings([lst]);
					} catch (error) {
						console.error(`Error delisting listing ${lst.id}:`, error);
					}
				}
			} else {
				// Inventory still available: update provided listing if necessary.
				// (Optional: adjust listing quantity or other details)
				try {
					await updateListing(listing.id, listing);
					listingsStore.updateListings([listing]);
				} catch (error) {
					console.error("Error updating listing:", error);
				}
			}
		} else if (inventory.totalQuantity === 0) {
			// No listing provided; ensure all listings for this inventory are delisted.
			const relatedListings = listingsStore.listings.filter(
				(lst) => lst.inventoryId === inventory.id
			);
			for (const lst of relatedListings) {
				lst.status = "DELISTED";
				try {
					await updateListing(lst.id, lst);
					listingsStore.updateListings([lst]);
				} catch (error) {
					console.error(`Error delisting listing ${lst.id}:`, error);
				}
			}
		}
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setSale((prevSale:any) => ({
			...prevSale,
			[name]: name === "price" ? parseFloat(value) : name === "quantity" ? parseInt(value) : value,
		}));
	};
	const handleFormSubmit = async () => {
		setLoading(true);
		const missingList = [];
		if(!sale.price)
			missingList.push("Price")
		if(!sale.quantity)
			missingList.push("Quantity")
		if(missingList.length !== 0) {
			setLoading(false);
			return setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				title: `Missing required fields`,
				message: `${missingList.join(", ")} fields are required!`
			})
		}
		try {
			await handleSale()
		} catch (error) {
			console.error('Error creating sale:', error);
		} finally {
			setLoading(false);
		}
	};
	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	}
	let notificationComponent;
	if(notificationObject.show) {
		notificationComponent = <NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title} message={notificationObject.message} isError={notificationObject.isError} autoClose={notificationObject.autoClose} />
	}

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-[100]" onClose={() => { setShowDialog(false); onClose(); }}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
									{sale.id ? "Update" : "Create"} Sale
								</Dialog.Title>
								<form onSubmit={(e) => {
									e.preventDefault();
									handleFormSubmit().catch((e) => {
										console.log(e);
									});
								}}>
									<div className="mt-4">
										<label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Title
										</label>
										<div className="mt-2">
											<input
												id="title"
												name="title"
												type="text"
												autoComplete="title"
												disabled={true}
												value={sale.title}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="currencyCode"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Currency Code
										</label>
										<div className="mt-2">
											<select
												id="currencyCode"
												name="currencyCode"
												value={sale.currencyCode}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value="USD">USD ($)</option>
												<option value="EUR">EUR (€)</option>
												<option value="GBP">GBP (£)</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="currencyCode"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Quantity Sold
										</label>
										<div className="mt-2">
											<input
												type={"number"}
												id="quantity"
												name="quantity"
												autoComplete="quantity"
												value={sale.quantity}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="price"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Price
										</label>
										<div className="mt-2">
											<input
												type={"number"}
												id="price"
												name="price"
												autoComplete="price"
												value={sale.price}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="price"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Total Sold ({sale.currencyCode})
										</label>
										<div className="mt-2">
											<input
												type={"number"}
												id="totalPrice"
												name="totalPrice"
												disabled={true}
												autoComplete="price"
												value={sale.quantity * sale.price}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											/>
										</div>
									</div>
									<div className="mt-4">
										<label htmlFor="date"
										       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
											Is out of Stock?
										</label>
										<div className="mt-2">
											<select
												id="isOutOfStock"
												name="isOutOfStock"
												value={sale.isOutOfStock.toString()}
												onChange={handleInputChange}
												className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
											>
												<option value={"true"}>Yes</option>
												<option value={"false"}>No</option>
											</select>
										</div>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												sale.id ? "Update Sale" : 'Create Sale'
											)}
										</button>
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
			{notificationComponent}
		</Transition>
	);
};
export default SaleForm;
