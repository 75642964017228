import React, {useState, useEffect, useRef} from 'react';
import {Combobox, Menu, Transition} from '@headlessui/react';
import {LinkIcon} from "@heroicons/react/24/outline"
import {
	getStockXProductById,
	getStockXProductVariants,
	searchStockXProduct,
	StockXProduct,
	StockXVariant
} from "../api/stockx";
import {NotificationPopupComponent} from "./NotificationPopup";
import {Listing, MarketplacePlatform} from "../utils/types";
import {userStore} from "../state/User";
import {ChevronDownIcon} from "@heroicons/react/20/solid";

/** eBay-friendly tokens for possible durations. You might add or remove as needed. */
const STOCK_X_EXPIRATION_DAYS = [
	{ label: "14 Days", value: "14" },
	{ label: "30 Days", value: "30" },
	{ label: "60 Days", value: "60" },
];

interface StockXListingProps {
	listing: Listing;
	setImages: (files: string[]) => void;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
	handlePlatformSpecifics: (listingObject: { [key: string]: string }) => void;
}

export default function StockXListing({listing, handleInputChange, handlePlatformSpecifics, setImages}: StockXListingProps) {
	const [query, setQuery] = useState(listing.title);
	const [results, setResults] = useState<StockXProduct[]>([]);
	const [variants, setVariants] = useState<any[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<StockXProduct | null>(null);
	const [selectedVariant, setSelectedVariant] = useState<any | null>(null);
	const [notificationObject, setNotificationObject] = useState({
		title: "",
		message: "",
		isError: false,
		show: false,
		autoClose: true,
	});
	const [page, setPage] = useState(1);
	const [hasMore, setHasMore] = useState(true);
	const [expiresIn, setExpiresIn] = useState<string>("30");
	const [imageOptionsExhausted, setImageOptionsExhausted] = useState(false);
	const [searchingProducts, setSearchingProducts] = useState<boolean>(false);
	const [searchingProductsError, setSearchingProductsError] = useState<string>("");
	const [fetchingVariants, setFetchingVariants] = useState<boolean>(false);
	const [fetchingVariantsError, setFetchingVariantsError] = useState<string>("");

	useEffect(() => {
		// if the component is mounted and there's a listing object
		if(listing && listing.listingObject && listing.listingObject["productId"] && !selectedProduct) {
			getStockXProductById(listing.listingObject["productId"])
				.then((res) => {
					setSelectedProduct(res);
				})
				.catch((err:any) => {
					console.log(err);
				})
		}
		// let's set a default variant if the variant is not there yet
		if(listing && listing.listingObject && listing.listingObject["variantId"] && !selectedVariant) {
			setSelectedVariant({variantId: listing.listingObject.variantId, productId: listing.listingObject.productId, variantName: listing.listingObject.variantName, variantValue: listing.listingObject.variantValue, sizeChart: {defaultConversion: { type: listing.listingObject.variantType, size: listing.listingObject.variantValue }}})
		}
		// let fire the default handle specific for the duration
		handlePlatformSpecifics({expiresIn: "30"});
	}, [])

	// Debounce effect
	useEffect(() => {
		if (selectedProduct && selectedProduct.title === query) return;
		const delayDebounceFn = setTimeout(() => {
			if (query.length > 2) {
				fetchProducts(query).catch((err) => {
					console.log(err)
				});
			}
		}, 333);
		return () => clearTimeout(delayDebounceFn);
	}, [query]);

	// when a product is selected
	// let's fetch all the product variants
	useEffect(() => {
		if (selectedProduct) {
			setQuery(selectedProduct?.title);
			if(selectedVariant && selectedVariant.productId !== selectedProduct.productId) {
				setSelectedVariant(null);
				fetchProductVariants(selectedProduct?.productId).catch((err) => {
					console.log(err)
				});
			} else if(!variants.length) {
				fetchProductVariants(selectedProduct?.productId).catch((err) => {
					console.log(err)
				});
			}
			// @ts-ignore
			handleInputChange({target: {name: "title", value: selectedProduct.title}});
			// @ts-ignore
			handleInputChange({target: {name: "brand", value: selectedProduct.brand}});
			// @ts-ignore
			handleInputChange({target: {name: "color", value: selectedProduct?.productAttributes.colorway || selectedProduct?.productAttributes.color}});
			// @ts-ignore
			handleInputChange({target: {name: "SKU", value: selectedProduct?.styleId}});
			// @ts-ignore
			handleInputChange({target: {name: "category", value: selectedProduct?.productType}});
			// @ts-ignore
			handleInputChange({target: {name: "tagsAndKeywords", value: selectedProduct?.productAttributes.gender}});
			// add some images to the listing
			setImages([
				generateStockX360ImageUrl(selectedProduct.title, selectedProduct.productType),
				generateStockXStandardImageUrl(selectedProduct.title, selectedProduct.productType),
			]);
			// then add some platform specifics
			handlePlatformSpecifics({productId: selectedProduct.productId})
		}
	}, [selectedProduct])

	useEffect(() => {
		if(selectedVariant) {
			// then add some platform specifics
			handlePlatformSpecifics({variantId: selectedVariant.variantId, variantName: selectedVariant.variantName, variantValue: selectedVariant.variantValue, variantType: selectedVariant?.sizeChart?.defaultConversion?.type});
		}
	}, [selectedVariant])

	// fetch the search products
	// and display it in the combo box UI
	const fetchProducts = async (searchTerm: string) => {
		try {
			setSearchingProducts(true);
			searchStockXProduct(searchTerm)
				.then((data) => {
					if (data.products) {
						setResults(data.products);
					} else if (data.message) {
						setSearchingProductsError(data.message);
					}
					setSearchingProducts(false);
				})
				.catch((err) => {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						autoClose: true,
						title: `Error getting products`,
						message: err.message
					});
					setSearchingProductsError(err.message);
					setSearchingProducts(false);
				})
		} catch (error) {
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				autoClose: true,
				title: `Error getting products`,
				message: `An error has occurred whilst fetching products`
			})
			console.error('Error fetching products:', error);
			setSearchingProducts(false);
			setSearchingProductsError("An error has occurred whilst fetching products");
		}
	};

	const generateStockX360ImageUrl = (title:string, type:string):string => {
		if (!title) return "";
		if(type === "sneakers") title = title.replaceAll("Jordan", "Air Jordan")
		// Remove special characters and replace spaces with hyphens
		let formattedTitle = title.replace(/[^\w\s-]/g, "").replace(/\s+/g, "-");
		// Construct the StockX 360-degree image URL
		return `https://images.stockx.com/360/${formattedTitle}/Images/${formattedTitle}/Lv2/img01.jpg?w=576&q=57&dpr=2&h=384`;
	}

	const generateStockXStandardImageUrl = (title: string, type:string): string => {
		if (!title) return "";
		setImageOptionsExhausted(true);
		if(type === "sneakers") title = title.replaceAll("Jordan", "Air Jordan")
		// Remove special characters and replace spaces with hyphens
		let formattedTitle = title.replace(/[^\w\s-]/g, "").replace(/\s+/g, "-");
		// Construct the StockX standard image URL
		return `https://images.stockx.com/images/${formattedTitle}.jpg?w=576&q=57&dpr=2&h=384`;
	}

	// fetch all variants of the selected product
	const fetchProductVariants = async (productId: string) => {
		try {
			setFetchingVariants(true);
			getStockXProductVariants(productId)
				.then((data) => {
					setVariants(data);
					setFetchingVariants(false);
				})
				.catch((err) => {
					setNotificationObject({
						...notificationObject,
						show: true,
						isError: true,
						autoClose: true,
						title: `Error fetching product variants`,
						message: err.message
					});
					setFetchingVariants(false);
					setFetchingVariantsError(err.message);
				})
		} catch (error) {
			setFetchingVariants(false);
			setNotificationObject({
				...notificationObject,
				show: true,
				isError: true,
				autoClose: true,
				title: `Error fetching products variants`,
				message: `An error has occurred whilst fetching product variants`
			})
			console.error('Error fetching products variants:', error);
			setFetchingVariantsError("An error has occurred whilst fetching product variants");
		}
	};

	const notificationCloseCallback = () => {
		setNotificationObject({
			title: "",
			message: "",
			isError: false,
			show: false,
			autoClose: true,
		})
	};

	let notificationComponent;
	if (notificationObject.show) {
		notificationComponent =
			<NotificationPopupComponent notificationCloseCallback={notificationCloseCallback} title={notificationObject.title}
			                            message={notificationObject.message} isError={notificationObject.isError}
			                            autoClose={notificationObject.autoClose}/>
	}

	const loader = <svg className="animate-spin h-5 w-5 text-gray-700 dark:text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
		<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
		<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
	</svg>

	let stockxConnectionMessage: any = <span className="text-red-500 font-bold">Connect a StockX account to search products</span>;
	if (userStore.user?.platformDetails && userStore.user.platformDetails?.["STOCKX"]) {
		stockxConnectionMessage = null;
	}
	if (searchingProducts) {
		stockxConnectionMessage = loader;
	}

	let variantMessage: any;
	if(!selectedProduct) {
		variantMessage = <span className="text-red-500 text-sm">Search and pick product to select variant</span>;
	}
	if(fetchingVariants) {
		variantMessage = loader;
	}

	return (
		<>
			{notificationComponent}
			<div className="relative mt-4 w-full">
				<Combobox value={selectedProduct} onChange={setSelectedProduct}>
					<div className="relative">
						<div className="mt-4">
							<label htmlFor="searchProduct"
							       className="flex text-sm space-x-1 font-medium leading-6 text-gray-900 dark:text-white">
								<span>Search Product</span> <span className="text-red-500 font-bold">*</span> {stockxConnectionMessage}
							</label>
							<div className={"mt-2"}>
								<Combobox.Input
									id={"searchProduct"}
									className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
									placeholder="Search for a product..."
									value={query}
									onChange={(e) => setQuery(e.target.value)}
								/>
							</div>
							<span className="text-red-500 text-sm">{searchingProductsError}</span>
						</div>
						<Combobox.Options
							className="absolute top-full left-0 w-full divide-y divide-gray-500 dark:divide-gray-400 bg-white dark:bg-gray-900 dark:text-gray-200 border dark:border-gray-700 shadow-lg rounded-lg mt-1 max-h-[75vh] overflow-y-scroll z-50">
							{results.map((product: any) => (
								<Combobox.Option
									key={product.productId}
									value={product}
									className={({active}) => `px-4 py-2 space-x-2 flex cursor-pointer ${active ? 'bg-gray-100 dark:bg-gray-800' : ''}`}
								>
									<img
										src={generateStockX360ImageUrl(product.title, product.productType)}
										alt={product.title}
										className="w-16 h-12 rounded-md"
										onError={(e) => (e.currentTarget.src = !imageOptionsExhausted ? generateStockXStandardImageUrl(product.title, product.productType) : "https://stockx-assets.imgix.net/media/Product-Placeholder-Default-20210415.jpg?fit=fill&bg=FFFFFF&q=57&dpr=2&trim=color&w=64&h=48")}
									/>
									<div>
										<div className="text-sm font-medium text-gray-900 dark:text-white">{product.title}</div>
										<div
											className="text-xs text-gray-500 dark:text-gray-400">{product.productAttributes?.colorway || product.productAttributes?.color || 'No Colorway'}</div>
									</div>
								</Combobox.Option>
							))}
						</Combobox.Options>
					</div>
				</Combobox>
			</div>
			<div className="relative mt-4 w-full">
				<div className="relative">
					<div className="mt-4">
						<label htmlFor="searchProduct"
						       className="flex text-sm space-x-1 font-medium leading-6 text-gray-900 dark:text-white">
							<span>Select Product Variant</span> <span className="text-red-500 font-bold">*</span> {variantMessage}
						</label>
						<div className={"mt-2"}>
							<Menu as="div" className="relative inline-block text-left w-full">
								<div>
									<Menu.Button
										className="inline-flex w-full gap-x-1.5 rounded-md bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-sm font-semibold text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500">
										{selectedVariant ?
											`${selectedVariant.sizeChart?.defaultConversion?.size ?? ''} (${selectedVariant.sizeChart?.defaultConversion?.type ?? ''})`
											: "Select Variant"}
										<ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400"/>
									</Menu.Button>
								</div>
								<Transition
									as={React.Fragment}
									enter="transition ease-out duration-100"
									enterFrom="transform opacity-0 scale-95"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-75"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-95"
								>
									<Menu.Items
										className="absolute dark:border dark:border-gray-700 left-0 z-[100] w-full origin-top-right rounded-md bg-white dark:bg-gray-800 dark:text-gray-200 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
										<div className="py-1 max-h-80 divide-y divide-gray-500 dark:divide-gray-400 overflow-y-auto">
											{(variants || [])
												.map((variant) => (
													<Menu.Item key={variant}>
														{({active}) => (
															<div onClick={() => {
																setSelectedVariant(variant)
															}}
															     className={`flex cursor-pointer items-center px-4 py-2 ${active ? 'bg-gray-100 dark:bg-gray-700' : ''}`}>
																{variant.sizeChart.defaultConversion.size} ({variant.sizeChart.defaultConversion.type})
															</div>
														)}
													</Menu.Item>
												))}
										</div>
									</Menu.Items>
								</Transition>
							</Menu>
						</div>
						<span className="text-red-500 text-sm">{fetchingVariantsError}</span>
					</div>
				</div>
			</div>
			<div className="relative mt-4 w-full">
				<label htmlFor="price"
				       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
					Price <span className={"text-red-500 font-bold"}>*</span>
				</label>
				<div className="mt-2">
					<input
						id="price"
						name="price"
						type="number"
						autoComplete="price"
						value={listing.price}
						onChange={handleInputChange}
						className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 px-2 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
					/>
				</div>
			</div>
			<div className="relative mt-4 w-full">
				<label className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
					Listing Expiration (Days) <span className="text-red-500"></span>
				</label>
				<select
					className="mt-1 block w-full bg-gray-100 dark:bg-white/5 text-gray-900 dark:text-white rounded-md py-1 px-2 text-sm"
					value={expiresIn}
					onChange={(e) => {handlePlatformSpecifics({expiresIn: e.target.value}); setExpiresIn(e.target.value);}}
				>
					{STOCK_X_EXPIRATION_DAYS.map((opt) => (
						<option key={opt.value} value={opt.value}>{opt.label}</option>
					))}
				</select>
			</div>
		</>
	);
}
