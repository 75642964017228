import React from 'react';
import {Label, Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/react";
import Tooltip from "./Tooltip";
import {ChevronDownIcon, CogIcon} from "@heroicons/react/20/solid";
import {Link} from "react-router-dom";
import {templatesStore} from "../state/Template";
import {initialInventory} from "./CreateInventory";
import {initialListing} from "./CreateListing";

interface EmptyComponentProps {
	imageSrc: string;
	headerText: string;
	descriptionText: string;
	actionText?: string;
	onActionClick?: (data?:any) => void;
	extraComponent?: any;
}

const EmptyComponent: React.FC<EmptyComponentProps> = ({
	                                                         imageSrc,
	                                                         headerText,
	                                                         descriptionText,
	                                                         actionText,
	                                                         onActionClick,
	                                                         extraComponent,
                                                         }) => {

	let buttonComponent;

	if((actionText === "Create New Inventory" || actionText === "Create New Listing") && onActionClick) {
		buttonComponent = <>
			<Listbox>
				{extraComponent}
				<Label className="sr-only">Create New</Label>
				<div className="relative">
					<div className="inline-flex divide-x divide-indigo-700 rounded-md outline-none">
						<button
							onClick={() => {
								onActionClick(undefined)
							}}
							className="rounded-l-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						>
							{actionText}
						</button>
						<Tooltip className={"w-24 bottom-8 -left-8"} text={"Select Template"}>
							<ListboxButton
								className="inline-flex items-center rounded-l-none rounded-r-md bg-indigo-500 p-2 outline-none hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-indigo-400">
								<span className="sr-only">Select Template</span>
								<ChevronDownIcon aria-hidden="true" className="size-5 text-white forced-colors:text-[Highlight]"/>
							</ListboxButton>
						</Tooltip>
					</div>

					<ListboxOptions
						transition
						className={`absolute right-0 z-[1000] mt-2 w-72 origin-top-right bottom-8 divide-y divide-gray-200 overflow-hidden rounded-md bg-white dark:bg-gray-800 dark:text-white shadow-lg ring-1 ring-black/5 focus:outline-none border dark:border-gray-600 ${actionText === "Create New Listing" ? "max-h-[22rem]" : "max-h-[13rem]"} overflow-y-auto`}
					>
						<ListboxOption
							key="manage-templates"
							value="Manage Templates"
							className="group cursor-default select-none p-4 text-sm text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
						>
							<Link to="/templates">
								<div className="flex flex-col">
									<p className="font-normal flex space-x-4 dark:text-white group-data-[selected]:font-semibold">
										<CogIcon aria-hidden="true" className="size-5"/>
										<span>Manage Templates</span>
									</p>
								</div>
							</Link>
						</ListboxOption>
						{templatesStore.templates.map((template, index) => (
							<ListboxOption
								key={template.id+index}
								value={template}
								onClick={() => {
									const data = actionText === "Create New Inventory" ? initialInventory : initialListing;
									// @ts-ignore
									onActionClick({ ...data, ...template, name: template.title, id: "", createdAt: Date.now(), updatedAt: Date.now() })
								}}
								className="group cursor-pointer select-none p-4 text-sm text-gray-900 dark:text-white data-[focus]:bg-indigo-600 data-[focus]:text-white"
							>
								<div className="flex flex-col">
									<div className="flex justify-between">
										<p className="font-normal group-data-[selected]:font-semibold">{template.title}</p>
										<span
											className="text-indigo-600 group-[&:not([data-selected])]:hidden group-data-[focus]:text-white">
	                            {/*<CheckIcon aria-hidden="true" className="size-5"/>*/}
	                          </span>
									</div>
									<p className="mt-2 text-gray-500 dark:text-gray-300 group-data-[focus]:text-indigo-200">{template.description}</p>
								</div>
							</ListboxOption>
						))}
					</ListboxOptions>
				</div>
			</Listbox>
		</>
	}

	if(actionText && (actionText !== "Create New Inventory" && actionText !== "Create New Listing") && onActionClick) {
		buttonComponent = <button
			onClick={() => {
				onActionClick();
			}}
			className="mt-6 rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
		>
			{actionText}
		</button>
	}

	return (
		<div className="flex flex-col items-center justify-center">
			<img src={imageSrc} alt={headerText} className="w-80 h-80"/>
			<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">{headerText}</h1>
			<p className="text-lg text-gray-700 dark:text-gray-300">{descriptionText}</p>
			{buttonComponent}
		</div>
	);
};

export default EmptyComponent;
