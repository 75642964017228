import {makeAutoObservable} from "mobx";
import {ChatMessage} from "app-ts-types/chats";
import {generateUUID} from "../utils/data";

const sampleChats: ChatMessage[] = [
	{
		"id": generateUUID(),
		"user": "Seller456",
		"sender": "Buyer789",
		"receiver": "Seller456",
		"content": [
			{
				"from": "Buyer789",
				"to": "Seller456",
				"message": "Hello, is the 'Vintage Watch' still available?",
				"timestamp": "1709148600",
				"read": false
			}
		],
		"timestamp": 1709148600,
		"read": false,
		"platform": 0,
		"needsAction": true,
		"messageId": "0940489803",
		"messageLink": "87965478",
		"createdAt": 1709148600,
		"updatedAt": 1709148600,
		supportsResponse: true,
		responseLink: "",
	},
	{
		"id": "302",
		"user": "Seller456",
		"sender": "Buyer567",
		"receiver": "Seller456",
		"content": [
			{
				"from": "Buyer567",
				"to": "Seller456",
				"message": "Can you provide more pictures of the 'Rare Coin'?",
				"timestamp": "1709151600",
				"read": false
			}
		],
		"timestamp": 1709151600,
		"read": false,
		"platform": 0,
		"needsAction": true,
		"messageId": "98459403908",
		"messageLink": "9086754678",
		"createdAt": 1709151600,
		"updatedAt": 1709151600,
		supportsResponse: true,
		responseLink: "",
	}
];

export class ChatMessagesStore {
	chatMessages: ChatMessage[] = [];
	filter: string = "";
	sortOption: { field: string; direction: string } = {field: "", direction: ""};
	currentPage: number = 1;
	itemsPerPage: number = 100;

	constructor() {
		makeAutoObservable(this);
	}

	setChatMessages(chatMessages: ChatMessage[]) {
		this.chatMessages = chatMessages;
	}

	addChatMessages(newItems: ChatMessage[]) {
		this.chatMessages = [...this.chatMessages, ...newItems];
	}

	updateChatMessage(updatedItem: ChatMessage) {
		const index = this.chatMessages.findIndex(item => item.id === updatedItem.id);
		if (index !== -1) {
			this.chatMessages[index] = updatedItem;
		}
	}

	deleteChatMessage(itemId: string) {
		this.chatMessages = this.chatMessages.filter(item => item.id !== itemId);
	}

	setFilter(filter: string) {
		this.filter = filter;
	}

	setSortOption(sortOption: { field: string; direction: string }) {
		this.sortOption = sortOption;
	}

	setCurrentPage(page: number) {
		this.currentPage = page;
	}

	get filteredAndSortedChatMessages(): ChatMessage[] {
		let filteredChats = this.chatMessages;
		if (this.filter) {
			filteredChats = filteredChats.filter(item => this.filter === "read" ? item.read : !item.read)
		}
		if (this.sortOption.field) {
			filteredChats = filteredChats.slice().sort((a, b) => {
				if (this.sortOption.direction === "asc") {
					// @ts-ignore
					return a[this.sortOption.field] > b[this.sortOption.field] ? 1 : -1;
				} else {
					// @ts-ignore
					return a[this.sortOption.field] < b[this.sortOption.field] ? 1 : -1;
				}
			});
		}
		return filteredChats;
	}

	get paginatedChatMessages(): ChatMessage[] {
		const startIndex = (this.currentPage - 1) * this.itemsPerPage;
		const endIndex = startIndex + this.itemsPerPage;
		return this.filteredAndSortedChatMessages.slice(startIndex, endIndex);
	}

	get totalPages(): number {
		return Math.ceil(this.filteredAndSortedChatMessages.length / this.itemsPerPage);
	}
}

export const chatMessagesStore = new ChatMessagesStore();
