import axiosInstance from './server';
import {
	Share,
	GetShareResponse,
	GetSharesResponse,
	GetSharedInventoriesResponse,
} from 'app-ts-types/shares';
import {initNotification} from "./notifications";
import {generateUUID} from "../utils/data";

// Get a share by ID
export const getShareById = async (id: string): Promise<GetShareResponse> => {
	const response = await axiosInstance.get<GetShareResponse>(`/shares/${id}`);
	return response.data;
};

// Get a list of shares
export const getShares = async (): Promise<GetSharesResponse> => {
	const response = await axiosInstance.get<GetSharesResponse>(`/shares`);
	return response.data;
};

// Create a new shared link
export const createShare = async (data: Share): Promise<GetShareResponse> => {
	try {
		const response = await axiosInstance.post<GetShareResponse>('/shares', data);
		initNotification(
			data.id,
			"Shared Link Creation",
			"Shared link successfully created"
		).catch((e) => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			data.id,
			"Error Creating Shared Link",
			e.response?.data?.error || "An unexpected error occurred while creating the shared link."
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Update a shared link by ID
export const updateShare = async (id: string, data: Share): Promise<GetShareResponse> => {
	try {
		const response = await axiosInstance.put<GetShareResponse>(`/shares/${id}`, data);
		initNotification(
			generateUUID(),
			"Shared Link Update",
			"Shared link successfully updated"
		).catch((e) => console.log(e));
		return response.data;
	} catch (e: any) {
		initNotification(
			generateUUID(),
			"Error Updating Shared Link",
			e.response?.data?.error || "An unexpected error occurred while updating the shared link."
		).catch((e) => console.log(e));
		console.log(e.response?.data);
		throw e;
	}
};

// Delete a share by ID
export const deleteShare = async (id: string): Promise<void> => {
	await axiosInstance.delete(`/shares/${id}`);
};

// Get shared inventories using a share key
// The share key is passed as a query parameter, e.g., ?shareKey=[key]
export const getSharedInventories = async (
	shareKey: string,
	limit: number = 100,
	skip: number = 0,
	sort_order: string = 'desc',
	sort_by: string = 'createdAt'
): Promise<GetSharedInventoriesResponse> => {
	const response = await axiosInstance.get<GetSharedInventoriesResponse>(
		`/shares/inventories?shareKey=${shareKey}&limit=${limit}&skip=${skip}&sort_order=${sort_order}&sort_by=${sort_by}`
	);
	return response.data;
};
